@charset "utf-8";

.entry {
    .l-page-header {
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        &__wrap {
            top: -20px;
        }
        .l-scroll-down {
            top: 48.8%;
        }
    }
    .l-content {
        margin-bottom: -5px;
        .l-container {
            padding-top: 100px;
            position: relative;
            &::before {
                @include dot-human;
                height: 600px;
                position: absolute;
                right: -300px;
                top: -300px;
                width: 600px;
                z-index: -1;
            }
            &::after {
                @include icon-ini;
                background-image: url(../images/entry/img-bg.svg);
                position: absolute;
                height: 221px;
                width: 398px;
                top: 43px;
                right: 21px;
                z-index: -1;
            }
        }
    }
    .page-links {
      bottom: 60px;
      &__item{
        &:nth-child(2){
          margin-bottom:2px;
          padding-bottom:2px;
              &:last-child{
                margin:0;
                padding:0;
                }
              }
            }
            &__link{
              width:80px;
              margin-bottom: 17px;
              padding-bottom: 20px;
          }
          .last{
              margin:0;
              padding:0;
          }
        }
        .entry-title {
            @include fz(28,28,700);
            margin-bottom: 34px;
            &::after {
                @include icon-ini;
                background: $mainGrada;
                display:block;
                height: 1px;
                margin-top: 42px;
                width: 30px;
            }
        }
        .text-link {
            color: #36a7c7;
        }
        .entry-btn {
            margin: 0 auto;
            margin-top: 40px;
            text-align: center;
            width: 300px;
            .btn-more {
                @include fz(15, 32, 600);
                background: $mainGrada;
                font-family: $fontFamily;
                padding: 9px 30px 0;
                position: relative;
                max-width: 300px;
                width: 100%;
                @media all and (-ms-high-contrast: none) {
                  padding: 11px 30px 0;
                }
                @include media(max-width, 340px) {
                    @include fz(13, 32);
                }
                &::before {
                    @include icon-ini;
                    background-image: url(../images/entry/ico-paper.svg);
                    height: 20px;
                    position: relative;
                    margin-right: 6px;
                    top: -2px;
                    width: 18px;
                    @media all and (-ms-high-contrast: none) {
                      top: -1px;
                    }
                }
                &:after {
                    display: none;
                }
            }
            &.block {
                display: flex;
                margin: 0px auto;
                width: 56%;
                .btn-more {
                    &:last-of-type {
                        margin-left: 20px;
                    }
                }
            }
        }
        .web-entry {
            margin-bottom: 79px;
            &__header {
                margin-bottom: 32px;
            }
            &__txt {
                @include fz(15, 28, 500);
            }
            &__content {
                &--list {
                    align-items: flex-start;
                    display: flex;
                    justify-content: space-between;
                }
                &--item {
                    background: #fff;
                    border: 1px solid $grayColor;
                    flex-basis: calc(50% - 10px);
                    height: 472px;
                    padding: 38px;
                    &_title {
                        @include fz(24, 24, 700);
                        margin-bottom: 20px;
                    }
                    &_img {
                        display: block;
                        margin-top: 27px;
                        width: 100%;
                        img {
                            display: block;
                            width: 100%;
                        }
                    }
                    &_txt {
                        @include fz(15, 28, 400);
                        display: block;
                        margin-top: 21px;
                    }
                    .btn-more {
                        margin-top: 9px;
                    }
                    &:first-child {
                        .btn-more {
                            margin-top: -19px;
                        }
                    }
                }
            }
        }

        .company-entry {
            margin-bottom: 70px;
            &__title {
                position: relative;
            }
            &__txt {
                @include fz(15, 28, 400);
                margin-bottom: 30px;
            }
            &__content {
                margin-bottom: 30px;
                &--list {
                    align-items: flex-start;
                    background: #fff;
                    border: 1px solid $grayColor;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    &::before {
                        @include icon-ini;
                        background-color: $grayColor;
                        height: 51%;
                        left: 0;
                        margin: auto;
                        position: absolute;
                        right: 8px;
                        top: 0;
                        width: 1px;
                        transform: rotate(-10deg);
                    }
                    &::after {
                        @extend ::before;
                        top: auto;
                        bottom: 3px;
                        transform: rotate(10deg);
                    }
                }
                &--item {
                    flex-basis: 50%;
                    height: 350px;
                    padding: 24px 47px 24px 49px;
                    &:before {
                        @include icon-ini;
                        background-image: url(../images/entry/touroku02.png);
                        background-image: image-set(url(../images/entry/touroku02.png)1x,url(../images/entry/touroku02@2x.png)2x);
                        bottom: -14px;
                        height: 80px;
                        position: absolute;
                        right: 61px;
                        width: 123px;
                        z-index: 1;
                    }
                    &_title {
                        @include fz(24, 24, 700);
                        position: relative;
                        margin-bottom: 4px;
                        padding-top: 3px;
                        &::before {
                            background: $siteColor;
                            border-radius: 50%;
                            color: #fff;
                            content: "01";
                            display: inline-block;
                            font-family: 'Alegreya Sans SC', sans-serif;
                            height: 50px;
                            line-height: 50px;
                            left: -4px;
                            margin-right: 8px;
                            position: relative;
                            text-align: center;
                            top: -3px;
                            width: 50px;
                        }
                    }
                    &_txt {
                        @include fz(15, 28, 500);
                    }
                    &:first-child {
                        padding: 24px 47px 24px 38px;
                        &:before {
                            @include icon-ini;
                            background-image: url(../images/entry/touroku01.png);
                            background-image: image-set(url(../images/entry/touroku01.png)1x,url(../images/entry/touroku01@2x.png)2x);
                            bottom: -14px;
                            height: 82px;
                            position: absolute;
                            left: 371px;
                            width: 91px;
                            z-index: 1;
                        }
                    }
                    &:nth-of-type(2) {
                        .company-entry__content--item_title {
                            &::before {
                                content: "02";
                                left: -6px;
                                margin-right: 7px;
                            }
                        }
                    }
                }
            }

            &__glaybox {
                background: $grayColor;
                padding: 40px 40px 35px;
                margin-bottom: 30px;
                &--title {
                    @include fz(18,18,600);
                }
                &--item {
                    @include fz(14,28,400);
                    margin-top: 11px;
                    &:last-child {
                        margin-top: 1px;
                    }
                    .indent{
                      margin-left:14px;
                    }
                }
                &--strong {
                    font-weight: 600;
                }
            }
            .entry-btn {
                .btn-more {
                    padding: 9px 3px 0px 0;
                    @media all and (-ms-high-contrast: none) {
                      padding: 11px 3px 0px 0;
                    }
                }
            }
        }

        .tel-entry {
            display: block;
            .entry-title {
                margin-top: 20px;
            }
            &__header {
                display: block;
            }
            &__inner {
                display: flex;
                position: relative;
            }
            &__txtarea {
                width: 550px;
                &--inner {
                    border-bottom: 1px $grayColor dotted;
                    padding-bottom: 37px;
                }
            }
            &__txt {
                @include fz(15,28,500);
            }
            &__imgerea {
                margin-left: 30px;
                margin-top: 21px;
                position: relative;
                top: -125px;
            }
            &__contactarea {
                align-items: center;
                display: flex;
                margin-top: 31px;
                &--link {
                    pointer-events: none;
                }
                &--mark {
                    padding-top: 19px;
                    width: 57px;
                }
                &--number {
                  margin-top: 2px;
                  margin-left: 12px;
                }
                &--txt {
                    @include fz(15,28,600);
                    margin-left: 10px;
                    padding-top: 24px;
                }
            }
        }
        .job-btn {
            display: block;
            margin-top: 20px;
            .btn-more {
                @include fz(15, 50, 700);
                font-family:$fontFamily ;
                padding: 0px 54px 1px 52px;
                &:before {
                    @include icon-ini;
                    background-image: url(../images/ico-search.svg);
                    height: 14px;
                    left: -8px;
                    top:-2px;
                    position: relative;
                    width: 14px;
                }
            }
        }


    //ここからSPサイズ
    @include media(sm) {
        .l-page-header {
            background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2);
            &__wrap {
                top: 0px;
            }
        }
        .l-content {
            margin-bottom: 180px;
            .l-container {
                padding-top: 50px;
                position: relative;
                &::before {
                    height: 300px;
                    right: -150px;
                    top: -150px;
                    width: 300px
                }
                &::after {
                    background: none;
                    height: 154px;
                    right: 21px;
                    top: 0;
                    width: 284px;
                }
            }
        }
        .page-links {
            display: none !important;
        }
        .entry-title {
            @include fzs(42, 22, 700);
            margin-bottom: 15px;
            &::after {
                margin-top: 20px;
            }
        }
        .entry-btn {
            display: block;
            margin: 0px auto;
            margin-top: 25px;
            width: auto;
            .btn-more {
                font-family: $fontSpFamily;
                padding: 9px 0 5px 0;
                height: 50px;
                position: relative;
                &::before {
                    height: 20px;
                    position: relative;
                    margin-right: 10px;
                    top: 0;
                    width: 18px;
                }
            }
            &.block {
                display: block;
                width: 100%;
                .btn-more {
                    &:last-of-type {
                        margin-left: 0px;
                        margin-top: 20px;
                    }
                }
            }
        }
        .web-entry {
            margin-bottom: 70px;
            margin-top: 30px;
            &__txt {
                @include fzs(28,48,500);
            }
            &__content {
                display: block;
                margin-bottom: 50px;
                &--list {
                    display: block;
                }
                &--item {
                    height: auto;
                    padding: 20px;
                    width: 100%;
                    &_title {
                        @include fzs(32, 32, 700);
                        margin-bottom: 15px;
                    }
                    &_img {
                        margin-top: 0;
                    }
                    &_txt {
                        @include fzs(28, 48, 500);
                        margin-top: 22px;
                    }
                    .btn-more {
                        margin-top: 0;
                    }
                    &:first-child {
                        .btn-more {
                            margin-top: 0;
                        }
                    }
                    &:last-child {
                        margin-top: 30px;
                    }
                }
            }
        }

        .company-entry {
            margin-bottom: 70px;
            &__txt {
                @include fzs(28, 48, 400);
            }
            &__content {
                margin-bottom: 30px;
                &--list {
                    display: block;
                    &::before {
                      height: 1px;
                      left: -6px;
                      right: inherit;
                      top: 42%;
                      transform: rotate(20deg);
                      width: 53.3%;
                    }
                    &::after {
                        height: 1px;
                        bottom: inherit;
                        left: inherit;
                        top: 42%;
                        transform: rotate(-20deg);
                        width: 53.3%;
                        right: -6px;
                    }
                }
                &--item {
                    height: auto;
                    padding: 20px;
                    &:first-child {
                        padding: 20px 15px 55px;
                        &:before {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        padding: 100px 15px 20px;
                    }
                    &_title {
                        @include fzs(32, 32, 600);
                        position: relative;
                        margin-bottom: 20px;
                        padding-top: 3px;
                        text-align: left;
                        &::before {
                            @include fz(20,40,700);
                            min-height: 40px;
                            min-width: 40px;
                            height: 40px;
                            width: 40px;
                        }
                    }
                    &_txt {
                        @include fzs(28, 48, 400);
                        padding: 0px 8px;
                    }
                    &:before {
                        display: none;
                    }
                    &_title {
                        @include fzs(38, 44, 700);
                        position: relative;
                        margin-bottom: 4px;
                        padding-top: 3px;
                        &::before {
                        }
                    }
                    &_txt {
                        @include fzs(28, 48, 400);
                        margin-top: 20px;
                        padding: 0;
                    }
                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            &__glaybox {
                padding: 30px 20px;
                &--title {
                    @include fzs(32, 32, 700);
                }
                &--item {
                    @include fzs(24,44,500);
                    &:last-child {
                        margin-top: 1px;
                    }
                    .indent{
                      margin-left:0;
                    }
                }
                &--strong {
                      @include fzs(24,44,700);
                }
            }
        }

        .tel-entry {
            display: block;
            margin-bottom: 50px;
            padding: 0;
            position: relative;
            .entry-title {
                margin-top: 20px;
            }
            &__header {
                display: block;
            }
            &__inner {
                display: block;
                margin: 0px;
                margin-top: 30px;
                position: relative;
            }
            &__txtarea {
                bottom: 0;
                margin-top: 0px;
                position: absolute;
                width: 100%;
                &--inner {
                    display: block;
                    margin-top: 20px;
                    padding-bottom: 20px;
                    position: absolute;
                }
            }
            &__txt {
                @include fzs(28,48,500);
            }
            &__imgerea {
                display: block;
                margin: 0px auto;
                top: 0;
                width: 100%;
                img {
                    display: block;
                    margin: 0px auto;
                    width: 100%;
                }
            }
            &__contactarea {
                align-items: center;
                display: flex;
                margin-top: 110px;
                padding-top: 20px;
                position: absolute;
                width: 100%;
                &--link {
                    pointer-events: auto;
                }
                &--mark {
                    margin-top: 25px;
                    padding-top: 0;
                    height: 27px;
                    width: 42px;
                }
                &--number {
                    margin-left: 15px;
                }
                &--txt {
                    @include fzs(28, 48, 600);
                    margin-left: 0px;
                    padding-top: 0px;
                    position: absolute;
                    left: 63px;
                    top: 80px;
                }
            }
        }

        .job-btn {
          margin: 0px auto;
          margin-top: 20px;
          width: 97.5%;
            .btn-more {
                @include fzs(28, 32, 700);
                height:50px;
                margin:0 auto;
                padding-top: 18px;
                text-align: center;
                width: 100%;
                &:before {
                    top: -2px;
                }
            }
        }
    }
    //ここからiPhoneSE
    @include media(ss){
        .l-content {
            margin-bottom: 230px;
        }
        .tel-entry {
            &__contactarea {
                margin-top: 140px;
            }
        }
    }
}
