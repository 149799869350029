@charset "utf-8";
.recruittips-category {

    .l-page-header {
        top: 20px;
        background-image: url(../images/recruittips/mv.jpg);
        background-image: image-set(url(../images/recruittips/mv.jpg)1x,url(../images/recruittips/mv@2x.jpg)2x);
        &__title {
            @include fz(57.22,57.22,800);
            @include textclip($mainGrada);
            font-family: $fontAlegreyaSansSC;
            margin-top: -37px;
            position: relative;
            &::after {
                @include icon-ini;
                background-image: url(../images/recruittips/ico-recruit-tips.png);
                background-image: image-set(url(../images/recruittips/ico-recruit-tips.png)1x,url(../images/recruittips/ico-recruit-tips@2x.png)2x);
                position: absolute;
                height: 23px;
                right: -21px;
                top: -5px;
                width: 23px;
            }
        }
        &__subTitle {
            @include fz(15,15,600);
            background: none;
            font-family: $fontFamily;
            position: relative;
            top: 14px;
            -webkit-text-fill-color: #333;
        }
    }

    .l-content {
        margin-bottom: 119px;
        margin-top: 85px;
        & > .l-container {
            &:before {
                @include dot-human;
                height: 600px;
                position: absolute;
                right: -300px;
                top: -300px;
                width: 600px;
                z-index: -1;
            }
        }
        .l-main {
            position: relative;
            left: -15px;//左の余白がでカラム落ちするので
        }
        .l-side {
            margin-left: 32px;//同じくカラム落ちする...
            @include media(sm) {
                margin-left: 0;
            }
        }
    }
    &-tips {
        &__header {
            align-items: center;
            background-color: $grayColor;
            border-radius: 3px;
            display: flex;
            padding: 0 0 1px 21px;
            height: 47px;
            width: 100%;
        }
        &__cap {
            @include fz(18,18,700);
            color: #bebebe;
            font-family: $fontAlegreyaSansSC;
            margin-right: 14px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 7px;
            }
        }
        &__title {
            @include fz(20,20,600);
            @media all and (-ms-high-contrast: none) {
              padding-top: 12px;
            }
        }
        &__contents {
            flex-wrap: wrap;
            margin-top: 36px;
            &--item {
                padding-bottom: 26px;
                &:nth-child(odd) {
                    margin-right: 30px;
                }
            }
            &--imgWrap {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
    }
    .pagenav {
        margin-top: 8px;
        padding-right: 8px;
    }

    .l-side {
        margin-top: -8px;
    }


    @include media(sm) {
        .l-page-header {
            background-image: url(../images/recruittips/mv_sp.jpg);;
            height: calc(482px / 2 + 45px);
            top: 0;
            &__title {
                @include fzs(80,80,800);
                margin-top: 20px;
                &::after {
                    height: calc(23px * 0.7);
                    width: calc(23px * 0.7);
                }
            }
            &__subTitle {
                @include fzs(30, 30, 600);
                font-family: $fontSpFamily;
                top: 7px;
            }
        }
        .l-content {
            margin-top: 30px;
            padding-bottom: 0;
            margin-bottom: 50px;
            & > .l-container {
                &:before {
                    height: 300px;
                    right: -150px;
                    top: -150px;
                    width: 300px;
                }
            }
            .l-main {
                position: static;
                left: 0;
            }
        }

        &-tips {
            &__header {
                padding: 1px 0 0px 21px;
            }
            &__contents {
                margin-top: 25px;
                &--item {
                    flex-wrap: wrap;
                    padding-bottom: 25px;
                    &:nth-child(odd) {
                        margin-right: 0;
                    }
                }
                &--imgWrap {
                    margin: 0;
                    width: 100%;
                    height: 280px;
                }
                &--txtWrap {
                    margin-top: 10px;
                }
                &--txt {
                    padding: 0;
                }
                &--title {
                    @include fzs(32,50,600);
                    margin-bottom: 5px;
                }
                &--excerpt {
                    @include fzs(28,48,400);
                }
            }
        }
    }
}
