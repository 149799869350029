@charset "utf-8";

.privacy {
    .l-page-header{
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        position:relative;
        z-index: 2;
        &__title{
        }
        &__subTitle {
        }
        .l-scroll-down {
              top: 48.8% !important;
        }
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2)
        }
    }
    .l-content {
        @include media(sm) {
            padding-bottom: 30px;
        }
    }

    .ico-gray-arrow {
        background-size: 14px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        margin-right: 13px;
        margin-left: 3px;
    }

    .ico-gray-arrow i {
        display:inline-block;
        width: 6px;
        height: 6px;
        border-top: 1px solid #636b76;
        border-right: 1px solid #636b76;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .l-container{
        margin-bottom: 112px;
        margin-top: 77px;
        position:relative;
        z-index: 0;
        // &:before{
        //     @include icon-ini;
        //     position:absolute;
        //     background:url(../images/privacy/img-privacy-bg.png)no-repeat top right;
        //     width:625px;
        //     height:571px;
        //     top: -323px;
        //     right: 0px;
        //     z-index: -1;
        // }
        @include media(sm) {
            display: block;
            margin: 0px auto;
            width: 100%;
            // &:before{
            //     position:absolute;
            //     background:url(../images/privacy/img-privacy-bg.png)no-repeat top right;
            //     height:571px;
            //     right: 0px;
            //     top: -239px;
            //     width: 100%;
            //     z-index: -1;
            // }
        }
    }

    .privacy-wrap {
        margin-top: 30px;
        &:first-child {
            margin-top: 0px;
        }
        //&:nth-child(2) {
            //.privacy-guidline__inner--headline {
                //&:after {
                    //top: 100px;
                //}
            //}
        //}
        &:last-child {
            border-top: 1px solid #ccc;
            padding-top: 20px;
        }
        @include media(sm) {
            margin-top: 30px !important;
        }
    }

    .privacy-info {
        width:1100px;
        margin:104px auto 90px;
        display: flex;
        justify-content: space-between;
        @include media(sm) {
            display: block;
            margin: 0px auto;
            margin-top: 30px;
            width: 100%;
        }
    }

    &-guidline{
        width:1110px;
        margin:75px auto 125px;
        position:relative;
        &__inner{
            &--headline{
                @include fz(28, 75, 700);
                margin-bottom: 33px;
                &:after{
                    @include icon-ini;
                    background: $mainGrada;
                    display:block;
                    height: 1px;
                    margin-top: 19px;
                    margin-left: 3px;
                    width: 30px;
                }
                &.intro {
                    display: block;
                    &:before{
                        position:absolute;
                        content:"";
                        background:$mainGrada;
                        width:30px;
                        height:1px;
                        top:94px;
                    }
                }
            }
            &--mark {
                @include fz(18,28,400);
                color: #ff0000;
            }
            &--text{
              @include fz(15,28,400);
                &.right{
                  text-align: right;
                }
              @include media(sm) {
                  @include fzs(28, 48, 400);
              }
            }
            &--number{
                margin:20px 0;
                list-style: decimal;
                padding-left: 17px;
                .list{
                    @include fz(15,28,400);
                    margin:20px 0;
                    @include media(sm) {
                        @include fzs(28, 48, 400);
                    }
                }
                .listTit{
                    @include fz(15,28,400);
                    margin:5px 0;
                }
                .another{
                    @include fz(15,28,400);
                    display: block;
                    margin:5px 0 30px 0;
                }
                .another-number {
                    display: block;
                    &:first-child {
                        &:before {
                            content: "（１）";
                        }
                    }
                    &:nth-child(2) {
                        &:before {
                            content: "（２）";
                        }
                    }
                    &:nth-child(3) {
                        &:before {
                            content: "（３）";
                        }
                    }
                    &:nth-child(4) {
                        &:before {
                            content: "（４）";
                        }
                    }
                    @include media(sm) {
                      padding-left: 32px;
                      text-indent: -44px;
                    }
                }
            }
          &--circle{
              @include fz(15,28,400);
              margin:5px 0 30px 0;
              list-style: circle;
              padding-left: 30px;
              .list{
                @include fz(15,28,400);
                margin:5px 0;
              }
              .point{
                  margin-bottom:30px;
                  .pointList{
                    @include fz(15,28,400);
                  }
              }
          }
        }
        @include media(sm) {
            display: block;
            margin: 0px auto;
            margin-top: 30px;
            width: 90%;
            &__inner{
                &--headline{
                    @include fzs(42, 22, 700);
                    margin-bottom: 14px;
                    &:after{
                        margin-top: 19px;
                        margin-left: 2px;
                    }
                }
                &--mark {
                    @include fzs(32 , 42, 400);
                    margin-bottom: 20px;
                }
            }
            &__mark {
                display: block;
                margin-top: 20px;
                &--box {
                    display: block;
                    margin-top: 20px;
                    dl {
                        display: flex;
                        margin-top: 10px;
                        &:first-child {
                            margin-top: 0px;
                        }
                    }
                     dt {
                          width: 50%;
                          min-width: 50%;
                    }
                    dd {
                        display: flex;
                    }
                }
            }
        }
    }
    a[target="_blank"]::after {
      background-image: none;
    }
}
