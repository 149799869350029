@charset "utf-8";

.js-switch-image {
    opacity: 0;
    &.is-loaded {
        opacity: 1;
    }
}

.c-image {
    &-full {
        width: 100%;
    }

    &-border {
        position: relative;
        &::after {
            bottom: 0;
            border: 1px solid #ddd;
            content: '';
            display: block;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
        }
        img {
            width: 100%;
        }
    }

    &-thumb {
        background: #ddd;
        overflow: hidden;
        position: relative;
        &::before {
            content: '';
            display: block;
            padding-top: percentage(300/600);
        }
        &::after {
            bottom: 0;
            border: 1px solid #ddd;
            content: '';
            display: block;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
        }
        img {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
        }
    }

    &-iframe {
        position: relative;
        &::before {
            content: '';
            display: block;
            padding-top: percentage(254/500);
        }
        iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}
