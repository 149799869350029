@charset "utf-8";

.flow {
    //MV・タイトル
    position: absolute;
    width: 100%;
    .l-page-header {
        background-image: url(../images/flow/mv.jpg);
        background-image: image-set(url(../images/flow/mv.jpg)1x,url(../images/flow/mv@2x.jpg)2x);
        z-index: 0;
        &__wrap {
            &::before {
                @include icon-ini;
                background-image: url(../images/reason/img-white.png);
                bottom: 0;
                height: 372px;
                margin: auto;
                right: 0;
                left: 0;
                position: absolute;
                top: 0;
                width: 695px;
                z-index: -1;
                @include media(sm) {
                    display: none;
                }
            }
        }
    }
    .l-page-header__title {
        margin: -13px 4px 0px 0px;
    }
    .l-page-header__subTitle {
        left: 1px;
        letter-spacing: 0.005em;
        position: relative;
        top: -3px;
    }

    //コンテンツ
    .l-content {
        margin-bottom: 120px;
    }
    .l-container {
        &::before {
            @include dot-human;
            position: absolute;
            height: 600px;
            right: -299px;
            top: -318px;
            width: 600px;
            z-index: -1;
        }
        &::after {
            @include icon-ini;
            background-image: url(../images/flow/img-bg.svg);
            position: absolute;
            height: 417px;
            width: 421px;
            top: -139px;
            right: 64px;
            z-index: -1;
            @include media(sm) {
              background: none;
            }
        }
    }
    .page-links {
        height: 150px;
        top: 66%;
    }
    &-contents {
        margin: 77px 0 0;
    }
    &-wrap {
        display: block;
        margin-bottom: 60px;
        &.second {
            margin-bottom: 60px;
            margin-top: 29px;
            position: relative;
            &::before {
                @include dot-human;
                position: absolute;
                height: 600px;
                right: -299px;
                top: -318px;
                width: 600px;
                z-index: 10;
            }
        }
    }


    //text関連ここから
    &-ltitle {
        @include fz(28, 75, 700);
        color: #333;
        margin-top: -2px;
        margin-left: -3px;
        &:after {
            @include icon-ini;
            background: $mainGrada;
            display:block;
            height: 1px;
            margin-top: 19px;
            margin-left: 3px;
            width: 30px;
        }
    }
    &-lltitle {
        @include fz(24, 32, 700);
        &.hoken {
            margin-bottom: 17px;
            margin-top: 8px;
        }
        &.teiki {
            margin-bottom: 17px;
            margin-top: 5px;
        }
    }
    &-block {
        &__regular {
            display: flex;
            margin-top: 60px;
            padding: 0;
            position: relative;
            width: 100%;

            &:nth-child(2){
              margin-top: 53px;
            }
            &::before {
                @include icon-ini;
                background-color: #eee;
                height: calc(2140px / 5);
                position: absolute;
                left: 549px;
                top: -100px;
                width: 2px;
            }
        }
        &__dispatch {
            display: flex;
            margin-top: 60px;
            padding: 0;
            position: relative;
            width: 100%;
            @include media(lg) {
              &.work-guide{
                margin-top: 138px;
                &::before {
                  height: 220px;
                  top: -24px;
                }
              }
            }
            &::before {
                @include icon-ini;
                background-color: #eee;
                height: calc(2140px / 5);
                position: absolute;
                left: 549px;
                top: -100px;
                width: 2px;
            }
            &:first-of-type {
                margin-top: 53px;
                &::after {
                    @include icon-ini;
                    background: linear-gradient(to top, rgba(255, 255, 255, 0.1), #fff 70%);
                    height: calc(2140px / 5 / 3);
                    position: absolute;
                    left: 549px;
                    top: -100px;
                    width: 2px;
                }
            }
            &:nth-of-type(2) {
                margin-top: 53px;
                &::after {
                    @include icon-ini;
                    background: linear-gradient(to top, rgba(255, 255, 255, 0.1), #fff 70%);
                    height: calc(2140px / 5 / 3);
                    position: absolute;
                    left: 549px;
                    top: -100px;
                    width: 2px;
                }
            }
        }
        &__flame {
            display: block;
        }
        //中身
        &__left {
            position: absolute;
            height:380px;
            width: 520px;
            z-index: 0;
        }
        &__right {
            background: #fff;
            height: 300px;
            left: 470px;
            margin-top: 80px;
            padding: 47px 109px 60px 56px;
            position: relative;
            width: 630px;
            z-index: 10;

            //step
            &--step-number {
                @include fz(18, 32, 700);
                background: #212a37;
                border-radius: 50px;
                color: #fff;
                display: inline-block;
                font-family: 'Alegreya Sans SC', sans-serif;
                height: 36px;
                padding: 1px 0 0;
                position: absolute;
                left: 31px;
                top: -16px;
                text-align: center;
                width: 100px;
                box-shadow: 0 0 0 5px #eee;
                @media all and (-ms-high-contrast: none) {
                  padding: 4px 0 0;
                }
            }
        }

        &__text {
            @include fz(15, 28, 400);
            color: #333;
            margin-top: 16px;
            &.flame {
                margin-top: 28px;
            }
        }
        &__glay {
            @include fz(13,24,400);
            color: #999999;
            margin-top: 5px;
        }

        &__number {
            @include fz(15, 28, 400);
            color: #333;
            margin-top: 29px;
            margin-left: -2px;
        }

        //item
        &__item {
            background: #f5f5f5;
            display: block;
            margin-top: 22px;
            margin-left: 3px;
            padding: 19px 22px;
            width: 472px;
            &--title {
                @include fz(14, 26, 700);
                color: #333;
                margin-bottom: 4px;
            }
            &--text {
                @include fz(14, 26, 400);
                color: #333;
            }
        }
        //btn
        &__btn {
            display: block;
            margin: 0px auto;
            width: 360px;
            &--regular {
                @include fz(16, 16, 600);
                background-color: #ff7364;
                background: $mainGrada;
                border-radius: 30px;
                color: #fff;
                display: inline-block;
                height: 60px;
                left: -1px;
                text-align: center;
                position: relative;
                padding: 21px 23px 23px 17px;
                width: 100%;
                &:before {
                    @include icon-ini;
                    background: url(../images/icon-memo.svg);
                    display: inline-block;
                    height: 20px;
                    margin-left: 22px;
                    margin-right: 7px;
                    position: relative;
                    top: -1px;
                    width: 18px;
                }
                &::after {
                    display: none !important;
                }
            }
            &--dispatch {
                @include fz(16, 59, 600);
                background-color: #ff7364;
                background: $mainGrada;
                border-radius: 30px;
                color: #fff;
                display: inline-block;
                left: -1px;
                text-align: center;
                padding: 0 0 0 25px;
                position: relative;
                height: 60px;
                width: 100%;
                &:before {
                    @include icon-ini;
                    background-image: url(../images/icon-memo.svg);
                    display: inline-block;
                    position: relative;
                    height: 20px;
                    right: 7px;
                    top: -1px;
                    width: 18px;
                }
                &:after {
                    display: none !important;
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
    }
    &-list {
        background-color: #f5f5f5;
        display: flex;
        margin-top: 30px;
        padding: 26px 25px;
        &__text {
            @include fz(14, 28, 700);
            margin-top: -2px;
            margin-left: 30px;
            &:first-child {
                margin-left: 0px;
            }
            &:before {
                @include icon-ini;
                content: '・';
                margin-left: -2px;
            }
        }
    }
    &-school {
        display: block;
        margin-bottom: 21px;
        margin-top: 46px;
        &__title {
            @include fz(20, 28, 700);
        }
        &__contents {
            display: flex;
            margin-top: 30px;
            padding: 50px 40px 50px 44px;
        }
        &__logo {
          margin-top: 80px;
          width: 297px;
          &--ohara {
            margin-left: 7px;
            margin-top: 30px;
          }
          &--fujitsu {
            margin-top: 15px;
            margin-left: -3px;
          }
        }
        &__box {
            margin-left: 30px;
            margin-top: 4px;
            width: 668px;
        }
        &.description {
            margin-bottom: 0px;
            margin-top: 10px;
        }
        &__description {
            @include fz(15, 28, 400);
            color: #333;
            margin-left: 2px;
        }
        &__bottom {
            border-top: 1px dotted #ccc;
            margin-left: 4px;
            margin-top: 21px;
            &--item {
                display: flex;
                margin-top: 25px;
                &:nth-of-type(2) {
                    margin-top: 3px;
                }
            }
            &--title {
                @include fz(15, 28, 700);
                color: #333;
                margin-right: 22px;
            }
        }
        &.blank-link {
            display: block;
            margin-bottom: 10px;
            margin-top: 1px;
        }
        &__link {
            @include fz(24, 26, 700);
            color: #36a7c7;
        }

        //ボタン
        &.link-box {
            display: flex;
            margin-left: 4px;
            margin-top: 38px;
        }
        &__btn {
            &:hover {
                opacity: 0.7;
            }
            &.btn-more {
                @include fz(14, 32, 700);
                text-align: center;
                padding: 9px 11px 0 11px !important;
                margin-right: 14px;
                width: 211px;
                &.site {
                    &:before {
                        @include icon-ini;
                        //background: url(../images/welfare/welfare-icon-site.png);
                        display: inline-flex;
                        height: 15px;
                        margin-top: -1px;
                        margin-left: -14px;
                        margin-right: 9px;
                        width: 17px;
                    }
                }
            }
        }
    }


    @include media(sm) {
        width: 100%;
        .l-page-header {
            background-image: url(../images/flow/mv_sp.jpg);
          &__title {
              margin: 0;
          }
        }
        .l-main {
            width: 100%;
        }
        .l-content {
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
        .l-container {
            display: block;
            margin: 0px auto;
            padding: 0;
            width: 90%;
                &::before {
                    top: -160px;
                    right: -140px;
                    height: 300px;
                    width: 300px;
                }
                &::after {
                    background-position: 91px 95px;
                    background-size: 67%;
                    display: block;
                    height: 300px;
                    right: 40px;
                    width: 300px;
                }
        }
        .page-links {
            display: none !important;
        }
        .sponly-box {
            &__btn {
                flex-basis: calc(50% - 7px);
            }
        }
        &-contents {
            display: block;
            margin: 50px 0;
            width: 100%;
        }

        //SP用アンカーボックス設定
        #regular, #dispatch {
            padding-top: 50px;
        }

        &-wrap {
            display: block;
            margin-bottom: 30px;
            margin-top: 0;
            &.second {
                margin-bottom: 20px;
                margin-top: 40px;
                position: relative;
                &::before {
                    height: 300px;
                    left: 210px;
                    top: -410px;
                    width: 300px;
                    z-index: -1;
                }
            }
        }

        &-block {
            &__regular {
                display: block;
                margin-top: 30px !important;
                &:first-of-type {
                    margin-top: 30px;
                    &::after {
                        display: none;
                    }
                }
                &::before {
                    display: none;
                }
            }
            &__flame {
                position: relative;
                height: auto;
                width: 100%;
                z-index: 0;
            }
            &__left {
                display: block;
                position: static;
                height: auto;
                width: 100%;
                img {
                    width: 100%;
                }
            }
            &__right {
                background: #fff;
                height: auto;
                left: 0;
                margin-top: 30px;
                padding: 0;
                padding: 0 20px 20px;
                position: relative;
                width: 100%;
                &--step-number {
                    display: block;
                    margin: 0px auto;
                    position: relative;
                    top: -52px;
                    left: 0;
                    z-index: 1;
                }
                &--bottom {
                    display: block;
                    margin-top: -40px;
                }
                @include media(sm) {
                  &--bottom {
                      margin-top: -30px;
                  }
                }
            }
            &__item {
                margin: 0px;
                margin-top: 20px;
                width: 100%;
            }
            &__dispatch {
                margin-top: 30px !important;
                width: 100%;
                &::before {
                    display: none;
                }
                &:first-of-type {
                    margin-top: 53px;
                    &::after {
                        display: none;
                    }
                }
            }
            &__btn {
                width: 100%;
                &--dispatch {
                    @include fzs(28, 30, 700);
                    padding: 21px 10px 23px 0;
                    &:before {
                        margin-left: 30px;
                        right: 7px;
                    }
                }
                &--regular {
                    @include fzs(28, 30, 700);
                    padding: 21px 10px 23px 0;
                    &:before {
                        margin-left: 15px;
                    }
                }
                &__text {
                    @include fzs(28, 48, 400);
                }
            }
        }

        //テキスト関連
        &-ltitle {
            @include fzs(42, 60, 700);
            &:after {
                margin-top: 21px;
            }
        }
        &-lltitle {
             @include fzs(32, 32, 700);
            text-align: center;
        }
    }
}
