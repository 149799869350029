@charset "utf-8";

.welfare {
    //MV・タイトル
    .l-page-header {
        background-image: url(../images/welfare/mv.jpg);
        background-image: image-set(url(../images/welfare/mv.jpg)1x,url(../images/welfare/mv@2x.jpg)2x);
        position: relative;
        &__wrap {
            &::before {
                @include icon-ini;
                background-image: url(../images/reason/img-white.png);
                bottom: 0;
                height: 372px;
                margin: auto;
                right: 0;
                left: 0;
                position: absolute;
                top: 0;
                width: 695px;
                z-index: -1;
                @include media(sm) {
                    display: none;
                }
            }
        }
    }
    .l-page-header__title {
        margin-top: -16px;
        position: relative;
        z-index: 0;
    }
    .l-page-header__subTitle {
        margin-top: -4px;
        position: relative;
        z-index: 0;
    }

    //コンテンツ
    .l-content {
        margin-bottom: 46px;
    }
    &-contents {
        margin: 75px 0;
    }
    &-wrap {
      &:first-child {
          margin-top: 0px;
          position: relative;
          &::before {
              @include dot-human;
              position: absolute;
              height: 600px;
              right: -299px;
              top: -318px;
              width: 600px;
              z-index: -1;
          }
          &::after {
              @include icon-ini;
              background-image: url(../images/welfare/img-bg.svg);
              position: absolute;
              height: 381px;
              width: 530px;
              top: -95px;
              right: 42px;
              z-index: -1;
              @include media(sm) {
                background: none;
              }
          }
      }
        &:nth-of-type(2) {
            margin-top: 78px;
            .welfare-block__text {
                margin-top: 20px;
            }
        }
        &:nth-of-type(3) {
            margin-top: 77px;
            .welfare-block__text {
                margin-bottom: 28px;
                margin-top: 0;
            }
        }
        &:nth-of-type(4) {
            margin-top: 68px;
            .welfare-caption {
                margin-top: 34px;
            }
        }
    }


    //text関連ここから
    &-ltitle {
        @include fz(28, 75, 700);
        color: #333;
        margin-left: -3px;
        &:after {
            @include icon-ini;
            background: $mainGrada;
            display:block;
            height: 1px;
            margin-top: 19px;
            margin-left: 3px;
            width: 30px;
        }
    }
    &-lltitle {
        @include fz(24, 24, 600);
        &.hoken {
            margin-bottom: 17px;
            margin-top: 8px;
        }
        &.teiki {
            margin-bottom: 17px;
            margin-top: 5px;
        }
    }
    &-caption {
        @include fz(15, 28, 400);
        margin-top: 35px;
        &__block {
            display: block;
            margin-top: 16px;
        }
        &__contents {
            @include fz(13, 24, 400);
            color: #999;
        }
    }
    &-block {
        background: #fff;
        box-shadow: -21.213px 21.213px 57px 0px rgba(0, 0, 0, 0.04);
        margin-top: 30px;
        padding: 39px 57px 41px 40px;
        &__flame {
            display: block;
            margin-top: 18px;
        }
        &__text {
            @include fz(15, 28, 400);
            color: #333;
            margin-top: 21px;
            &.flame {
                margin-top: 28px;
                .outside{
                  position:relative;
                  padding-right: 12px;
                  &::after {
                      @include icon-ini;
                      background-image: url(../images/icon-tab.svg);
                      display:inline-block!important;
                      height: 10px;
                      position: relative;
                      left: 5px;
                      top: -3px;
                      width: 10px;
                      }
                }
            }
        }
        &__number {
            @include fz(15, 28, 400);
            color: #333;
            margin-top: 27px;
        }
        &:nth-of-type(2) {
            margin-top: 33px;
            padding-top: 38px;
        }
        &:nth-of-type(3) {
            padding-top: 33px;
        }
        &:nth-of-type(4) {
            padding-top: 35px;
        }
        &__link {
            color: #36a7c7;
            position: relative;
            &:hover {
                opacity: 0.7;
            }
            &:after {
                display: none !important;
            }
        }
        .outside{
          position:relative;
          padding-right: 12px;
          &::after {
              @include icon-ini;
              background-image: url(../images/icon-tab.svg);
              display:inline-block!important;
              height: 10px;
              position: relative;
              left: 5px;
              top: -3px;
              width: 10px;
              }
        }
    }
    &-list {
        background-color: $grayColor;
        display: flex;
        margin-top: 32px;
        padding: 26px 26px;
        &__text {
            @include fz(14, 28, 700);
            margin-top: -2px;
            margin-left: 30px;
            &:first-child {
                margin-left: 0px;
            }
            &:before {
                @include icon-ini;
                content: '・';
                margin-left: -2px;
            }
        }
    }

    @include media(sm) {
        width: 100%;
        .l-page-header {
            background-image: url(../images/welfare/mv_sp.jpg);
            &__title {
                margin-top: 0;
            }
            &__subTitle {

            }
        }
        .l-content {
            margin-bottom: 0px;
            padding: 0;
        }
        .l-main {
            width: 100%;
        }
        &-contents {
            margin: 30px 0;
        }
        &-wrap {
            display: block;
            &:first-child {
                margin-top: 0px;
                position: static;
                &::before {
                    top: -195px;
                    right: -140px;
                    height: 300px;
                    width: 300px;
                    z-index: -1;
                }
                &::after {
                    background-position: 100px 15px;
                    background-size: 67%;
                    display: block;
                    height: 300px;
                    right: 25px;
                    width: 300px;
                }
            }
            &:nth-of-type(2) {
                .welfare-ltitle {
                    &:after {
                        margin-top: 14px;
                    }
                }
            }
        }
        &-block {
            background: #fff;
            box-shadow: -10px 0px 100px 0px rgba(31, 31, 31, 0.1);
            margin-top: 30px;
            padding: 20px !important;
            &__flame {
                display: block;
                margin-top: 18px;
            }
            &__text {
                @include fzs(28, 48, 400);
                margin-top: 20px;
                &.flame {
                    margin-top: 28px;
                }
            }
            &__number {
                @include fzs(28, 48, 400);
                margin-top: 20px;
            }
            &__link {
                color: #36a7c7;
                position: relative;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        &-list {
            display: block;
            &__text {
                @include fz(14, 28, 700);
                margin-top: 0px;
                margin-left: 0px;
                &:first-child {
                    margin-left: 0px;
                }
                &:before {
                    @include icon-ini;
                    content: '・';
                    margin-left: -2px;
                }
            }
        }

        //テキスト関連
        &-ltitle {
            @include fzs(42, 60, 700);
            &:after {
                margin-top: 21px;
            }
        }
        &-lltitle {
            @include fzs(32, 32, 700);
        }
        &-caption {
            @include fzs(28, 48, 400);
            margin-top: 30px;
            &__contents {
                @include fzs(24, 44, 400);
            }
        }
    }
}
