@charset "utf-8";

.reason {
    #skill,
    #fresh,
    #recruit,
    #follow,
    #fulfilling,
    #staff {
        padding-top: 100px;
        margin-top: -100px;
        position: relative;
        &:after {
            display: none;
        }
    }
    #fresh {
        margin-top: -100px + 76px;
     }
     #recruit,
     #follow {
         margin-top: -100px + 80px;
    }
    #fulfilling {
        margin-top: -100px + 81px;
    }
    #staff {
        margin-top: -100px + 76px;
    }
    //MV・タイトル
    .l-page-header {
        background-image: url(../images/reason/mv.jpg);
        background-image: image-set(url(../images/reason/mv.jpg)1x,url(../images/reason/mv@2x.jpg)2x);
        position: relative;
        z-index: 0;
        &__wrap {
            &::before {
                @include icon-ini;
                background-image: url(../images/reason/img-white.png);
                bottom: 0;
                height: 372px;
                margin: auto;
                right: 0;
                left: 0;
                position: absolute;
                top: 0;
                width: 695px;
                z-index: -1;
                @include media(sm) {
                    display: none;
                }
            }
        }
    }
    .l-page-header__title {
        margin-top: -16px;
        position: relative;
        z-index: 0;
        @include media(sm) {
            margin-top: 0;
        }
    }
    .l-page-header__subTitle {
        margin-top: -6px;
        position: relative;
        z-index: 0;
    }

    .l-content {
        margin-bottom: 18px;
    }
    .l-container {
        max-width: 1370px;
        &.dot-Circle {
            &::after {
                @include icon-ini;
                background-image: url(../images/reason/img-bg.svg);
                position: absolute;
                height: 490px;
                width: 768px;
                top: -52px;
                right: 42px;
                z-index: -1;
                @include media(sm) {
                    display: none;
                }
            }
        }
    }


    &-contents {
        margin: 102px auto;
        position: relative;

    }
    &-wrap {
        display: flex;
        margin-top: 80px;
        position: relative;
        &:first-child {
            margin-top: 0px;
            position: relative;
        }
        &:nth-of-type(2) {
          margin-top: 76px;
            .reason-text {
                margin-left: 1px;
                .reason-block--bottom{
                  margin-top:27px;
                }
            }
        }
        &:nth-of-type(3) {
          margin-top: 80px;
            .reason-lltitle {
                margin-left: 1px;
            }
        }
        &:nth-of-type(4){
            margin-top: 80px;
            .reason-right__img{
              margin-top:1px;
            }
        }
        &:nth-of-type(5) {
            margin-top: 81px;
            .reason-block--bottom {
                margin-top: 35px;
            }
        }
        &:nth-of-type(6) {
          margin-top: 76px;
          .reason-right__img{
            margin-top:5px;
          }
        }
        &:nth-of-type(even) {
            &::before {
                left: -150px;
                right: inherit;
                top: -380px;
            }
        }
    }
    &-left {
        margin-right: 20px;
        img {
            height: 100%;
            width: 100%;
            max-width: 652px;
        }
        &__img {
            flex-basis: 652px;
            img {
                height: 100%;
                width: 100%;
                max-width: 652px;
            }
        }
        &__text {
          margin-left: 130px;
          margin-top: 94px;
          flex-basis: 523px;
          &.second {
              margin-top: 112px;
              .reason-block--bottom {
                margin-top: 25px;
                .reason-list {
                  margin-top: 17px;
                }
              }
          }
          &.third {
              margin-top: 98px;
          }
        }
    }
    &-right {
        margin-left: 58px;
        margin-top: 74px;
        width: 520px;
        &__img {
            margin-left: 60px;
            img {
                height: 100%;
                width: 100%;
                max-width: 652px;
            }
        }
        &.second  {
            margin-top: 66px;
            margin-left: 58px;
            .reason-list {
                &__block {
                    &:nth-child(2) {
                        padding: 8px 28px 9px;
                    }
                }
            }
        }
        &.third {
          margin-top: 46px;
          margin-left: 38px;
          .reason-list{
            &__block{
              &:nth-child(2){
                padding:8px 26px 9px;
              }
            }
          }
        }
    }

    //text関連ここから
    &-block {
        &--bottom {
            margin-top: 26px;
        }
        &__link {
            margin-top: 20px;
            margin-left: 3px;
            .btn-more {
                @include fz(18,50,700);
                text-align: center;
                width: 200px;
            }
        }
    }
    &-ltitle {
        @include fz(36, 50, 700);
        color: #333;
    }
    &-text {
        @include fz(15, 28, 400);
        color: #333;
        margin-left: 3px;
        margin-top: 22px;
        &.third {
           margin-top: 25px;
        }
        &.second {
          margin-top: 24px;
          margin-left: 1px;
        }
        &.forth {
            margin-top: 26px;
            margin-left: 1px;
        }
    }
    &-lltitle {
        @include fz(22, 22, 700);
        @include textclip($mainGrada);
        display: inline-block;
        font-family: 'Alegreya Sans SC', sans-serif;
        &:before,
        &:after {
            @include icon-ini;
            background: url(../images/reason/reason-point-bg.png);
            display: inline-flex;
            height: 11px;
            margin-top: 0;
            width: 14px;
        }
        &:before {
            margin-right: 12px;
        }
        &:after {
            margin-left: 10px;
        }
    }
    &-list {
        margin-top: 15px;
        &__block {
            background-color: $grayColor;
            margin-top: 1px;
            padding: 7px 27px 9px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 16px !important;
            }
            &:first-child {
                margin-top: 0;
            }
            &--text {
                @include fz(16, 32, 700);
                &:before {
                  @include icon-ini;
                  background: $mainGrada;
                  border-radius: 50px;
                  display: inline-block;
                  position: relative;
                  height: 6px;
                  margin-top: -4px;
                  margin-right: 23px;
                  width: 6px;
                }
            }
        }
    }
    .top{
      .reason-list__block {
        &:nth-child(2){
          padding: 9px 27px 9px;
        }
        &:nth-child(3){
          padding: 9px 27px 9px;
        }
      }
    }

    .text-link {
        color: #36a7c7;
    }

    @include media(sm) {
        width: 100%;
        #fresh {
            margin-top: -100px + 30px;
        }
         #recruit,
         #follow {
             margin-top: -100px + 30px;
          }
          #fulfilling {
             margin-top: -100px + 30px;

          }
          #staff {
              margin-top: -100px + 30px;
        }
        .l-page-header {
            background-image: url(../images/reason/mv_sp.jpg);
        }
        .l-content {
            margin-bottom: 30px;
            padding-bottom: 0px;
        }
        &-contents {
            margin: 0;
            margin-top: 30px;
            position: relative;
        }
        &-wrap {
            display: block;
            margin-top: 30px;
            position: relative;
            &:first-child {
                margin-top: 0px;
                position: static;
            }
            &:nth-of-type(2),
            &:nth-of-type(4),
            &:nth-of-type(6){
                display: flex;
                flex-direction: column-reverse;
                margin-top: 40px;
                .reason-text {
                    margin-left: 0px;
                }
            }
            &:nth-of-type(3) {
                margin-top: 30px;
                .reason-lltitle {
                    margin-left: 0px;
                }
            }
            &:nth-of-type(5) {
                margin-top: 30px;
                .reason-block--bottom {
                    margin-top: 34px;
                }
            }
        }
        &-right {
            margin: 0;
            width: 100%;
            &.second {
                margin: 0px;
                display: block;
            }
            &.third {
                margin: 0;
                margin-top: 30px;
                width: 100%;
            }
            &__img {
                display: block;
                margin: 0px;
            }
        }

        &-left {
            margin-right: 0px;
            width: 100%;
            &__text {
                display: block;
                margin: 0;
                flex-basis: auto;
                &.second {
                    margin: 0;
                }
                &.third {
                    margin-top: 10px;
                }
            }
        }
        &-block {
            &__link {
                margin: 0px auto;
                margin-top: 20px;
                .btn-more {
                    @include fzs(36,50,700);
                    display: block;
                    text-align: center;
                    margin: 0px auto;
                    padding-top: 6px;
                }
            }
            &--bottom {
                margin-top: 20px;
            }
        }

        &-ltitle {
            @include fzs(42,60,700);
            margin-top: 20px;
        }
        &-lltitle {
            @include fzs(40,40,700);
            position: relative;
            &::before {
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
            &::after {
                margin-left: 5px;
                position: relative;
                top: 1px;
            }
        }
        &-list {
            &__block {
                padding: 15px 26px;
                &--text {
                    @include fzs(26,40,600);
                    text-indent: -15px;
                    padding-left: 18px;
                    &:before {
                        margin-right: 10px;
                        margin-top:0px;
                    }
                }
            }
        }

        &-text {
            @include fzs(28, 48, 400);
            margin: 0;
            margin-top: 10px !important;
        }
        &-image-list {
            &__item {
                width: 50%;
            }
        }
    }
}

.img-left {
    &.l-container {
        display: flex;
    }
}
.img-right {
    &.l-container {
        display: flex;
    }
}
