@charset "utf-8";


.top {
    &-guide {
        &__title {
            @include fz(24, 30, 700);
            margin-bottom: 10px;
        }

        @include media(sm) {
            &__title {
                @include fz(18, 24);
                text-align: center;

            }
        }
    }
    &-hero {
      height: 100vh;
      background-image: url(../images/top/mv.jpg);
      background-image: image-set(url(../images/top/mv.jpg)1x,url(../images/top/mv@2x.jpg)2x);
      position: relative;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(1.2);
      right: 0;
      top: 0;
      transition: opacity 1500ms, transform 1500ms $easeOutQuart;
      &.is-active {
        opacity: 1;
        transform: inherit;
        background-position: inherit;
      }
      &__image {
        height: 100%;
        position: relative;
        .l-container {
          height: 100%;
          transform: scale(1);
        }
        &--titleWrap {
          position: absolute;
          top: 26px;
          bottom: 0;
          left: 64px;
          display: flex;
          justify-content: center;
          flex-direction: column;

          background-position: none;
        }
        &--title {
          @include fz(100, 75, 700);
          font-family: $fontAlegreyaSansSC;
          letter-spacing: -0.015em;
          margin-bottom: 20px;
          position: relative;
          @media all and (-ms-high-contrast: none) {
            margin-bottom: 0;
          }
          @include media(sm) {
            @include fzs(150,110,700);
            margin-bottom: 9px;
          }
          .grad-wrap {
            display: inline-block;
            @include textclip($mainGrada);
            @include ieTextColor();
          }
          > span {
            display: block;
          }
          > span.js-mv_animation1 {
            opacity: 0;
            &:nth-child(1) {
              &.is-hero {
                opacity: 1;
                animation: heroText 500ms 0ms both;
              }
            }
          }
          span.js-mv_animation2 {
            opacity: 0;
            @include media(sm) {
                position: relative;
                top: -1px;
            }
            &.is-hero {
              opacity: 1;
              animation: heroText 500ms 100ms both;
            }
          }
          .txt {
            &-f {
              margin-left: 1px;
            }
            &-i {
              margin-left: 4px;
            }
            &-n {
              margin-left: -2px;
            }
            &-d {
              margin-left: -4px;
            }
            &-y {
              margin-left: 5px;
            }
            &-o {
              margin-left: -3px;
            }
            &-u {
              margin-left: -1px;
            }
            &-r {
              margin-left: -4px;
            }
            &-s {
              margin-left: 2px;
            }
            &-a {
              margin-left: -1px;
            }
            &-g {
              margin-left: -1px;
            }
          }
        }
        &--subTitle {
          @include fz(22, 32, 700);
          margin-left: 6px;
          position: relative;
          top: -3px;
          opacity: 0;
          @include media(sm) {
              height: calc(59px / 2);
              margin-left: 2px;
              top: -2px;
              width: calc(338px / 2);
          }
            &.is-hero {
              opacity: 1;
              animation: heroText 500ms 200ms both;
            }
        }
        &--txt {
          position: absolute;
          right: -19px;
          margin-top: 4px;
          top: 30px;
          opacity: 0;
            &.is-hero {
              opacity: 1;
              animation: fadeIn 500ms 300ms both;
            }
        }
      }
    }
    &-search {
      margin-top: -77px;
      position: relative;
      z-index: 10;
      &__title {
        align-items: center;
        background: #37414f;
        border-radius: 5px 5px 0 0;
        display: flex;
        height: 30px;
        padding: 17px 0 0 28px;
        position: relative;
        width: 520px;
        @media all and (-ms-high-contrast: none) {
          top: 1px;
        }
        &::before {
          @include icon-ini;
          background-image: url(../images/top/ico-search.svg);
          height: 14px;
          left: -11px;
          position: relative;
          top: 1px;
          width: 14px;

        }
        h2 {
          @include fz(20,20,600);
          font-family: $fontAlegreyaSansSC;
          color: #fff;
          position: relative;
          margin-right: 50px;
          &::after {
              @include icon-ini;
              background-color: #515965;
              bottom: 0;
              height: 13px;
              margin: auto;
              position: absolute;
              right: -24px;
              top: 1px;
              width: 1px;
          }
        }
        &--check {
          position: relative;
          top: 1px;
          label {
              @include fz(13,13,600);
              color: #fff;
              cursor: pointer;
              margin-right: 15px;
              position: relative;
              .checkbox-parts {
                position: relative;
                &:before {
                  @include icon-ini;
                  background: #fff;
                  border-radius: 1px;
                  height: 13px;
                  position: relative;
                  margin-right: 9px;
                  left: 0;
                  top: -1px;
                  width: 13px;
                }
              }
              input[type="checkbox"] {
                display: none;
              }
              input[type="checkbox"]:checked + .checkbox-parts {
                position: relative;
                &::after {
                    @include icon-ini;
                    background: transparent;
                    border-bottom: 3px solid $siteColor;
                    border-right: 3px solid $siteColor;
                    height: 9px;
                    left: 4px;
                    position: absolute;
                    top: 2px;
                    transform: rotate(40deg);
                    width: 6px;
                }
              }
          }
        }
      }
      &__wrap {
        position: relative;
        opacity: 0;
        transform: translate(0, 40px);
        transition: opacity 400ms, transform 400ms $easeOutSine 0ms;
        //transition: opacity 1800ms, transform 800ms $easeOutQuart 1000ms;
        z-index: 1;
        .btn-search {
          @include fz(15,60,600);
          color: #fff;
          background: #212a37;
          width: 160px;
          height: 60px;
          position: relative;
          margin-left: auto;
          &::before {
            @include icon-ini;
            background-image: url(../images/top/ico-search.svg);
            height: 18px;
            position: relative;
            left: 34px;
            width: 18px;
            top: 0;
          }
          .submit {
            @include fz(15,32,600);
            display: block;
            font-family: inherit;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            padding-left: 20px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 4px;
            }
          }
        }
      }
      &__form {
        background: #37414f;
        padding: 20px;
        border-radius: 0 5px 5px 5px;
        display: flex;

        .select {
          display: flex;
        }
        &--label {
          position: relative;
          &::before {
            @include icon-ini;
            background: url(../images/ico-arrow.svg);
            height: 9px;
            position: absolute;
            right: 20px;
            top: 25px;
            width: 14px;
            pointer-events: none;
            z-index: 10;
          }
          &::after {
              top: 0;
              background: #fff;
              content: "";
              display: inline-block;
              position: absolute;
              right: 2px;
              height: 100%;
              width: 40px;
              vertical-align: middle;
              pointer-events: none;
          }

        }
        select {
          @include fz(15,15,600);
          appearance: none;
          border-radius: 0;
          cursor: pointer;
          font-family: inherit;
          margin: 0;
          padding: 0 20px 1px 22px;
          border: 0;
          background-color: #fff;
          outline: 0;
          height: 60px;
          border-right: 1px solid #212a37;
          width: 215px;
          &::-ms-expand {
              display: none;
          }
          @media all and (-ms-high-contrast: none) {
            padding-top: 6px;
          }

        }
        input[type="text"] {
          @include fz(15,15,600);
          font-family: inherit;
          margin: 0;
          padding: 0 22px 1px 27px;
          border: 0;
          background-color: #fff;
          outline: 0;
          width: 450px;
          height: 60px;
          @media all and (-ms-high-contrast: none) {
            padding-top: 6px;
          }
        }
      }
    }
    &-reasonWhy {
      background: #f5f5f5;
      overflow: hidden;
      padding-bottom: 124px;
      padding-top: 120px;
      position: relative;
      z-index: 0;
      @media all and (-ms-high-contrast: none) {
        padding-bottom: 123px;
      }
      &__header {
        position: relative;
        &::before {
          @include icon-ini;
          background-image: url(../images/top/img-bg.svg);
          height: 490px;
          left: 121px;
          position: absolute;
          top: -133px;
          width: 768px;
          z-index: -1;
        }
        &::after {
          @include icon-ini;
          background-image: url(../images/dot.svg);
          // background-image: radial-gradient(circle farthest-side, #fca967 1px, transparent 0px, transparent 1%);
          // opacity: .8;
          background-repeat: repeat;
          background-size: 9px 9px;
          background-position: 0 0;
          border-radius: 50%;
          height: 325px;
          right: -175px;
          position: absolute;
          top: -43px;
          width: 325px;
          z-index: -1;
        }
      }
      &__title {
        margin-bottom: 14px;
        position: relative;
        text-align: center;
      }
      &__subtitle {
        margin-left: -3px;
        text-align: center;
      }
        &__contents {
          box-shadow: 30px 30px 70px 0px rgba(200,200,200,0.1);
          display: flex;
          flex-wrap: wrap;
          margin-top: 48px;
          &--imgWrap {
            p {
              @include fz(18,18,600);
              @include textclip($mainGrada);
              @include ieTextColor();
              font-family: $fontAlegreyaSansSC;
            }
          }
          &--txtWrap {
            margin-top: 11px;
            @media all and (-ms-high-contrast: none) {
          margin-top: 14px;
        }
          }
          .fresh {
            p {
              margin-top: 25px;
            }
          }
          .recruit {
            margin-top: -5px;
            p {
              margin-top: 27px;
            }
          }
          .follow {
            margin-left: 4px;
            margin-top: 3px;
            p {
              margin-top: 33px;
            }
          }
          .fulfilling {
            margin-top: 11px;
            margin-right: 18px;
            p {
              margin-top: 36px;
              margin-left: 6px;
            }
          }
          .skill {
            margin-top: 7px;
            p {
              margin-top: 34px;
            }
          }
          .staff {
            margin-top: 8px;
            margin-left: 4px;
            p {
              margin-top: 36px;
            }
          }
          &--item {
            width:269.72px;
            flex-basis: 366px;
            background: $mainGrada;
            height: 323px;
            &:nth-child(-n+3) {
              & > a {
                border-top: 1px solid #fff;
                border-left: 1px solid #fff;
              }
            }
            &:nth-child(3n) {
              & > a {
                border-right: 1px solid #fff;
              }
            }
            &:nth-child(5) {
              .top-reasonWhy__contents--title {
                margin-right: 12px;
              }
              .top-reasonWhy__contents--txt {
                margin-left: -6px;
              }
              & > a {
                padding: 50px 55px 50px 50px;
              }
            }
            &:nth-child(n+4) {
              height: 358px;
              & > a {
                padding-bottom: 84px;
                border-bottom: 1px solid #fff;
                border-left: 1px solid #fff;
                border-top: 1px solid #fff;
              }
            }
            &:nth-child(5) {
              .top-reasonWhy__contents--txtWrap {
                h3 {
                  margin-left: -14px;
                }
                p {
                  margin-left: -6px;

                }
              }
            }
          }
          &--link {
            align-items: center;
            border-right: 1px solid $grayColor;
            border-bottom: 1px solid $grayColor;
            background: #fff;
            display: block;
            height: 100%;
            padding: 50px;
            position: relative;
            justify-content: flex-end;
            text-align: center;
            border-right: 1px solid $grayColor;
            &:hover {
              opacity: 1;
              @include media(lg) {
                  border: 1px solid $grayColor !important;
              }
            }
          }
          &--title {
            @include fz(22, 32, 600);
            //margin-bottom: 6px;
          }
          &--txt {
            @include fz(14, 28, 700);
            margin-left: 4px;
            margin-top: 13px;
            text-align: left;
          }
        }
        &__big_circle {
          @include icon-ini;
          position: absolute;
          animation: MoveGrad 8s ease infinite;
          background: $mainGrada;
          background-size: 200% 200%;
          border-radius: 50%;
          height: 650px;
          left: calc(19% - 632px);
          top: -275px;
          width: 650px;
          z-index: -1;
        }
        &__small_circle {
          //animation: MoveGrad 8s ease infinite;
          @include icon-ini;
          position: absolute;
          animation: MoveGrad 8s ease infinite;
          background: $mainGrada;
          border-radius: 50%;
          bottom: -200px;
          height: 325px;
          left: inherit;
          right: calc(11% - 325px);
          top: 850px;
          width: 325px;
          z-index: -1;
        }
        .viewMore-btn {
          bottom: -24px;
          margin: auto;
          position: absolute;
          left: 0;
          right: 6px;
          width: 200px;
          .btn-more {
            display: inline-block;
          }
        }
      }

      &-recruitment {
        height: 1218px;
        &::before {
          content: "";
          background: $mainGrada;
          display: inline-block;
          position: absolute;
          height: 1081px;
          width: 100%;
          z-index: -1;
        }
        &__wrap {
          margin-top: 0;
          padding-top: 78px;
          //overflow: hidden;
        }
        &__bg {
          width: 100%;
          height: 570px;
          min-height: 570px;
          position: relative;
          overflow: hidden;
        }
        &__headerImg {
          background: url(../images/top/recruit.jpg) no-repeat;
          background-position: center center;
          background-size: cover;
          height: 120%;
          width: 100%;
          bottom: 0%;
          left: 0%;
          position: absolute;
          transform: translate3d(0px, 0px, 0px);

        }
        &__header {
          text-align: center;
          &::before {
            @include icon-ini;
            background-image: url(../images/top/img-recruitment-bk.svg);
            height: 689px;
            position: absolute;
            top: -203px;
            width: 827px;
            left: 100px;
            z-index: -1;
          }
        }
         &__title {
          color: #fff;
          margin-bottom: 12px;
         }
         &__subtitle {
          color: #fff;
         }
         &__contents {
          display: flex;
          //アニメーション★
          transition: 0ms 200ms;
          &.swiper-slide-active{

            transform: translate(0, 50px);
          }
          &--item {
            background: $mainGrada;
            position: relative;
            height: 400px;
            min-width: 275px;
            max-width: 275px;
            z-index: 1;
            &:last-child {
              a {
                border-right: 1px solid $grayColor;
              }
            }
          }
          &--link {
            background: #fff;
            border-left: 1px solid $grayColor;
            border-bottom: 1px solid $grayColor;
            border-top: 1px solid $grayColor;
            display: inline-block;
            height: 100%;
            width: 100%;
            padding: 27px 27px 40px 30px;
            box-shadow: 30px 30px 70px 0px rgba(200,200,200,0.1);
            &:hover {
                opacity: 1;
                border-right: 1px solid $grayColor;
                h3 {
                  @include textclip($mainGrada);
                  @include ieTextColor();
                }
            }
          }
          &--itemTitle {
            @include fz(18, 32, 600);
            margin-bottom: 21px;
            padding-bottom: 28px;
            position: relative;
            min-height: 124px;
            &::after {
              @include icon-ini;
              background: $mainGrada;
              bottom: 2px;
              height: 1px;
              left: 1px;
              position: absolute;
              width: 20px;
            }
          }
          &--itemTxt {
            @include fz(14, 28, 500);
            margin-bottom: 12px;
            min-height: 84px;
            li {
              span {
                font-weight: 700;
              }
            }
          }
          &--itemCategory {
            @include fz(14, 32, 600);
            @include textclip($mainGrada);
            @include ieTextColor();
            margin-bottom: 11px;
          }
          &--workStyle {
            span {
              @include fz(14, 30, 700);
              height: 35px;
              background: #eaeaea;
              border-radius: 17.5px;
              display: inline-block;
              padding: 2px 26px 1px;
              @media all and (-ms-high-contrast: none) {
                padding-top: 6px;
              }
            }
          }
         }
         .viewMore-btn {
            margin-top: 25px;
         }
      }

      &-tradingCompanies {
        margin-top: 63px;
        &__header {
          margin-bottom: 28px;
        }
        &__title {
          @include fz(18, 32, 600);
          position: relative;
          text-align: center;
          &::before {
            @include icon-ini;
            background-color: $grayColor;
            bottom: 0;
            position: absolute;
            left: 60px;
            margin: auto;
            top: 0;
            height: 1px;
            width: 400px;
          }
          &::after {
            @extend ::before;
            right: 60px;
            left: inherit;
          }
        }
        &__contents {
          ul {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            li {
              flex-basis: 20%;
              margin: 0 auto 30px;
              text-align: center;
              position: relative;
              &:first-child {
                  left: 102px;
                  top: -4px;
              }
              &:nth-child(2) {
                  left: 41px;
                  top: -4px;
              }
              &:nth-child(3) {
                  left: 2px;
                  top: 0;
              }
              &:nth-child(4) {
                  left: -31px;
                  top: -4px;
              }
              &:nth-child(5) {
                  left: -101px;
                  top: -7px;
              }
              &:nth-child(6) {
                  left: 24px;
                  top: -23px;
              }
              &:nth-child(7) {
                  left: -36px;
                  top: -24px;
              }
              &:nth-child(8) {
                  left: -46px;
                  top: -24px;
              }
              &:nth-child(9) {
                  left: -79px;
                  top: -18px;
              }
              &:nth-child(10) {
                  left: -87px;
                  top: -22px;
              }
              &:nth-child(11) {
                  flex-basis: 15%;
                  left: 53px;
                  top: -17px;
              }
              &:nth-child(12) {
                  left: 35px;
                  top: -19px;
              }
              &:nth-child(13) {
                  flex-basis: 25%;
                  left: 32px;
                  top: -18px;
              }
              &:nth-child(14) {
                  flex-basis: 25%;
                  left: 30px;
                  top: -15px;
              }
              &:nth-child(15) {
                  flex-basis: 15%;
                  left: -9px;
                  top: -16px;
              }
              &:nth-child(16) {
                  left: 100px;
                  top: -10px;
              }
              &:nth-child(17) {
                  left: 98px;
                  top: -10px;
              }
              &:nth-child(18) {
                  left: 89px;
                  top: -10px;
              }
              &:nth-child(19) {
                  left: 63px;
                  top: -10px;
              }
              &:nth-child(20) {
                  left: -33px;
                  top: -12px;
              }
              &:nth-child(21) {
                  left: 90px;
                  top: -2px;
              }
              &:nth-child(22) {
                  left: 64px;
                  top: -1px;
              }
              &:nth-child(23) {
                  left: 52px;
                  top: -4px;
              }
              &:nth-child(24) {
                  left: 24px;
                  top: -2px;
              }
              &:nth-child(25) {
                  left: -57px;
                  top: -7px;
              }
            }
          }

        }
      }

      &-interview {
        box-shadow: 0px 38px 43px -3px rgba(200, 200, 200, 0.1);
        //box-shadow:0px 46px 500px -150px rgba(200, 200, 200, 1);
        margin-top: 168px;
        position: relative;
        padding-bottom: 87px;
        &::before {
          @include icon-ini;
          background: linear-gradient(to left, rgba(255, 255, 255, 0.1), #fff 70%);
          bottom: -150px;
          left: 0;
          height: 150px;
          position: absolute;
          width: 50%;
        }
        &::after {
            @extend ::before;
            background: linear-gradient(to right, rgba(255, 255, 255, 0.1), #fff 70%);
            left: inherit;
            right: 0;
        }
        &__inner {
          &::before {
            @include icon-ini;
            // background-image: radial-gradient(circle farthest-side, #fca967 1px, transparent 0px, transparent 1%);
            background-image: url(../images/dot.svg);
            // opacity: .8;
            background-repeat: repeat;
            background-size: 9px 9px;
            background-position: 0 0;
            height: 759px;
            position: absolute;
            right: 0;
            top: -110px;
            width: 1100px;
            z-index: -1;
          }
        }

        &__wrap {
          position: relative;
        }
        &__header {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          position: relative;
          top: -52px;
          right: 175px;
          &::before {
            @include icon-ini;
            background-image: url(../images/top/img-special.png);
            background-image: image-set(url(../images/top/img-special.png)1x,url(../images/top/img-special@2x.png)2x);
            position: absolute;
            height: 151px;
            right: 153px;
            top: -69px;
            width: 115px;
          }
        }
        &__title {
          letter-spacing: 0.002em;
        }
        &__subtitle {
          position: relative;
          right: 6px;
          top: 12px;
        }
        &__contents {
          display: flex;
          justify-content: space-between;
          //アニメーション
          opacity: 0;
          transform: translate(0, 50px);
          transition: opacity 800ms, transform 800ms $easeOutQuart;
          &.is-animate {
            opacity: 1;
            transform: translate(0, 0);
          }
          &--item {
            display: inline-block;
            position: relative;
            z-index: 0;
            &.over {
              &:hover {
                opacity: 1;
                //TOPページのデカイイメージだけは、ー20pxでホバーする
                transform: translate(-20px, -20px);
              }
              @include media(sm) {
                  &:hover {
                      transform: none;
                  }
              }
            }
            &_bk {
              background-color: #ff7166;
              background: $mainGrada;
              position: relative;
              flex-basis: calc(100% / 2 - 10px);
              @media all and (-ms-high-contrast: none) {
                height: 510px;
              }
              &:first-child {
                position: relative;
                top: -103px;
              }
            }
          }
          &--imgWrap {
              height: 510px;
              img {
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover';
                width: 100%;
              }
          }
          &--txt {
            background: linear-gradient(to top, rgba(55, 65, 79, 0.5), transparent 90%);
            position: absolute;
            bottom: 0;
            color: #fff;
            padding: 40px;
            width: 100%;
          }
          &--workStyle {
            span {
              @include fz(14, 32, 700);
              background: $mainGrada;
              border-radius: 16px;
              display: inline-block;
              height: 32px;
              vertical-align: middle;
              padding: 0 20px 0 18px;
              text-align: center;
              min-width: 80px;
              @media all and (-ms-high-contrast: none) {
                padding-top: 3px;
              }
            }
          }
          &--words {
            @include fz(18, 32, 700);
            margin-top: 15px;
          }
        }
        .viewMore-btn {
          margin-top: 48px;
        }
      }

      .top-recruit {
        margin-top: 72px;
        &__header {
            text-align: center;
        }
        &__title {
            span {
              position: relative;
              @include textclip($mainGrada);
              @include ieTextColor();
              margin-bottom: 19px;
              display: inline-block;
              &::after {
                @include icon-ini;
                background-image: url(../images/top/ico-recruit-tips.png);
                background-image: image-set(url(../images/top/ico-recruit-tips.png)1x,url(../images/top/ico-recruit-tips@2x.png)2x);
                height: 19px;
                position: absolute;
                top: -6px;
                right: -18px;
                width: 18px;
              }
            }
        }
        &__subtitle {
          padding-right: 3px;
        }
        &__contents {
            margin-top: 40px;
            &--item {
            }
            &--readMore {
              border-bottom: 1px solid $grayColor;
            }
        }
        .new-pop {
          top: -30px;
          left: 6px;
          &::before {

          }
        }
        &__viewMore {
          margin-top: 30px;
          margin-bottom: 110px;
       }
      }
      &-news {
        padding-bottom: 100px;
        &__inner {
          display: flex;
          margin-bottom: 61px;
          padding-bottom: 18px;
          box-shadow: -14px 14px 70px rgba(200,200,200,0.1);
        }
        &__header {
          padding: 24px 80px 24px 76px;
          .sub-title__en {
            font-size: 37px;
            margin-bottom: 8px;
          }
        }
        &__subtitle {
          padding-left: 2px;
        }
        &__contents {
          &--listItems {
            display: flex;
            align-items: center;
            padding: 24px 0 17px;
            width: 780px;

            &:not(:last-child) {
                border-bottom: 1px solid #eaeaea;
            }
            &__link {
              @include fz(14,28,700);
              padding-top: 1px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 672px;
              &:hover {
                  opacity: 1;
                  @include textclip($mainGrada);
                  @include ieTextColor();
              }
            }
          }
          .txt-years {
            color: #999;
            @include fz(18,32,700);
            font-family: $fontAlegreyaSansSC;
            margin-right: 28px;
          }
        }
        .new-pop {
          position: relative;
          margin: 0 -5px 0 4px;
          width: 90px;
          white-space: nowrap;
          &::before {
            height: 8px;
            top: 0;
          }
          span {
            @include fz(16,16,700);
          }
        }
      }



.top-recruitment{

  .swiper-container {
    transition: opacity 200ms;
    &.is-start {
        opacity: 0;
    }
    &-pagination {
        &-bullet {
            margin: 0 0 0 15px;
        }
    }
  }
  .swiper-wrapper {
    transition-delay: 200ms;
  }
}

.is-hello_hedder{
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 400ms $easeOutQuart 0ms;
}
.is-hedder_chenge_own{
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 400ms $easeOutQuart 0ms;
}
.is-hedder_chenge_two{
  opacity: 1;
  transform: translate(0, -100%);
  transition: transform 400ms $easeOutQuart 400ms;
}

.recruit-contents {
    &__item {
        &:nth-of-type(2) {
            margin: 0px 30px;
        }
    }
}

@include media(sm) {
  .recruit-contents {
      &__item {
          &:nth-of-type(2) {
              margin: 0px 0px;
          }
      }
  }

.viewMore-btn {
  bottom: auto;
  left: auto;
  //margin-top: 43px;
  //padding-left: 10px;
  position: static;
  right: 0;
}
.top {
  &-hero {
    background: url(../images/top/mv_sp.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    //height: calc(1206px / 2);
    &__image {
      &--titleWrap {
        left: 20px;
        bottom: 101px;
      }
      &--title {
        .grad-wrap {
            left: -4px;
            position: relative;
            top: -1px;
        }
        .txt {
          &-f {
            margin-left: -1px;
          }
          &-i {
            margin-left: 8px;
          }
          &-n {
            margin-left: 4px;
          }
          &-d {
            margin-left: 3px;
          }
          &-y {
            margin-left: 0;
          }
          &-o {
            margin-left: -2px;
          }
          &-u {
            margin-left: 0px;
          }
          &-r {
            margin-left: -3px;
          }
          &-s {
            margin-left: -1px;
          }
          &-t {
            margin-left: -1px;
          }
          &-a {
            margin-left: -4px;
          }
          &-g {
            margin-left: -1px;
          }
          &-e {
            margin-left: -2px;
          }
        }
      }
      &--txt {
        right: -34px;
        top: 34px;
        img {
            height: calc(168px / 2);
            width: calc(179px / 2);
        }
      }
    }
  }
  &-search {
    margin-top: -115px;
    &__title {
        background: transparent;
        flex-wrap: wrap;
        height: auto;
        padding: 0;
        width: auto;
        &::before {
            display: none;
        }
        h2 {
            @include fzs(34,34,700);
            width: 100%;
            padding: 0;
            height: 26px;
            &::after {
                display: none;
            }
            span {
                @include fzs(34,34,700);
                background: $siteColor;
                border-radius: 5px 5px 0 0;
                display: inline-block;
                height: 26px;
                padding: 12px 0 0px 31px;
                position: relative;
                width: calc(250px / 2);
                z-index: 10;
                &::before {
                    @include icon-ini;
                    background-image: url(../images/top/ico-search.svg);
                    height: calc(24px / 2);
                    width: calc(24px / 2);
                    margin: 0;
                    position: absolute;
                    left: 16px;
                    top: 14px;
                }
            }
        }
        &--check {
            background-color: $siteColor;
            padding: 11px 14px 0 16px;
            border-top-right-radius: 5px;
            top: 0;
            width: 100%;
            label {
                margin-right: 5px;
                @include fzs(24,24,600);
                &:last-of-type {
                    margin-right: 0;
                }
                .checkbox-parts {
                    // padding-left: 18px;
                    &::before {
                        margin-right: 6px;
                        height: calc(22px / 2);
                        // position: absolute;
                        // left: 0;
                        // top: 3px;
                        width: calc(22px / 2);
                    }
                }
                input[type="checkbox"]:checked + .checkbox-parts {
                    &::after {
                        border-bottom: 2px solid #37414f;
                        border-right: 2px solid #37414f;
                        height: 7px;
                        left: 2px;
                        top: 1px;
                        width: 5px;
                    }
                }
            }
        }
    }
    &__form {
        border-radius: 0 0 5px 5px;
      display: block;
      padding: 9px 14px 15px 16px;
      &--label {
        flex-basis: 50%;
        &::before {
          height: 8px;
          right: 7.3%;
          width: 14px;
          top: 19px;
        }
        &::after {
          top: 0;
          width: 30px;
        }
        &.areaSelect {
          select {
            border-right: 0;
          }
        }
      }
      select {
        width: 100%;
        height: calc(90px / 2);
        @include fzs(26,26,600);
        padding: 1px 20px 0 15px;
        word-wrap: normal;
      }
      input[type="text"] {
        @include fzs(26,26,600);
        line-height: 1.42857143;
        width: 100%;
        border-top: 1px solid #212a37;
        height: calc(92px / 2);
        padding: 3px 13px 3px 13px;
        border-radius: 0;

      }
    }
    &__wrap {
       .btn-search {
          @include fzs(28,80,600);
           width: 100%;
           margin-top: 10px;
           text-align: center;
           height: calc(80px / 2);
           &::before {
              height: calc(28px / 2);
              left: -32px;
              top: -2px;
              width: calc(28px / 2);
           }
           .submit {
             @include fzs(28,80,600);
             margin-top: -1px;
           }
       }
    }
  }
  &-reasonWhy {
      padding-bottom: 58px;
      padding-top: 165px;
      &__header {
          margin-bottom: 28px;
          &::before {
              height: calc(476px / 2);
              width: calc(746px / 2);
              top: -41px;
              margin: auto;
              left: -58px;
              right: 0;
          }
          &::after {
              height: calc(340px / 2);
              right: -104px;
              top: 45px;
              width: calc(340px / 2);
          }
      }
      &__title {
          padding-right: 2px;
          margin-bottom: 7px;
      }
      &__subtitle {
          margin-left: -2px;
      }
      &__contents {
          &--imgWrap {
              p {
                  @include fzs(28,28,700);
                  white-space: nowrap;
              }
          }
          &--txtWrap {
              margin-top: 9px;
          }
          .fresh {
              margin-left: 4px;
              margin-top: 9px;
              img {
                  height: calc(95px / 2);
                  width: calc(95px / 2);
              }
              p {
                  margin-top: 12px;
                  padding-right: 1px;
              }
          }
          .recruit {
              margin-left: 3px;
              margin-top: 6px;
              img {
                  height: calc(96px / 2);
                  width: calc(91px / 2);
              }
              p {
                  margin-top: 14px;
              }
          }
          .follow {
              margin-left: 0px;
              margin-top: 9px;
              img {
                  height: calc(79px / 2);
                  width: calc(101px / 2);
              }
              p {
                  margin-top: 20px;
              }
          }
          .fulfilling {
              margin-right: 0;
              margin-top: 15px;
              img {
                  height: calc(67px / 2);
                  width: calc(85px / 2);
              }
              p {
                  margin-left: 0;
                  margin-top: 20px;
              }
          }
          .skill {
              margin-top: 9px;
              img {
                  height: calc(78px / 2);
                  width: calc(62px / 2);
              }
              p {
                  margin-top: 20px;
              }
          }
          .staff {
              margin-top: 12px;
              margin-left: 1px;
              img {
                  height: calc(72px / 2);
                  width: calc(72px / 2);
              }
              p {
                  margin-top: 20px;
              }
          }
          &--title {
              @include fzs(32,44,600);
              margin-left: 4px;
          }
          &--txt {
              @include fzs(28,42,400);
              margin-left: 0;
              margin-top: 7px;
          }
          &--item {
              border: none;
              background: none;
              flex-basis: calc(334px / 2);
              height: calc(525px / 2);
              &:nth-child(5) {
                  & > a {
                      padding: 15px;
                  }
              }
              &:not(:last-child) {
                  border-right: 1px solid $grayColor;
              }
              &:nth-child(5) {
                  .top-reasonWhy__contents--txtWrap {
                      h3 {
                          margin-left: 0;
                          margin-right: 0;
                          margin-top: -1px;
                      }
                      p {
                          margin-left: 0;
                      }
                  }
              }
              &:last-child {
                a {
                  padding-left: 13px;
                  padding-right: 13px;
                }
              }
          }
          &--link {
              border: none;
              padding: 15px 15px 15px 15px;
          }
      }
      &__big_circle {
          height: calc(600px / 2);
          left: -183px;
          top: -108px;
          width: calc(600px / 2);
      }
      &__small_circle {
          bottom: -63px;
          height: calc(320px / 2);
          right: -81px;
          top: auto;
          width: calc(320px / 2);
      }
      .viewMore-btn {
          bottom: auto;
          left: auto;
          margin-top: 45px;
          padding-left: 10px;
          position: static;
          right: 0;
      }
  }
  &-recruitment {
    height: 683px;
    &::before{
      height: calc(1204px / 2);
    }
    .swiper-container {
      top: 0;
    }
    &__header{
      margin-bottom: 29px;
        &::before {
            background-size: 100%;
            height: calc(634px / 2);
            top: -107px;
            width: calc(762px / 2);
            left: -4px;
            right: 0;
            margin: auto;
        }
    }
    &__headerImg {
      background: url(../images/top/recruit_sp.jpg) no-repeat;
      background-size: 100%;
      background-position: top center;
      height: calc(450px / 2);
    }
    &__bg {
      height: calc(450px / 2);
      min-height: calc(450px / 2);
    }
    &__title {
        margin-bottom: 7px;
        letter-spacing: 0.005em;
        margin-right: 1px;
    }
    &__subtitle {
        padding-left: 1px;
    }
    &__wrap {
      margin-top: 44px;
      padding-top: 0;
    }
    &__contents {
      &--item {
        background: none;
        height: auto;
        min-height: calc(556px / 2);
        margin: auto;
        width: calc(590px / 2);
        max-width: inherit;
      }
      &--link {
          border: solid 1px $grayColor !important;
          padding: 28px 21px;
          max-width: inherit;
      }
      &--itemCategory {
          @include fzs(24,24,600);
          margin-bottom: 9px;
      }
      &--itemTitle {
          @include fzs(32,50,600);
          margin-bottom: 13px;
          margin-left: -1px;
          min-height: 69px;
          padding-bottom: 19px;
          &::after {
              left: 0;
          }
      }
      &--itemTxt {
          @include fzs(28,48,400);
          margin-left: -2px;
          margin-bottom: 15px;
          min-height: 72px;
      }
      &--workStyle {
          span {
              @include fzs(22,60,600);
              height: calc(60px / 2);
              padding: 0 14px;
          }
      }
      &.swiper-slide-active {
          transform: none;
      }
    }
    .swiper {
      position: relative;
      margin-bottom: 20px;
    }
    .swiper-button-next {
      background: initial;
      right: -13%;
      top: 23.5vh;
      margin: 0;
      &::before {
        background: initial;
        height: 9px;
        width: 9px;
      }
    }
    .swiper-button-prev {
      background: initial;
      left: -1%;
      top: 23.5vh;
      margin: 0;
      &::before {
        background: initial;
        height: 9px;
        width: 9px;
      }

    }
    .swiper-pagination {
      bottom: 0;
      position: relative;
      left: 0;
      margin-top: 0;
      right: 0;
      top: initial;
      &-bullet {
        background: $mainGrada;
        position: relative;
        height: calc(14px / 2);
        margin: 0 7.6px 0;
        width: calc(14px / 2);
        &::before {
            background: linear-gradient(90deg, #ff9933 20%, #ff7166 80%);
            border: none;
            border-radius: 50%;
            height: calc(42px / 2);
            width: calc(42px / 2);
        }
        &:after {
            @include icon-ini;
            box-shadow: 0px 0px 0px 6px #fdfdfd inset;
            border-radius: 50%;
            left: -6px;
            opacity: 0;
            position: absolute;
            top: -6px;
            visibility: hidden;
            height: calc(38px / 2);
            width: calc(38px / 2);
        }
        &.swiper-pagination-bullet-active {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
      }
    }
  }
  &-tradingCompanies {
    margin-top: 49px;
    &__header {
        margin-bottom: 33px;
    }
    &__title {
      @include fzs(34,34,600);
      &::before {
        height: 1px;
        left: 0;
        top: 1px;
        width: calc(200px / 2);
      }
      &::after {
        left: inherit;
        right: 0;
      }
    }
    &__contents {
       ul {
        max-width: 335px;
        margin: 0 auto;
        li {
            flex-basis: auto;
            margin: 0 auto 10px;
            position: relative;
            //ロゴの調整
            &:first-child {
                order: 1;
                left: -10px;
                top: 0;
                img {
                    height: calc(34px / 2);
                }
            }
            &:nth-child(2) {
                order: 2;
                left: -1px;
                top: 0;
                img {
                    height: calc(33px / 2);
                    width: calc(96px / 2);
                }
            }
            &:nth-child(3) {
                order: 3;
                left: 3px;
                top: 5px;
                img {
                    width: calc(168px / 2);
                }
            }
            &:nth-child(4) {
                order: 4;
                margin-right: 10px;
                left: 11px;
                top: 2px;
                img {
                    height: calc(26px / 2);
                    width: calc(113px / 2);
                }
            }
            &:nth-child(5) {
                order: 5;
                left: -7px;
                top: -4px;
                img {
                    height: calc(79px / 2);
                    width: calc(144px / 2);
                }
            }
            &:nth-child(6) {
                order: 6;
                left: -9px;
                top: -2px;
                img {
                    height: calc(69px / 2);
                    width: calc(89px / 2);
                }
            }
            &:nth-child(7) {
                order: 7;
                left: -5px;
                top: -3px;
                img {
                    height: calc(53px / 2);
                    width: calc(170px / 2);
                }
            }
            &:nth-child(8) {
                order: 8;
                left: 0;
                top: -4px;
                img {
                    height: calc(63px / 2);
                    width: calc(173px / 2);
                }
            }
            &:nth-child(9) {
                order: 10;
                left: -8px;
                top: -4px;
                img {
                    height: calc(60px / 2);
                    width: calc(202px / 2);
                }
            }
            &:nth-child(10) {
                order: 11;
                left: -9px;
                top: -5px;
                img {
                    height: calc(44px / 2);
                    width: calc(202px / 2);
                }
            }
            &:nth-child(11) {
                order: 12;
                left: -8px;
                top: -2px;
                img {
                    height: calc(34px / 2);
                    width: calc(67px / 2);
                }
            }
            &:nth-child(12) {
                order: 13;
                left: 4px;
                top: -6px;
                img {
                    height: calc(46px / 2);
                    width: calc(168px / 2);
                }
            }
            &:nth-child(13) {
                order: 14;
                left: -5px;
                top: 2px;
                img {
                    height: calc(31px / 2);
                    width: calc(230px / 2);
                }
            }
            &:nth-child(14) {
                order: 15;
                left: -2px;
                top: 5px;
                img {
                    height: calc(26px / 2);
                    width: calc(228px / 2);
                }
            }
            &:nth-child(15) {
                order: 9;
                left: 2px;
                top: -3px;
                img {
                    height: calc(55px / 2);
                    width: calc(63px / 2)
                }
            }
            &:nth-child(16) {
                order: 17;
                left: -3px;
                top: 7px;
                img {
                    height: calc(37px / 2);
                    width: calc(195px / 2);
                }
            }
            &:nth-child(17) {
                order: 18;
                left: 1px;
                top: 7px;
                img {
                    height: calc(39px / 2);
                    width: calc(157px / 2);
                }
            }
            &:nth-child(18) {
                order: 19;
                left: 1px;
                top: 9px;
                img {
                    height: calc(36px / 2);
                    width: calc(210px / 2);
                }
            }
            &:nth-child(19) {
                order: 21;
                left: -4px;
                top: 16px;
                @include media(ss) {
                    left: 0;
                    top: 9px;
                    margin-left: 10px;
                }
                img {
                    height: calc(31px / 2);
                    width: calc(81px / 2);
                }
            }
            &:nth-child(20) {
                order: 22;
                left: -3px;
                top: 11px;
                @include media(ss) {
                    left: 0;
                    top: 1px;
                }
                img {
                    height: calc(50px / 2);
                    width: calc(66px / 2);
                }
            }
            &:nth-child(21) {
                order: 23;
                left: 1px;
                top: 17px;
                img {
                    height: calc(29px / 2);
                    width: calc(132px / 2);
                }
            }
            &:nth-child(22) {
                order: 24;
                left: 2px;
                top: 15px;
                img {
                    height: calc(33px / 2);
                    width: calc(161px / 2);
                }
            }
            &:nth-child(23) {
                order: 16;
                left: 5px;
                top: 3px;
                img {
                    height: calc(31px / 2);
                    width: calc(153px / 2);
                }
            }
            &:nth-child(24) {
                order: 25;
                left: 6px;
                top: 15px;
                img {
                    height: calc(22px / 2);
                    width: calc(130px / 2);
                }
            }
            &:nth-child(25) {
                order: 20;
                left: 5px;
                top: 4px;
                img {
                    height: calc(49px / 2);
                    width: calc(56px / 2);
                }
            }
        }
       }
    }
  }
  &-interview {
    box-shadow: 0px 38px 43px -3px rgba(200, 200, 200, 0.1);
    margin-top: 89px;
    padding-bottom: 51px;
    &::before {
        display: none;
    }
    &__inner {
      &::before {
        top: -24px;
        height: calc(1619px / 2);
        right: 0;
        left: auto;
        width: calc(681px / 2);
      }
    }
    &__title {
      margin-bottom: 7px;
    }
    &__subtitle {
      top: 0;
      right: 0;
      padding-left: 3px;
    }
    &__header {
      margin-bottom: 30px;
      &::before {
        background-image: url(../images/top/img-special_sp.png);
        background-image: image-set(url(../images/top/img-special_sp.png)1x,url(../images/top/img-special_sp@2x.png)2x);
        right: 0;
        height: calc(229px / 2);
        width: calc(173px / 2);
        left: 22px;
        top: -58px;
        z-index: -1;
      }
    }
    &__contents {
      display: block;
      &--imgWrap {
        height: calc(632px / 2);
        width: 100%;
      }
      &--item_bk {
        background: none;
        margin-bottom: 20px;
        &:first-child {
          top: 0;
        }
      }
      &--txt {
        padding: 0 $space 25px;
      }
      &--workStyle {
        padding-left: 1px;
        span {
            @include fzs(24,54,600);
            height: calc(54px / 2);
            padding: 0 10px 0 10px;
            min-width: calc(138px / 2);
        }
      }
      &--words {
        @include fzs(32,44,600);
        margin-top: 7px;
      }
    }
    &__header {
      top: 0;
    right: 0;
    align-items: center;
    }
    .viewMore-btn {
      margin-top: 30px;
    }
  }
  &-recruit {
    margin-bottom: 30px;
    margin-top: 38px !important;
    &__title {
        @include fzs(80,80,800);
        span {
          margin-bottom: 7px;
          padding-right: 2px;
          &::after {
              background-image: url(../images/top/ico-recruit-tips_sp.png);
              background-image: image-set(url(../images/top/ico-recruit-tips_sp.png)1x,url(../images/top/ico-recruit-tips_sp@2x.png)2x);
              height: calc(28px / 2);
              right: -12px;
              top: -1px;
              width: calc(28px / 2);
          }
        }
    }
    &__subtitle {
      padding-right: 2px;
    }
    .new-pop {
      position: absolute;
      top: -6px;
      left: 12px;
      line-height: 1;
    }
    &__contents {
      margin-top: 29px;
      &--imgWrap {
          flex-basis: calc(300px / 2);
      }
      &--txtWrap {
        display: flex;
        flex-direction: column;
        flex-basis: calc(100% - 150px - 13px);
        justify-content: space-between;
      }
    }
    .viewMore-btn {
      margin-top: 10px;
      a {
          letter-spacing: 0.03em;
      }
    }
  }
}
}
@include media(sm) {
  &-news {
    padding-bottom: 0;
    &__inner {
        padding: 20px 20px 20px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
        position: relative;
    }
    &__header {
        padding: 0;
        text-align: center;
    }
    &__title {
        &::after {
            left: 0;
            margin: auto;
            right: 0;
            top: 75px;
        }
    }
    &__subtitle {
        margin-bottom: 0;
    }
    &__contents {
        padding: 0;
        width:100%;
        &--listItems {
            align-items: flex-start;
            flex-direction: column;
            position: relative;
            width: 100%;
            &__link {
                white-space: normal;
                overflow: auto;
                text-overflow: clip;
                width: 100%;
            }
        }
    }
    .new-pop {
        position: absolute;
        margin: auto;
        left: 90px;
        top: 31px;
    }
    .recruit-more {
        bottom: 20px;
        position: absolute;
        margin: auto;
        right: 20px;
    }
    .viewMore-btn {
        margin-top: 30px;
        margin-bottom: 51px;
    }
  }
}
    @include media(ss) {
          .top {
          &-tradingCompanies {
          margin-top: 49px;
          &__header {
              margin-bottom: 33px;
          }
          &__title {
            @include fzs(34,34,600);
            &::before {
              height: 1px;
              left: 0;
              top: 1px;
              width: calc(160px / 2);
            }
            &::after {
              left: inherit;
              right: 0;
            }
          }
        }
      }
    }
}

.top-recruitment__contents {
  &.swiper-slide {
    &.swiper-slide-active,
    &.swiper-slide-prev,
    &.swiper-slide-next,
    &.swiper-slide-duplicate-active,
    &.swiper-slide-duplicate-prev,
    &.swiper-slide-duplicate-next {
        .is-end & {
            //opacity: 1;
            transform: translate(0, 0);
        }
    }
    &.swiper-slide-prev,
    &.swiper-slide-duplicate-prev {
      opacity: .7;
      pointer-events: none;
        .is-end & {
            transition: opacity 500ms, transform 500ms;
        }
    }
    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
      pointer-events: auto;
        .is-end & {
            //transition: opacity 500ms 150ms, transform 500ms 150ms;
            //中央のスワイパーの速さはココで指定する
            transition: opacity 150ms 0ms, transform 150ms 0ms;
        }
    }
    &.swiper-slide-next,
    &.swiper-slide-duplicate-next {
      opacity: .7;
      pointer-events: none;
        .is-end & {
            transition: opacity 500ms 300ms, transform 500ms 300ms;
        }
    }
  }
}

@include media(ss) {
  .top {
    & &-recruitment {
      .swiper-button-next {
        right: -20%;
        top: 26.5vh;
      }
      .swiper-button-prev {
        left: -5%;
        top: 26.5vh;
      }
    }
  }
}

@include media(ss) {
  .top {
    .top-search {
          &__title {
              &--check {
                  label {
                      @include fz(10,10,600);
                      // margin-right: 10px;
                      .checkbox-parts {
                          // padding-left: 12px;
                          &::before {
                              height: calc(18px / 2);
                              width: calc(18px / 2);
                              // top: 1px;
                          }
                      }
                      input[type="checkbox"] {
                          &:checked + .checkbox-parts {
                              &::after {
                                  left: 2px;
                                  top: 2px;
                              }
                          }
                      }
                  }
              }
          }
      }
      .top-interview {
        &__contents {
          &--words {
            @include fz(14,14,600);
          }
          &--imgWrap {
            height: calc(525px / 2);
          }
        }
      }
      .top-recruit {
        &__contents {
          &--imgWrap {
            flex-basis: calc(270px / 2);
          }
          &--txtWrap {
            flex-basis: calc(100% - 150px - 8px);
          }
        }
      }
    }
}
