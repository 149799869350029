@charset "utf-8";

.dispatch {
    .l-page-header{
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        position:relative;
        z-index: 1;
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
        }
        &__wrap {
            top: -16px;
            @include media(sm) {
                top: 0;
            }
        }
        &__title {
            @media screen and (max-width: 320px) {
                font-size: 20px;
            }
        }
        &__subTitle {
            position: relative;
            top: -3px;
            @include media(sm) {
                top: 0;
            }
        }
    }
    .l-scroll-down_view{
        top:40px;
    }
    .dot-Circle {
        &::after {
            @include icon-ini;
            background-image: url(../images/form/img-bg.svg);
            height: 317px;
            position: absolute;
            right: 42px;
            top: -167px;
            width: 389px;
            z-index: -1;
            @include media(sm) {
                display: nonek
            }
        }
    }

    &.confirm {
        .form-overview__inner {
            margin-bottom: 0;
        }
    }

    //調整用
    @include media(lg) {
      .select-wrap {
          align-items: center;
          &_item {
              &::after {
                  top: 0;
                }
          }
          .form-overview {
              .text_bg {
                  padding: 7px 20px;
              }
              &__inner {
                  .select-wrap {
                      align-items: center;
                      &_item {
                          &--block {
                              align-items: center;
                          }
                      }
                  }
                  .line {
                      .text {
                          align-items: center;
                          display: flex;
                          flex-wrap: wrap;
                          span {
                              margin-bottom: 10px;
                          }
                      }
                      .select-wrap {
                          align-items: center;
                          &_item {
                              &--block {
                                  align-items: center;
                              }
                          }
                      }
                      &.job-hunt {
                          .select-wrap {
                              margin-bottom: 15px;
                          }
                      }
                      .station {
                          .form-overview__inner--contents {
                              align-items: center;
                              flex-wrap: wrap;
                          }
                      }
                  }
              }
          }
      }
      .name {
          span {
              margin-bottom: 0 !important;
          }
      }
      .work {
          span {
              margin-bottom: 0px !important;
          }
      }
      .birth {
          span {
              margin-bottom: 0 !important;
          }
      }
      .text {
          span {
              display: inline-block;
              margin-bottom: 5px;
          }
      }
    }
    .start {
        span {
            margin-bottom: 0 !important;
        }
        .text_bg {
            padding: 2px 20px;
            @include media(sm) {
                padding: 6px 20px 0;
            }
        }
    }
    .os {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
    .select-wrap_item--block {
        align-items: center;
        @include media(sm) {
            margin-bottom: 10px;
        }
    }
    .tenkyo {
        textarea {
            padding: 2px 20px !important;
            @include media(sm) {
                padding: 6px 20px 0 !important;
            }
        }
    }
    .time {
        .select-wrap_inner {
            align-items: center;
            .labelTit {
            }
            .overtime {
                align-items: center;
                margin-bottom: 0;

            }
        }
    }
    .apply-company {
        span {
            margin-bottom: 0 !important;
        }
    }
    .telphone {
        .telphone__txtlong {
            margin: 0 0 0 10px !important;
        }
    }
    .text__box {
        margin-bottom: 30px;
    }
    .pr-boxtype {
        margin-top: 80px;
    }
    .form-overview__inner .line:nth-of-type(5) {
        align-items: center;
    }
    .form-overview__inner .line:nth-of-type(6) {
        align-items: center;
    }
    .check-wrap input[type="checkbox"] {
        margin-right: 10px !important;
    }
}
@include media(sm) {
    .dispatch {
      .select-wrap {
          //align-items: flex-start !important;
          &_item {
              &::after {
                  top: -1px;
                }
          }
      }
        .form-overview {
            &__inner {
                .line {
                    &:not(:first-of-type) {
                        padding: 20px 0;
                    }
                    &.job-hunt {
                        .cptn {
                            margin-bottom: 10px;
                        }
                    }
                    .hope-years {
                        .text_bg {
                            padding-top: 10px;
                        }
                    }
                }
                .birth {
                    .select-wrap {
                        align-items: center;
                        &_item--block {
                            align-items: normal;
                            select {
                                font-size: 10px !important;
                            }
                        }
                    }
                }
                .salary {
                    .text {
                        align-items: center;
                        .cptn {
                            margin-top: 0;
                        }
                        span {
                            margin-bottom: 0;
                        }
                        input {
                            margin-bottom: 0;
                            margin-top: 0;
                        }
                    }
                }
                .apply-company {
                    .text {
                        .text_bg {
                            padding: 4px 10px 0;
                        }
                    }
                    .select-wrap {
                        &_item--block {
                            margin-bottom: 10px;
                        }
                        .time-applicants {
                            margin-bottom: 10px;
                        }
                    }
                }
                .work-location {
                    .select-wrap_item {
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                }
            }
            .text_bg {
                margin-bottom: 8px;
            }
        }
        .text {
            span {
                display: inline-block;
                margin-bottom: 10px;
            }
        }
        .work {
            span {
                margin-bottom: 0px;
            }
        }
        .excel {
            align-items: center;
        }
        .start {
            span {
                margin-bottom: 0;
            }
        }
        .hope-years {
            textarea {
                padding: 6px 20px 0 !important;
            }
        }
        .name {
            span {
                margin-bottom: 0 !important;
            }
            input {
                margin-top: 0 !important;
            }
        }
    }
    .other-input {
        //その他
        align-items: center;
        width: 100% !important;
    }
}
