@charset "utf-8";

.sitemap {
    .l-page-header{
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        position:relative;
        z-index: 1;
        .l-scroll {
            right: 8.6%;
            top: -61px;
        }
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2);
        }
    }
    .l-page-header__title {
        margin-top: -18px;
        position: relative;
        z-index: 0;
      @include media(sm) {
          margin-top: 0;
      }
    }
    .l-page-header__subTitle {
        position: relative;
        top: -4px;
    }
    .l-content {
        margin-bottom: 163px;
        margin-top: 56px;
        @include media(sm) {
            margin-bottom: 0px;
            margin-top: 0px;
            padding-bottom: 30px;
        }
    }
    .l-block{
        position:relative;
        z-index: 0;
    }

    //調整
    //横にシュのやつ
    .bar-slide {
        &:after {
            bottom: -1px;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
    &-wrap {
        display: block;
        padding: 12px 43px 13px 37px;
        width: 100%;
        &:nth-child(odd) {
            background: #fff;
        }
        &:nth-child(even) {
            background-color: #f9f9f9;
            padding-top: 11px;
        }
        &:first-child {
            background: transparent;
        }
        &:nth-child(4) {
            padding-top: 10px;
        }
        &:nth-child(6) {
            padding-bottom: 38px;
        }
        &:nth-child(8) {
            margin-top: 28px;
        }
        //&:last-child {
            //margin-top: 22px;
            //padding-bottom: 37px;
            //padding-top: 16px;
        //}
        @include media(sm) {
            padding: 20px;
            &:first-child {
                margin-top: 0px;
                position: relative;
            }
            &:last-child {
                padding-bottom: 30px;
                padding-top: 20px;
            }
            &:nth-child(even) {
                padding-top: 18px;
            }
        }
    }

    .sitemap-guidline{
        position: relative;
        width: 1110px;
        &__inner{
            &--headline{
                @include fz(28,75,700);
                margin-bottom:53px;
                position:relative;
                &:before{
                  position:absolute;
                  content:"";
                  background:$mainGrada;
                  width:30px;
                  height:1px;
                  top:94px;
                }
                &.intro {
                    display: block;
                    &:before{
                        position:absolute;
                        content:"";
                        background:$mainGrada;
                        width:30px;
                        height:1px;
                        top:94px;
                    }
                }
            }
        }
        @include media(sm) {
            display: block;
            margin: 30px auto;
            width: 100%;
        }
    }
    .link-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -2px;
        &__link {
            @include fz(15, 28, 700);
            align-items: center;
            border-bottom: 1px solid #eaeaea;
            color: $textColor;
            margin-right: 30px;
            margin-top: 15px;
            position: relative;
            width: 320px;
            //横間隔調整
            &:first-child {
                margin-left: 0px;
            }
            &:nth-child(3),
            &:nth-child(6) {
                margin-right: 0px;
            }
            &:last-child {
                margin-right: 0px;
            }
            //縦間隔調整
            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 0px;
            }
            &--text {
                display: block;
                margin: 0;
                padding: 8px 13px;
                padding-bottom: 21px;
                width: 100%;
                &:hover {
                    opacity: 1;
                    @include textclip($mainGrada);
                    @include media(sm) {
                      color:#333;
                      -webkit-text-fill-color:#333;
                      opacity: .8;
                    }
                }
            }
            &--border {
                @include fz(18, 76, 700);
                border-bottom: 1px solid #333;
                position: relative;
                &::after {
                    display: none;
                }
                &:hover{
                  @include textclip($mainGrada);
                  opacity: 1;
                  position: relative;
                  &::after{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    -webkit-transform: scale(1, 1);
                    transform: scale(1, 1);
                    -webkit-transform-origin: left top;
                    transform-origin: left top;
                  }
                  @media all and (-ms-high-contrast: none) {
                    background: none;
                    color: #ff9933;
                    &::after {
                      top: 28px;
                    }
                  }
                }
                &::after{
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;
                  content: "";
                  display: inline-block;
                  vertical-align: middle;
                  background: -webkit-gradient(linear, left top, right top, color-stop(50%, #ff9933), to(#ff7166));
                  background: -webkit-linear-gradient(left, #ff9933 50%, #ff7166);
                  background: linear-gradient(90deg, #ff9933 50%, #ff7166);
                  left: 0;
                  margin: auto;
                  height: 1px;
                  right: 0;
                  position: absolute;
                  bottom: -1px;
                  -webkit-transform: scale(0, 1);
                  transform: scale(0, 1);
                  -webkit-transform-origin: right top;
                  transform-origin: right top;
                  width: 100%;
                  @media all and (-ms-high-contrast: none) {
                    top: 82px;
                  }
                }
            }

            //リンク横の丸矢印
            .ico-grad-arrow {
              top: 14px;
              right: 8px;
              position: absolute;
            }
            &.bar-slide {
              @include media(lg) {
                position: relative;
                &::after {
                    @include icon-ini;
                    transform: scale(0, 1);
                    transform-origin: right top;
                    transition: transform 200ms;
                }
                &:hover {
                    transition: 200ms;
                    &::after {
                        background: $mainGrada;
                        @include icon-ini;
                        transform: scale(1, 1);
                        transform-origin: left top;
                        position: absolute;
                    }
                }
              }
            }
        }
        @include media(sm) {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            &__link {
                @include fz(13, 25, 600);
                margin-left: 0px;
                margin-right: 0;
                padding: 0 10px;
                width: 100%;
                &--border {
                    @include fzs(36, 56, 700);
                }
                &--text {
                    align-items: center;
                    display: flex;
                    height: 50px;
                    padding: 0;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    width: 100%;
                }
                .ico-grad-arrow {
                  position: absolute;
                  top: 38%;
                }
                &:nth-child(n + 3){
                  margin-top:0;
                }
            }
        }
    }

    .dot-Circle {
        &::before {
            @include media(sm) {
                top: -210px;
            }
        }
    }

}
