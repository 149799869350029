@charset "utf-8";

.company {
    .l-page-header {
        background-image: url(../images/company/mv.jpg);
        background-image: image-set(url(../images/company/mv.jpg)1x,url(../images/company/mv@2x.jpg)2x);
        background-position: center center;
        position: relative;
        z-index: 1;
        @include media(sm) {
            background-image: url(../images/company/mv_sp.jpg);
        }
        &__wrap {
            left: 2px;
            top: -16px;
            @include media(sm) {
            	top: 0;
            }
        }
        &__title{
            @include media(sm) {
            }
        }

        &__subTitle {
            left: -1px;
            position: relative;
            top: -2px;
            @include media(sm) {
            }
        }
    }
    .l-content {
        @include media(sm) {
            padding-bottom: 0;
        }
    }
    .dot-Circle {
        &::before {
        }
        &::after {
            @include icon-ini;
            background-image: url(../images/company/img-bg.svg);
            height: 232px;
            position: absolute;
            right: 28px;
            top: -75px;
            width: 453px;
            z-index: -1;
            @include media(sm) {
              background: none;
              top: -80px;
              right: -60px;
              background-size: 60%;
            }
        }
    }
    .company-overview.dot-Circle {
        &::before {
            top:-335px;
            z-index:-1;
            @include media(sm) {
              top:-140px;
            }
        }
        &::after {
            right: 29px;
            top: -48px;
            width: 453px;
            z-index: -1;
            @include media(sm) {
              top: -80px;
              right: -60px;
              background-size: 60%;
            }
        }
    }

    .company-location.dot-Circle {
        &::before {
            top: -345px;
            @include media(sm) {
              top:-140px;
            }
        }
        &::after {
            @include icon-ini;
            background-image: url(../images/company/img-bg.svg);
            height: 232px;
            position: absolute;
            right: 37px;
            top: -48px;
            width: 453px;
            z-index: -1;
            @include media(sm) {
              background: none;
              top: -80px;
              right: -60px;
              background-size: 60%;
            }
        }
    }

    .company-history.dot-Circle {
        &::after {
            top: -66px;
        }
    }

    .company-guidline.dot-Circle {
        &::before {
            top: -345px;
            @include media(sm) {
              top:-140px;
            }
        }
        &::after {
            @include icon-ini;
            background-image: url(../images/company/img-bg.svg);
            height: 232px;
            position: absolute;
            right: 28px;
            top: -45px;
            width: 453px;
            z-index: -1;
            @include media(sm) {
              background: none;
              top: -80px;
              right: -60px;
              background-size: 60%;
            }
        }
    }

    .l-block{
        @include media(sm) {
            display: block;
            margin: 0px auto;
        }
    }
    &-info {
        margin-bottom: 61px;
        margin-top: 104px;
        @include media(sm) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        &__list {
            align-items: center;
            flex-wrap: wrap;
            display: flex;
            justify-content: flex-start;
        }
        &__item {
            flex-basis: 346px;
            margin-bottom: 55px;
            @include media(sm) {
                flex-basis: 100%;
                margin-bottom: 30px;
            }
            &:not(:nth-child(3n)) {
                margin-right: 31px;
                @include media(sm) {
                    margin-right: 0;
                }
            }
        }
        &__link {
            border-bottom: 1px solid #eaeaea;
            display: block;
            &:hover {
                opacity: 1;
                .company-info__imgarea {
                    opacity: .8;
                }
            }
        }
        .bar-slide {
            &:after {
                background: $mainGrada;
                bottom: 0;
                height: 1px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                width: 100%;
            }
        }
        &__imgarea {
            margin-bottom: 25px;
            @include media(sm) {
                margin-bottom: 30px;
                img {
                    height: 100%;
                    width: 100%;
              }
            }
        }
        &__title {
            @include fz(18,28,600);
            display: flex;
            justify-content: space-between;
            margin-bottom: 21px;
            padding: 0 10px;
            @include media(sm) {
                @include fzs(32,32,600);
                margin-bottom: 30px;
            }
            .ico-grad-arrow {
                top: 6px;
                @include media(sm) {
                  top:2px;
                }
            }
        }
    }


    &-overview{
        width:1100px;
        margin:71px auto 126px;
        position:relative;
        z-index: 0;
        &__inner{
            z-index: 0;
            &--table{
                font-family: $fontFamily;
                @include fz(15,28,400);
                .line {
                    border-bottom:1px solid #eaeaea;
                    position: relative;
                    display: block;
                    &:after{
                        content:"";
                        position: absolute;
                        background: $mainGrada;
                        width:150px;
                        height:1px;
                        bottom:-1px;
                        left:0px;
                    }
                    .title{
                        @include fz(15, 32, 700);
                        width:150px;
                        position:relative;
                        text-indent:18px;
                    }
                    .text{
                        @include fz(15, 28, 400);
                        padding: 24px 0 22px 27px;
                        span{
                          font-weight:bold;
                          }
                    }
                    .bottom{
                        padding-bottom:20px;
                    }
                    .map-btn {
                        padding: 0;
                        vertical-align: middle;
                        padding-left: 38px;
                        padding-top: 30px;
                        .btn-more {
                            @include fz(13,28,700);
                            font-family: $fontFamily;
                            text-indent: 12px;
                            height: 40px;
                            padding: 7px 11px 7px 22px !important;
                            position: relative;
                            @media all and (-ms-high-contrast: none) {
                              padding-top: 9px !important;
                            }
                            &:before{
                                @include icon-ini;
                                position:absolute;
                                background:url(../images/company/ico-map.svg)no-repeat;
                                width:11px;
                                height:14px;
                                top:13px;
                                left:17px;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
              }
              &:nth-child(2){
                .line{
                  .text{
                    padding: 24px 0 21px 27px;
                  }
                }
              }
          }
        }
        @include media(sm) {
            margin-bottom: 30px;
            margin-top: 30px;
            width:100%;
            tbody {
                display: block;
                margin-bottom: 20px;
                width: 100%;
            }
            &__inner{
                display: block;
                width: 100%;
                &--table{
                    display: block;
                }
                .line{
                    border-bottom:none;
                    display: grid;
                    padding-bottom: 20px;
                    &:after {
                        width: 100%;
                    }
                    &.last {
                        border-bottom: none;
                    }
                    .title{
                        @include fzs(32, 32, 700);
                        display: block;
                        text-indent: 0px;
                        width: 100%;
                        &:after{
                            position: absolute;
                            background: $mainGrada;
                            width:100%;
                            height:1px;
                            bottom:-1px;
                            left:0px;
                        }
                    }
                    .text {
                        @include fzs(28, 48, 400);
                        display: block;
                        margin: 0px;
                        margin-top: 10px;
                        padding: 0 !important;
                        width: 100%;
                        span {
                            font-weight:bold;
                          }
                    }
                    .bottom{
                        padding-bottom:20px;
                    }
                    .map-btn {
                        margin: 0px auto;
                        margin-top: 20px;
                        padding-left: 0px;
                        padding-top: 0px;
                        width: calc(460px / 2);
                        .btn-more {
                            @include fzs(26, 32, 700);
                            font-family: $fontSpFamily;
                            width:100%;
                            height: 41px;
                            margin: 0 auto;
                            padding: 13px 21px !important;
                            position: relative;
                            text-align: center;
                            text-indent: 12px;
                            &:before{
                                content: "";
                                position: absolute;
                                background: url(../images/company/ico-map.svg)no-repeat;
                                left: 30%;
                                top:13.5px;
                            }
                        }
                    }
              }
            }
        }
    }

    &-button {
        background-image: url(../images/dot.svg);
        background-repeat: repeat;
        background-position: 0 0;
        background-size: 9px 9px;
        padding: 61px 0 54px;
        @include media(sm) {
            padding: 30px 0;
        }
        &__inner {

        }
        &__list {
            align-items: center;
            display: flex;
            justify-content: center;
            @include media(sm) {
                flex-wrap: wrap;
            }
        }
        &__item {
            background: $mainGrada;
            box-shadow: 21.213px 21.213px 50px 0px rgba(0, 0, 0, 0.04);
            flex-basis: 346px;
            height: 150px;
            margin: 0 15px;
            @include media(sm) {
                flex-basis: 100%;
                margin: 0 0 20px;
                height: 100px;
            }
        }
        &__link {
            align-items: center;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            height: 100%;
            padding: 0 40px 0 37px;
            width: 100%;
            @include media(sm) {
                padding: 0 20px 0 27px;
            }
            &:hover {
                opacity: 1;
            }
            .ico-grad-arrow {
                background-size: 20px;
                height: 20px;
                width: 20px;
                @include media(sm) {
                    height: 15px;
                    width: 15px;
                }
                i {
                    border-top: 3.5px solid transparent;
                    border-bottom: 3.5px solid transparent;
                    border-left: 6px solid #fff;
                    display: inline-block;
                    position: absolute;
                    top: 6.5px;
                    left: 8px;
                    @include media(sm) {
                        border-top: 2px solid transparent;
                        border-bottom: 3px solid transparent;
                        border-left: 5px solid #fff;
                        top: 5px;
                        left: 6px;
                    }
                }
            }
        }
        &__text {
            position: relative;
            @include media(sm) {
                top: 8px;
            }
        }
        &__ja {
            @include fz(22,22,600);
            margin-bottom: 11px;
            @include media(sm) {
                @include fzs(32,32,600);
                margin-bottom: 0px;
            }
        }
        &__en {
            @include fz(18,18,700);
            @include textclip($mainGrada);
            font-family: $fontAlegreyaSansSC;
            left: 2px;
            position: relative;
            @include media(sm) {
                @include fzs(26,26,700);
            }
        }
      }

    &-history{
        width: 1100px;
        margin: 93px auto 152px;
        position: relative;
        &__inner{
          &--table{
              @include fz(15,28,400);
              font-family: $fontFamily;
            .line{
              display: block;
              border-bottom:1px solid #eaeaea;
              padding-top: 35px;
              padding-bottom: 20px;
              position: relative;
              &:after{
                @include icon-ini;
                position: absolute;
                background: $mainGrada;
                width: 170px;
                height:1px;
                bottom: -1px;
                left: 0;
              }
              @include media(lg) {
                  &:first-child {
                      padding-top: 0;
                  }
                  &:not(:first-child) {
                      padding-top: 32px;
                      padding-bottom: 21px;
                  }
                  &:nth-child(3) {
                      padding-bottom: 20px;
                  }
                  &:nth-child(4) {
                      padding-top: 31px;
                      padding-bottom: 20px;
                  }
                  &:nth-child(5) {
                      padding-bottom: 19px;
                  }
                  &:nth-child(6) {
                      padding-top: 32px;
                  }
                  &:nth-child(7) {
                      padding-top: 31px;
                  }
                  &:nth-child(8) {
                      padding-top: 30px;
                  }
                  &:nth-child(9) {
                      padding-top: 32px;
                  }
              }

              .title{
                  @include fz(15, 32, 700)
                  width: 198px;
                  padding-left: 18px;
                  padding-right: 10px;
                  position: relative;
              }
              .text{
                  @include fz(15, 28, 400);
                span{
                  font-weight:bold;
                  }
              }
            }
          }
        }
        @include media(sm) {
            display: block;
            margin: 0 auto;
            margin-top: 30px;
            width: 100%;
            &__inner{
                &::before{
                  position:absolute;
                  content:"";
                  //background:url(../images/company/img-bg-company.png);
                  width:625px;
                  height:571px;
                  top:0;
                  right:0;
                }
                &--table{
                    @include fz(15,28,400);
                    font-family: $fontFamily;
                    tbody {
                        display: block;
                        margin-bottom: 20px;
                        width: 100%;
                    }
                    .line{
                        border: none;
                        padding: 20px 0 16px;
                        .title{
                            @include fzs(32, 32, 700);
                            display: block;
                            padding: 0;
                            text-indent: 0px;
                            width: 100%;
                            &:after{
                              bottom: -10px;
                              height: 1px;
                              left: 0;
                              width: 100%;
                            }
                        }
                        .text{
                            @include fzs(28, 48, 400);
                            display: block;
                            margin: 0px;
                            margin-top: 20px;
                            padding-left: 0;
                        }
                        &:after {
                            width: 100%;
                        }
                    }
                    .height{
                        padding-bottom:21px;
                        .title{
                            &:after{
                                bottom: -22px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-location{
        width:1110px;
        margin:75px auto 164px;
        position:relative;
        @include media(sm) {
            margin-bottom: 40px;
        }
        &__inner{
            &--table{
                margin-bottom:78px;
                .headline{
                    @include fz(28, 75, 700);
                    color: #333;
                    &:after {
                        @include icon-ini;
                        background: $mainGrada;
                        display:block;
                        height: 1px;
                        margin-top: 18px;
                        width: 30px;
                    }
                }
                .subline{
                    @include fz(20,26,700);
                    margin-top: 32px;
                }
                .locationTable{
                    padding:40px 20px;
                    box-shadow: -21.213px 21.213px 57px 0px rgba(0, 0, 0, 0.04);
                    background:#fff;
                    margin-top: 33px;
                    .line{
                        display: block;
                        background:#fff;
                        padding: 39px 0 39px 0;
                        margin: 0 63px;
                        .title{
                            @include fz(22, 32, 700);
                            vertical-align: middle;
                            text-align: center;
                            text-indent: -6px;
                            width:280px;
                        }
                        .text{
                            @include fz(15,28,400);
                            padding-left:37px;
                            width:547px;
                            @include media(sm) {
                                @include fzs(28, 48, 400);
                            }
                            .listLink{
                              color:#36a7c7;
                            }
                        }
                      .spaceBottom{
                        padding-top:9px;
                      }
                      .map-btn{
                        padding:0;
                        vertical-align: middle;
                        padding-left: 32px;
                        padding-bottom:9px;
                        font-family:$fontFamily ;
                        .btn-more {
                          @include fz(13,28,700);
                          font-family: $fontFamily;
                          text-indent: 15px;
                          height: 41px;
                          padding: 7px 20px !important;
                          position: relative;
                          @media all and (-ms-high-contrast: none) {
                            padding-top: 9px !important;
                          }
                          &:before{
                            content:"";
                            position:absolute;
                            background:url(../images/company/ico-map.svg)no-repeat;
                            width:11px;
                            height:14px;
                            top:13px;
                            left:19px;
                          }
                        }
                      }
                      .spaceTop{
                        padding-bottom:25px!important;
                      }
                    }
                  .space{
                      padding: 41px 0 24px 0;
                      .title{
                        vertical-align: middle;
                        width:280px;
                        text-align: center;
                        padding:30px 0;
                      }
                  }
                  .topP{
                    padding: 48px 0 40px 0;
                    .title{
                      padding: 0px 0 10px 0;
                    }
                  }
                  .topS{
                    padding:42px 0 42px 0;
                    .title{
                      padding: 0px 0 10px 0;
                    }
                  }
                  .topD{
                    padding:40px 0 42px 0;
                    .title{
                      padding: 0px 0 10px 0;
                    }
                  }
                  .topT{
                    padding:33px 0 42px 0;
                    .title{
                      padding: 9px 0 10px 0;
                    }
                  }
                  .border{
                    border-bottom:1px dotted #eaeaea;
                  }
                }
              .nextTitle{
                  .line{
                      .title{
                        @include fz(20,42,700);
                        vertical-align: middle;
                        width:280px;
                      }
                  }
              }
            }
            .bottom{
              margin-bottom:55px;
            }
        }
        @include media(sm) {
            margin-top: 30px;
            width: 100%;
            &__inner {
                &--table{
                    margin-bottom: 30px;
                    .headline {
                        @include fzs(42, 22, 700);
                    }
                    .locationTable {
                        display: block;
                        margin-top: 28px;
                        padding: 20px;
                        width: 100%;
                        .line {
                            margin: 0px auto;
                            padding: 0;
                            .map-btn {
                              display: block;
                              margin-top: 20px !important;
                              margin: 0px auto;
                              padding-left: 0px;
                              width: calc(460px / 2);
                                .btn-more {
                                    font-family: $fontSpFamily;
                                    display: block;
                                    margin: 0px auto;
                                    width:100%;
                                    text-align: center;
                                    text-indent: 12px;
                                    &:before{
                                      left:30%;
                                      top:13.5px;
                                    }
                                }
                            }
                        }
                      .title {
                          @include fzs(32, 32, 700);
                          display: block;
                          text-align: center !important;
                          width: 100% !important;
                      }
                      .text {
                        @include fzs(28, 48, 400);
                        display: block;
                        margin-top: 15px;
                        padding-left: 0 !important;
                        width: 100% !important;
                      }
                      .topP,.topD{
                          padding: 0;
                          padding-bottom: 20px;
                      }
                    }
                    .nextTitle{
                        .line{
                            .title{
                              @include fz(20,42,700);
                              padding: 0;
                              padding-top: 15px;
                              vertical-align: middle;
                            }
                        }
                    }
                    tbody {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    &-guidline{
        width:1110px;
        margin:87px auto 125px;
        position:relative;
        &__inner{
            &--headline{
                @include fz(28,75,700);
                margin-bottom:20px;
                &:after {
                    @include icon-ini;
                    background: $mainGrada;
                    display:block;
                    height: 1px;
                    margin-top: 18px;
                    width: 30px;
                }
            }
            &--text{
                @include fz(15,28,400);
                @include media(sm) {
                    @include fzs(28, 48, 400);
                }
            }
            &--linkText{
                @include fz(15,28,400);
                text-align:center;
                margin-top:50px;
                @include media(sm) {
                    @include fzs(28, 48, 400);
                }
            }
            .right{
              text-align: right;
            }
            &--number{
                margin:20px 0;
                list-style: decimal;
                padding-left: 17px;
                .list{
                    @include fz(15,28,400);
                    margin:20px 0;
                    @include media(sm) {
                        @include fzs(28, 48, 400);
                    }
                }
                .listTit{
                    @include fz(15,28,700);
                    margin:5px 0;
                    @include media(sm) {
                        @include fzs(32, 32, 700);
                        margin-bottom:10px;
                    }
                }
                .another{
                    @include fz(15,28,400);
                    display: block;
                    margin:5px 0 30px 0;
                    @include media(sm) {
                        @include fzs(28, 48, 400);
                    }
                }
                .another-number {
                    display: block;
                    &:first-child {
                        &:before {
                            content: "（１）";
                        }
                    }
                    &:nth-child(2) {
                        &:before {
                            content: "（２）";
                        }
                    }
                    &:nth-child(3) {
                        &:before {
                            content: "（３）";
                        }
                    }
                    &:nth-child(4) {
                        &:before {
                            content: "（４）";
                        }
                    }
                    @include media(sm) {
                      padding-left: 32px;
                      text-indent: -44px;
                    }
                }
            }
          &--circle{
              @include fz(15,28,400);
              margin:5px 0 30px 0;
              list-style: circle;
              padding-left: 30px;
              .point{
                  @include fz(15,28,400);
                  &.tit {
                      @include fz(15,28,600);
                      .listLink{
                        color:#42acca;
                        position:absolute;
                        &::after {
                           @include icon-ini;
                           background-image: url(../images/icon-tab.svg);
                           height: 10px;
                           position: relative;
                           left: 5px;
                           top: -3px;
                           width: 10px;
                           }
                      }
                  }
              }
              .tit {
                @include fz(15,28,700);
                list-style:initial;
              }
              .nashi{
                list-style:none;
              }
          }
          .telArea{
            .lead{
              display:inline-block;
              width:120px;
            }
          }
        }
        @include media(sm) {
            margin: 0px auto;
            margin-top: 30px;
            margin-bottom: 60px;
            width: 100%;
            &__inner{
                &--headline{
                    @include fzs(42, 22, 700);
                    &:after {
                        margin-top: 20px;
                    }
                }
                &--circle{
                    margin: 0 auto;
                    margin-bottom: 20px;
                    .point{
                        @include fzs(28, 48, 700);
                    }
                    .tit{
                      @include fzs(28, 48, 700);
                    }
                    .nashi{
                      @include fzs(28, 48, 400);
                    }
                }
            }
        }
    }
    .l-content {
      a[target="_blank"]::after {
        background-image: none;
      }
    }
    //dispatch_law_infoのリンク
    .formLink-box{
        margin-top: 30px;
        text-align: center;
        &__link {
           @include fz(15,50,700);
            font-family: $fontFamily;
            padding: 0 41px 0;
            min-width: calc(360px / 2);
            @media all and (-ms-high-contrast: none) {
              padding-top: 4px;
          }
        }
        @include media(sm) {
            &__link {
                &.btn-more {
                    font-family: $fontSpFamily;
                    height: 50px;
                    @include fzs(28, 52, 700);
                    padding: 12px 20px 12px;
                }
            }
        }
    }
}
