@charset "utf-8";

.job-apply {
    .l-page-header{
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        position:relative;
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2);
        }
        &__wrap {
            top: -16px;
            @include media(sm) {
                top: 0;
            }
        }
        &__title {
            @media screen and (max-width: 320px) {
                font-size: 20px;
            }
        }
        &__subTitle {
            position: relative;
            top: -3px;
            @include media(sm) {
                top: 0;
            }
        }
    }
    .l-scroll-down_view{
        top:40px;
    }
    .dot-Circle {
        &::after {
            @include icon-ini;
            background-image: url(../images/form/img-bg.svg);
            height: 317px;
            position: absolute;
            right: 42px;
            top: -167px;
            width: 389px;
            z-index: -1;
            @include media(sm) {
              background: none;
            }
        }
    }
    .P_message {
        @include fz(15,24,600);
        margin-top: 20px;
        text-align: center;
    }

    .meisyou {
        display: block;
    }

    .form-block .select-wrap_item {
        &::after {
            top: 0;
        }
    }
    .form-overview__inner {
         .line {
            &:last-child {
                margin-bottom: 0px;
            }
         }
    }
    .select-wrap_item {
        &--block {
            align-items: center;
        }
        &::after {
            top: 0;
        }
    }
    .check-wrap {
        margin-top: 20px;
    }
    .telphone {
        &__txt {
            &--text {
                margin-right: 0px !important;
            }
        }
    }
    .select-wrap {
        .u-my--xxs {
        }
    }
    .check-wrap input[type="checkbox"] {
        margin-right: 10px !important;
    }
}

@include media(sm) {
    .job-apply {
        .form-overview {
            &__inner {
                .line {
                    &:not(:first-of-type) {
                        padding: 20px 0;
                    }
                }
            }
        }
        .form-block .select-wrap_item {
            &::after {
                top: 0;
            }
        }
        .telphone {
            &__txt {
                &--text {
                    margin: 0px !important;
                }
            }
        }
    }
    .meisyou {
        display: block;
        width: 100%;
    }
}
