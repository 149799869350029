@charset "utf-8";

// clearfix
@mixin clearfix() {
    &::before,
    &::after {
        content: '';
        display: table;
    }
    &::after {
        clear: both;
    }
}

// 画像置換
@mixin hideText() {
    color: transparent;
    font: 0/0 a;
    overflow: hidden;
    text-shadow: none;
}

// テキスト省略
@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin notEllipsis() {
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
}

// フォントサイズとラインハイトまとめて指定 / fzsはスマホ
// ----------------------------------------------------------
@mixin fz($size, $height: false, $weight: false) {
    font-size: #{$size}px;
    // font-size: #{$size / $fontRem}rem;
    @if $weight {
        font-weight: $weight;
    }
    @if $height {
        line-height: $height / $size;
    }
}

@mixin fzs($size, $height: false, $weight: false) {

    $size: $size/2;
    $height: $height/2;
    font-size: #{$size}px;
    @if $weight { font-weight: $weight; }
    @if $height { line-height: $height / $size; }

}

// letter-spacingをトラッキングの数値から計算
@mixin letterSpacing($tracking) {
    letter-spacing: #{$tracking / 1000}em;
}

// gradation
@mixin gradation($topColor, $bottomColor, $angle: to bottom) {
    background: mix($topColor, $bottomColor, 50%); //開始色と終了色の中間の色を背景色に指定
    background: linear-gradient($angle, $topColor, $bottomColor);
}

@mixin ieTextColor(){
  @media all and (-ms-high-contrast:none){
    color: $gradeTop;
    background: none;
  }
}


// media query
@mixin media($size, $px: false) {
    @if ($size == 'sm') {
        @media screen and (max-width: $breakPoint - 1) {
            @content;
        }
    }
    @else if ($size == 'lg') {
        @media screen and (min-width: $breakPoint) {
            @content;
        }
    }
    @else if ($size == 'retina') {
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
            @content;
        }
    }
    @else if ($size == 'ss') {
        @media screen and (max-width: $breakPointSs) {
            @content;
        }
    }

    @if ($px) {
        @if ($size == 'max-width') {
            @media screen and (max-width: $px) {
                @content;
            }
        }
        @else if ($size == 'min-width') {
            @media screen and (min-width: $px) {
                @content;
            }
        }
        @else if ($size == 'max-height') {
            @media screen and (max-height: $px) {
                @content;
            }
        }
        @else if ($size == 'min-height') {
            @media screen and (min-height: $px) {
                @content;
            }
        }
    }
}
// アイコン関連
// ----------------------------------------------------------
@mixin icon-ini {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: "";
    display: inline-block;
    vertical-align: middle;
}

//文字切り抜き
@mixin textclip($color) {
    background-color: $gradeTop;//IEの時にグラデがかからない単色で
    background: #{$color};
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

//背景のドットのやつ
@mixin dot-human {
    //background-image: radial-gradient(circle farthest-side, #fca967 1px, transparent 0px, transparent 1%);
    background-image: url(../images/dot.svg);
    background-repeat: repeat;
    border-radius: 50%;
    content: "";
    display: inline-block;
}
