@charset "utf-8";

.recruit {
    .l-page-header{
        background-image: url(../images/recruit/mv.jpg);
        background-image: image-set(url(../images/recruit/mv.jpg)1x,url(../images/recruit/mv@2x.jpg)2x);
        position:relative;
        height:500px;
        &__wrap{
            top: -16px;
        }
        &__title{}
        &__subTitle {
            position: relative;
            top: -3px;
            left: -3px;
        }
    }

    .l-block{
        position:relative;
        margin-top:0;
    }

    .dot-Circle {
        &::before {
            top: -380px;
            right: -310px;
            @include media(sm) {
              top:0;
            }
        }
    }

    &-info{
      width:1136px;
      margin:42px auto 98px;
      padding-top:50px;
      background:url(../images/recruit/img-bg-people.png)no-repeat top right;
      background-image: image-set(url(../images/recruit/img-bg-people.png)1x,url(../images/recruit/img-bg-people@2x.png)2x);
      background-position-x: 473px;
      background-position-y: -74px;
      background-size: 60%;
      position:relative;
      z-index: 0;
      &:before{
          @include icon-ini;
        position:absolute;
        background-image: url(../images/recruit/img-bg-recruit.png);
        width:534px;
        height:361px;
        top:-5%;
        left:160px;
        z-index: -1;
        @include media(sm) {
          background: none;
        }
      }
      &__inner{
        &--textblock{
          height:397px;
          margin-left:17px;
          .headline{
            @include fz(41, 62, 700);
            margin-bottom:5px;
            font-family: $fontFamily;
          }
          .subline{
            @include fz(18,32,700);
            @include textclip($mainGrada);
            font-family: 'Alegreya Sans SC', sans-serif;
            margin-bottom:17px;
            display: inline-block;
          }
          .text{
            @include fz(15,34,700);
          }
        }
      }
      &__buttom{
        width:1100px;
        z-index: 0;
          &--inner{
              .list{
                  display: flex;
                  justify-content: space-between;
                  .line{
                      margin-right:19px;
                      background: $mainGrada;
                      &:last-child{
                        margin-right: 0;
                      }
                      .link{
                        @include fz(27,30,700);
                        display: inline-block;
                        position: relative;
                        width:540px;
                        height:380px;
                        color:#fff;
                        text-align: center;
                        padding-top: 161px;
                          .spel{
                            @include fz(19,32,700);
                            @include textclip($mainGrada);
                            font-family: 'Alegreya Sans SC', sans-serif;
                          }
                          &:hover{
                              opacity: 1;
                           }
                           img{
                             max-width:inherit;
                           }
                       }
                       .graduate{
                           background:url(../images/recruit/img-graduate.jpg)no-repeat top;
                           background-image: image-set(url(../images/recruit/img-graduate.jpg)1x,url(../images/recruit/img-graduate@2x.jpg)2x);
                           &:after {
                              display: none;
                           }
                       }
                       .career{
                          background:url(../images/recruit/img-career.jpg)no-repeat top;
                          background-image: image-set(url(../images/recruit/img-career.jpg)1x,url(../images/recruit/img-career@2x.jpg)2x);
                          &:after {
                              display: none;
                          }
                       }
                       &:first-child{
                         margin-left: 20px;
                       }
                    }
                }
            }
        }
    }
      @include media(sm) {
          .l-page-header {
              background-image: url(../images/recruit/mv_sp.jpg);
              height: calc(482px / 2);
            &__wrap {
                top: 0;
            }
          }
          &-info{
              background:none;
              margin: 40px auto;
              padding-top: 0;
              position: relative;
              width: 100%;
              &:before{
                  position:absolute;
                  background-size: 50%;
                  content: "";
                  top: -40px;
                  left: -131px;
                  z-index: -1;
              }
              &:after{
                  position:absolute;
                  content:"";
                  width: 220px;
                  height: 150px;
                  top: -86px;
                  right: -37%;
              }
              &__inner{
                  margin-bottom:21px;
                  &--textblock{
                      height:auto;
                      margin-left:0;
                      .headline{
                          @include fz(25, 30, 700);
                          margin-bottom:5px;
                          font-family: $fontFamily;
                      }
                      .subline{
                          @include fz(15,22,700);
                          font-family: 'Alegreya Sans SC', sans-serif;
                          margin-bottom:17px;
                      }
                      .text{
                          @include fz(13,24,700);
                      }
                  }
              }
              &__buttom{
                width:100%;
                margin:0 auto;
                  &--inner{
                      .list{
                          display: block;
                          .line{
                            margin-bottom:30px;
                              &:last-child{
                                margin-right: 0;
                              }
                              .link{
                                  @include fz(22,30,700);
                                  background-size: cover;
                                  display: inline-block;
                                  position: relative;
                                  margin: 0 auto;
                                  width: 100%;
                                  height: 150px;
                                  padding: 50px 0 0 0;
                                  &:hover{
                                      opacity: 1;
                                   }
                               }
                               &:first-child{
                                 margin-right:0;
                                 margin-left: 0;
                               }
                            }
                        }
                    }
                }
          }
      }
      @include media(ss) {
        .l-container{

        }
        &-info{
            background:none;
          }
      }
    .dot-Circle {
        &::before {
            top: -380px;
            @include media(sm) {
                height: 300px;
                right: -150px;
                top: -150px;
                width: 300px;
            }
        }
    }
}
