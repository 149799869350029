@charset "utf-8";
//recruit tips記事詳細
.single-recruittips {
    margin-top: 85px;
    &__header {
        margin-bottom: 27px;
        padding-right: 1em;
    }
    &__category {
        @include fz(18,18,600);
        color: #999999;
    }
    &__date {
        @include fz(20,20,500);
        color: #999999;
        font-family: $fontAlegreyaSansSC;
    }
    &__title {
        @include fz(34,50,600);
        padding-bottom: 19px;
        position: relative;
        margin-top: 11px;

    }


    article {
        h2 {
            @include fz(24,32,600);
            margin-top: 60px;
            margin-bottom: 24px;
            padding-bottom: 25px;
            position: relative;
            &::before {
                @include icon-ini;
                background-color: #eaeaea;
                bottom: 0;
                left: 0;
                height: 1px;
                position: absolute;
                width: 100%;
            }
            &::after {
                @extend ::before;
                background: $mainGrada;
                width: 100px;
            }
        }
        h3 {
            @include fz(18,30,600);
            margin-bottom: 14px;
        }
        h4 {
            @include fz(18,30,600);
            border: 1px solid #eaeaea;
            min-height: 50px;
            vertical-align: middle;
            padding: 9px 12px;
            margin-bottom: 23px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 18px;
            }
        }
        p {
            @include fz(15,30,500);
            margin-bottom: 30px;
        }
        a {
            color: #36a7c7;
        }
        img {
            margin-bottom: 30px;
            margin-top: 25px;
            height: auto;
        }
    }


    &__content {
        margin-bottom: 54px;
        &--mainImg {
            @include media(sm) {
              font-family: 'object-fit: cover';
              object-fit: cover;
              height: 100%;
            }
        }
        &--movie {
            width: 100%;
            height: 407px;
            margin-bottom: 39px;
            margin-top: 51px;
        }
    }
    &__txtlink {
        padding-left: 1px;
        margin-bottom: 51px;
        a {
            @include fz(15,15,600);
            align-items: center;
            color: $textColor;
            display: flex;
            .ico-grad-arrow {
                margin-left: 10px;
            }
        }
        & a[target="_blank"]::after {
            content: none;
        }
    }
    &__writearea {
        p {
            @include fz(14,28,600);
        }
    }
    .glaybox {
        background: $grayColor;
        padding: 24px 43px 31px;
        margin-bottom: 35px;
        &__company {
            @include fz(14,30,600);
            align-items: center;
            display: flex;
            margin-bottom: 4px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 6px;
            }
        }
        &__name {
            @include fz(18,30,600);
            display: inline-block;
            margin-top: -3px;
            padding-left: 14px;
        }
        &__txt {
            @include fz(14,26,500);
            margin-bottom: 0;
        }
    }
    .btn-cvs {
        text-align: center;
        margin-top: -4px;//wpにしたら消す！！
        margin-bottom: 60px;//wpにしたら20px！！
        padding-left: 2px;
        .btn-more {
            @include fz(14,50,600);
            color: #fff;
            font-family: $fontFamily;
            height: 50px;
            min-width: 211px;
            &::before {
                @include icon-ini;
                background: url(../images/icon-dl.svg);
                height: 12px;
                position: relative;
                right: 10px;
                top: -1px;
                width: 14px;
            }
        }
        & a[target="_blank"]::after {
            content: none;
        }
    }
    &__footer {
        align-items: flex-start;
        background-color: #fff;
        box-shadow: 21.213px 21.213px 50px 0px rgba(0, 0, 0, 0.04);
        display: flex;
        margin-bottom: 58px;
        min-height: 190px;
        padding: 30px;
        position: relative;
        &--imgarea {
            flex-basis: 160px;
            height: 129px;
            img {
                font-family: 'object-fit: cover';
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        &--textarea {
            flex-basis: calc(100% - 160px - 30px);
            margin-left: 30px;
        }
        &--subtitle {
            @include fz(15,15,600);
            color: #999999;
            margin-top: 2px;
            position: relative;
            margin-bottom: 16px;
            &::before {
                @include icon-ini;
                background: url(../images/recruittips/ico-share.svg);
                height: 16px;
                left: 2px;
                margin-right: 15px;
                position: relative;
                top: -2px;
                width: 15px;
            }
        }
        &--title {
            @include fz(18,28,600);
        }
        &--sharesns {
            position: absolute;
            bottom: 28px;
            right: 32px;
            .single-sharesns {
                margin-top: 0;

                &__item {
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &__related {
        margin-bottom: 45px;
        &--title {
            @include fz(18,18,600);
            margin-bottom: 40px;
        }
        &--list {
            display: flex;
            flex-wrap: wrap;
        }
        &--item {
            align-items: flex-start;
            border-bottom: 1px solid #eaeaea;
            display: flex;
            flex-basis: 345px;
            margin-bottom: 30px;
            margin-right: 30px;
            padding-bottom: 25px;
            &:nth-of-type(even) {
                margin-right: 0;
            }
            &:hover {
                opacity: 1;
                .single-recruittips__related--item_txtarea-txt {
                    @include textclip($mainGrada);
                }
                .single-recruittips__related--item_txtarea-cate,
                .single-recruittips__related--item_imgarea {
                    opacity: .8;
                }
                &::after {
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                }
            }
            &_imgarea {
                flex-basis: 130px;
                height: 105px;
                position: relative;
                img {
                    font-family: 'object-fit: cover';
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
            &_txtarea {
                flex-basis: calc(100% - 130px - 20px);
                margin-left: 20px;
                &-cate {
                    @include fz(13,13,600);
                    color: #999999;
                    margin-bottom: 11px;
                }
                &-txt {
                    @include fz(14,24,600);
                }
            }
        }
    }


    @include media(sm) {
        margin-top: 30px;
        &__header {
            margin-bottom: 20px;
        }
        &__category {
            @include fz(14,14,600);
        }
        &__title {
            @include fzs(42,60,600);
            padding-bottom: 15px;
        }
        .glaybox {
            margin-bottom: 30px;
            padding: 10px 15px 20px;
            &__company {
                display: block;
            }
            &__name {
                display: block;
                padding-left: 0px;
            }
            &__txt {
                @include fzs(24,48,500)
            }
        }
        .btn-cvs {
            .btn-more {
                height: calc(80px / 2);
                min-width: calc(360px / 2);
                font-family: $fontSpFamily;
                line-height: 1;
                padding: 13px 0 0 0;
            }
        }
        &__content {
            &--movie {
                height: 320px;
            }
        }
        article {
            h2 {
                @include fzs(32,43,600);
                margin-bottom: 15px;
                margin-top: 30px;
                padding-bottom: 10px;
            }
            h3 {
                @include fzs(32,43,600);

            }
            h4 {
                @include fzs(30,43,600);
                min-height: 40px;
            }
            p {
                @include fzs(28,48,500);
            }
        }
        &__footer {
            padding: 20px 15px 45px;
            min-height: inherit;
            &--imgarea {
                flex-basis: 90px;
                height: 73px;
            }
            &--textarea {
                flex-basis: calc(100% - 90px - 10px);
                margin-left: 10px;
            }
            &--subtitle {
                @include fz(12,12,600);
                margin-bottom: 8px;
                &::before {
                    margin-right: 5px;
                }
            }
            &--title {
                @include fz(14,21,600);
            }
            &--sharesns {
                bottom: 10px;
                right: 15px;
                .single-sharesns {
                    &__title {
                        display: none;
                    }
                    &__item {
                        margin-right: 5px;
                    }
                }
            }
        }
        &__related {
            margin-bottom: 0;
            &--item {
                margin-right: 0;
                &:last-child {
                    margin-bottom: 0;
                }
                &_txtarea {
                    &-txt {
                        @include fzs(24,42,600);
                    }
                }
            }
        }
    }
}

//記事詳細ページSNSボタン
.single-sharesns {
    align-items: center;
    display: flex;
    &__title {
        @include fz(18,18,800);
        color: #999999;
        font-family: $fontAlegreyaSansSC;
        margin-right: 14px;
    }
    &__list {
        align-items: center;
        display: flex;
        position: relative;
        .tw {
            top: 4px;
        }
        .fb {
            top: -2px;
        }
        .hb {
            top: 4px;
        }
     }
    &__item {
        margin-right: 10px;
        position: relative;
    }
}
@include media(sm) {
    .single-sharesns {
        align-items: flex-start;
        margin-top: 10px;
        &__title {
            @include fz(16,16,800);
            margin-right: 10px;
        }
        &__list {
            flex-wrap: wrap;
            top: -5px;
        }
        &__item {
            &:not(:last-child) {
                margin-right: 5px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.single {
    //位置調整
    .l-side {
        margin-top: 77px;
    }
    .l-container {
    }
    .search-tags {
        margin-top: 0;
        margin-bottom: 45px;
        position: relative;
        @include media(sm) {
            margin-bottom: 25px;
        }
        &::before {
            @include icon-ini;
            background: $mainGrada;
            bottom: -20px;
            height: 1px;
            position: absolute;
            left: 0;
            width: 30px;
            @include media(sm) {
                bottom: -9px;
            }
        }
        &__list {
            border: none;
            padding: 0;
            @include media(sm) {
                padding: 0;
            }
            &::before {
                display: none;
            }
        }
        &__link {
            @include fz(13,32,600);
            padding: 1px 15px 0 16px;
            min-width: 60px;
        }
    }
}
.news {
    //位置調整
    .single-recruittips {
        margin-top: 83px;
        @include media(sm) {
            margin-top: 30px;
        }
        &__title {
            margin-top: 10px;
            &::before {
                @include icon-ini;
                background: $mainGrada;
                bottom: -15px;
                height: 1px;
                position: absolute;
                left: 0;
                width: 30px;
                @include media(sm) {
                    bottom: -15px;
                }
            }
        }
        &__header {
            margin-bottom: 30px;
        }
        &__content {
            &--mainImg {
                margin-bottom: 32px;
            }
        }
        .btn-pdf {
            margin-bottom: 20px;
            padding-left: 2px;
            @include media(sm) {
                text-align: center;
                padding-left: 0;
            }
            .btn-more {
                @include fz(14,50,600);
                color: #fff;
                font-family: $fontFamily;
                height: 50px;
                padding: 0 30px 0 38px;
                position: relative;
                min-width: 211px;
                text-align: center;
                width: inherit;
                @include media(sm) {
                    font-family: $fontSpFamily;
                }
                &::before {
                    @include icon-ini;
                    background: url(../images/icon-dl.svg);
                    height: 12px;
                    position: relative;
                    right: 10px;
                    top: -1px;
                    width: 14px;
                }
            }
            & a[target="_blank"]::after {
                content: none;
            }
        }
    }
    .l-side {
        margin-top: 85px;
        @include media(sm) {
            margin-top: 0 !important;
        }
    }
    .l-content {
        margin-bottom: 60px;
        .l-container {
            &::before {
              @include dot-human;
              height: 600px;
              position: absolute;
              right: -300px;
              top: -220px;
              width: 600px;
              z-index: -1;
              @include media(sm) {
                  height: 300px;
                  top: -110px;
                  right: -150px;
                  width: 300px;
              }
            }
        }
    }
}


//wp化したら消したほうがいいやつppのための調整
.pp-img {
    margin-bottom: 22px !important;
}
