@charset "utf-8";

.recruittips-news {
    .l-page-header {
        top: 20px;
        background-image: url(../images/recruittips/mv.jpg);
        background-image: image-set(url(../images/recruittips/mv.jpg)1x,url(../images/recruittips/mv@2x.jpg)2x);
        &__title {
            @include fz(57.22,57.22,800);
            @include textclip($mainGrada);
            font-family: $fontAlegreyaSansSC;
            margin-top: -37px;
            position: relative;
            &::after {
                @include icon-ini;
                background-image: url(../images/recruittips/ico-recruit-tips.png);
                background-image: image-set(url(../images/recruittips/ico-recruit-tips.png)1x,url(../images/recruittips/ico-recruit-tips@2x.png)2x);
                position: absolute;
                height: 23px;
                right: -21px;
                top: -5px;
                width: 23px;
            }
        }
        &__subTitle {
            @include fz(15,15,600);
            background: none;
            font-family: $fontFamily;
            position: relative;
            top: 14px;
            -webkit-text-fill-color: #333;
        }
    }

    .l-content {
        margin-bottom: 119px;
        margin-top: 85px;
        & > .l-container {
            &:before {
                @include dot-human;
                height: 600px;
                position: absolute;
                right: -300px;
                top: -300px;
                width: 600px;
                z-index: -1;
            }
        }
        .l-main {
            position: relative;
            left: -15px;//左の余白がでカラム落ちするので
        }
        .l-side {
            margin-left: 32px;//同じくカラム落ちする...
            margin-top: 0;
            @include media(sm) {
                margin-left: 0;
            }
        }
    }

    &-news {
        margin-bottom: 49px;
        &__inner {}
        &__header {
            align-items: center;
            background-color: $grayColor;
            border-radius: 3px;
            display: flex;
            padding: 0 0 1px 21px;
            height: 47px;
            width: 100%;
          }
          &__cap {
              @include fz(18,18,700);
              color: #bebebe;
              font-family: $fontAlegreyaSansSC;
              margin-right: 14px;
              @media all and (-ms-high-contrast: none) {
              padding-top: 6px;
            }
          }
          &__title {
              @include fz(20,20,600);
              @media all and (-ms-high-contrast: none) {
              padding-top: 11px;
              }
          }
          &__year {
            @include fz(15,15,700);
            margin-left: 14px;
            @media all and (-ms-high-contrast: none) {
            padding-top: 6px;
          }
        }

          &__contents {
          margin-top: 26px;
          &--listItems {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              &:not(:first-child) {
                  padding-top: 16px;
              }
              &:not(:last-child) {
                  border-bottom: 1px solid #eaeaea;
                  padding-bottom: 15px;
              }
              &__link {
                  @include fz(14,28,700);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding-top: 1px;
                  &:hover {
                      @include textclip($mainGrada);
                  }
              }
          }
          .txt-years {
              @include fz(18,32,700);
              color: #999;
              font-family: $fontAlegreyaSansSC;
              margin-left: 3px;
              margin-right: 28px;
          }
      }
          @include media(lg) {
            .new-pop {
                position: relative;
                margin: 0 36px 0 18px;
                &::before {
                    bottom: 0;
                    height: 8px;
                    left: -19px;
                    margin: auto;
                    position: absolute;
                    top: 0;
                }
                &::after {
                    left: auto;
                    right: -19px;
                }
                span {
                    @include fz(16,16,700);
                }
            }
          }
          .recruit-more {
              margin-left: 6px;
          }
    }
    .l-side-news {
        &__list {
            background: #fff;
            border: 1px solid #eaeaea;
        }
        &__head {
            display: none;
        }
        &__item {
            &:not(:last-child) {
                border-bottom: 1px solid #eaeaea;
            }
        }
        &__link {
            @include fz(20,20,500);
            align-items: center;
            display: flex;
            font-family: $fontAlegreyaSansSC;
            height: 61px;
            justify-content: space-between;
            padding: 0 29px 2px;
        }
    }

    @include media(sm) {
        .l-page-header {
            background-image: url(../images/recruittips/mv_sp.jpg);
            height: calc(482px / 2 + 45px);
            top: 0;
            &__title {
                @include fzs(80,80,800);
                margin-top: 20px;
                &::after {
                    height: calc(23px * 0.7);
                    width: calc(23px * 0.7);
                  }
            }
            &__subTitle {
                @include fzs(30, 30, 600);
                font-family: $fontSpFamily;
                top: 7px;
            }
        }
        .l-content {
          margin-top: 30px;
          padding-bottom: 0;
          margin-bottom: 50px;
          & > .l-container {
              flex-direction: column-reverse;
              &:before {
                  height: 300px;
                  right: -150px;
                  top: -150px;
                  width: 300px;
              }
          }
          .l-main {
              position: static;
              left: 0;
          }
        }

        &-news {
            padding-bottom: 0;
            &__inner {
                padding: 20px 0px 20px;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 0;
                position: relative;
            }
            &__header {
                margin-bottom: 20px;
            }
            &__contents {
                padding: 0;
                &--listItems {
                    align-items: flex-start;
                    flex-direction: column;
                    position: relative;
                    &__link {
                        white-space: normal;
                        overflow: auto;
                        text-overflow: clip;
                    }
                }
            }
            .new-pop {
                position: absolute;
                margin: auto;
                left: 90px;
                top: 8px;
            }
            .recruit-more {
                bottom: 20px;
                position: absolute;
                margin: auto;
                right: 20px;
            }
        }

        .l-side {
            position: relative;
            &-news {
                position: relative;
                z-index: 3;
                &__head {
                    @include fzs(28,48,700);
                    align-items: center;
                    background: #fff;
                    border: 1px solid #eaeaea;
                    display: flex;
                    height: 50px;
                    justify-content: space-between;
                    padding: 5px 25px 2px;
                    &::after {
                        @include icon-ini;
                        background-image: url(../images/ico-arrow.svg);
                        bottom: 0;
                        height: 11px;
                        margin: auto;
                        position: absolute;
                        right: 25px;
                        top: 0;
                        transform: rotate(0);
                        width: 16px;
                    }
                    .ico-grad-arrow {
                        display: none !important;
                    }
                }
                &__list {
                    display: none;
                    &.is-open {
                        display: block;
                    }
                }
                &__item {
                }
                &__link {
                    height: 50px;
                    position: relative;
                    &::after {
                        @include icon-ini;
                        background-image: url(../images/ico-arrow.svg);
                        bottom: 0;
                        height: 11px;
                        margin: auto;
                        position: absolute;
                        right: 25px;
                        top: 0;
                        transform: rotate(-90deg);
                        width: 16px;
                    }
                    .ico-grad-arrow {
                        display: none;
                    }
                }
            }
            &.is-open {
                margin-bottom: 61px;
                .l-side-news {
                    height: 61px;
                    position: absolute;
                    width: 100%;
                    &__head {
                        display: none;
                    }
                }
            }
        }
    }
}
