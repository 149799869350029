@charset "utf-8";

.regular {
    .l-page-header{
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        position:relative;
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2);
        }
        &__wrap {
            top: -16px;
            @include media(sm) {
                top: 0;
            }
        }
        &__title {
            @media screen and (max-width: 320px) {
                font-size: 20px;
            }
        }
        &__subTitle {
            position: relative;
            top: -3px;
            @include media(sm) {
                top: 0;
            }
        }
    }
    .l-scroll-down_view{
        top:40px;
    }
    .dot-Circle {
        &::after {
            @include icon-ini;
            background-image: url(../images/form/img-bg.svg);
            height: 317px;
            position: absolute;
            right: 42px;
            top: -167px;
            width: 389px;
            z-index: -1;
            @include media(sm) {
                display: none;
            }
        }
    }
    .P_message {
        @include fz(15,24,600);
        margin-top: 20px;
        text-align: center;
    }


    //調整用
    @include media(lg) {
      .select-wrap {
          align-items: center;
          &_item {
              &::after {
                  top: 0;
                }
          }
          .form-overview {
              .text_bg {
                  padding: 7px 20px;
              }
              &__inner {
                  .select-wrap {
                      align-items: center;
                      &_item {
                          &--block {
                              align-items: center;
                          }
                      }
                  }
                  .line {
                      .text {
                          span {
                              margin-bottom: 10px;
                          }
                      }
                      .select-wrap {
                          align-items: center;
                          &_item {
                              &--block {
                                  align-items: center;
                              }
                          }
                      }
                      &.job-hunt {
                          .text {
                          }
                          .select-wrap {
                              margin-bottom: 15px;
                          }
                      }
                      .station {
                          .form-overview__inner--contents {
                              align-items: center;
                              flex-wrap: wrap;
                          }
                      }
                  }
              }
          }
      }
    }
    .form-overview {
        margin-bottom: 0px;
        &__inner {
            .line {
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
        p {
            //margin-top: 10px;
            padding: 10px 20px;
            padding-left: 0;
        }
    }
    .select-wrap {
         .u-my--xxs {
            margin-top: -4px;
         }
    }
    .address {
        margin-top: 2px;
    }
    .job-hunt {
        .select-wrap_item {
            margin-bottom: 10px !important;
        }
    }
    .form-overview__inner {
         .line {
           &:nth-of-type(5) {
               padding: 35px 0 32px !important;
           }
            &:nth-of-type(6) {
                padding-bottom: 30px !important;
            }
         }
    }
    .personal-info {
        border: none !important;
    }
    .work-location {
        .select-wrap_item {
            margin-right: 20px;
        }
    }
    .apply-company {
        .select-wrap {
            .time-applicants {
                margin-top: -5px;
            }
        }
    }
    .check-wrap {
        input[type="checkbox"] {
            margin-right: 10px !important;
        }
    }
    .file_btn {
        height: 29px;
        margin-top: 10px;
        padding-top: 7px;
    }

    .full-area {
        &__reason {
            padding-top: 2px !important;
            width: 100%;
        }
    }
    .birth-select {
        padding-top: 4px;
    }
    .nensyu-kibou,
    .nensyu-saitei {
        margin-top: -3px;
    }
}

@include media(sm) {
    .l-content {
        padding-bottom: 0px;
    }
    .regular {
        .form-overview {
            margin-bottom: 0px;
            &__inner {
                .line {
                    &:not(:first-of-type) {
                        padding: 20px 0;
                    }
                    &.job-hunt {
                        .cptn {
                            margin-bottom: 10px;
                        }
                    }
                    .hope-years {
                        .text_bg {
                            padding-top: 10px;
                        }
                    }
                    span {
                        margin-bottom: 5px;
                    }
                }
                .birth {
                    .select-wrap {
                        align-items: center;
                        &_item--block {
                            align-items: normal;
                            select {
                                font-size: 10px !important;
                            }
                        }
                    }
                }
                .salary {
                    .text {
                        align-items: center;
                        .cptn {
                            margin-top: 0;
                        }
                    }
                }
                .apply-company {
                    .text {
                        .text_bg {
                            padding: 0px 10px 0;
                        }
                    }
                    .select-wrap {
                        &_item--block {
                            margin-bottom: 10px;
                        }
                        .time-applicants {
                            margin-bottom: 10px;
                        }
                    }
                }
                .work-location {
                    .select-wrap_item {
                        margin-bottom: 5px;
                        margin-top: 5px;
                    }
                }
            }
            .text_bg {
                //padding: 10px 10px 0;
            }
        }
    }
    .select-wrap {
         .u-my--xxs {
            margin-top: 0px !important;
         }
    }
    .address {
        margin-top: 0px !important;
    }
    .form-overview {
        p {
            margin-top: 0px;
        }
    }
    .form-overview__inner {
         .line {
           &:nth-of-type(5) {
               padding: 30px 0 32px !important;
           }
         }
    }
    .line.job-hunt {
        .cptn {
            &:before {
                display: none !important;
            }
        }
    }
    .form-overview {
        .text_bg {
            padding-top: 6px;
        }
    }
    .full-area {
        &__reason {
            padding-top: 5px !important;
        }
    }
}
