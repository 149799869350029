@charset "utf-8";

.recruittips {
    .l-content {
        margin-bottom: 60px;
        @include media(sm) {
            padding-bottom: 0;
        }
    }
    .l-page-header {
        top: 20px;
        background-image: url(../images/recruittips/mv.jpg);
        background-image: image-set(url(../images/recruittips/mv.jpg)1x,url(../images/recruittips/mv@2x.jpg)2x);
    }
    .l-page-header__title {
        @include fz(57.22,57.22,800);
        @include textclip($mainGrada);
        font-family: $fontAlegreyaSansSC;
        margin-top: -37px;
        position: relative;
        &::after {
            @include icon-ini;
            background-image: url(../images/recruittips/ico-recruit-tips.png);
            background-image: image-set(url(../images/recruittips/ico-recruit-tips.png)1x,url(../images/recruittips/ico-recruit-tips@2x.png)2x);
            position: absolute;
            height: 23px;
            right: -21px;
            top: -5px;
            width: 23px;
        }
    }
    .l-page-header__subTitle {
        @include fz(15,15,600);
        background: none;
        font-family: $fontFamily;
        position: relative;
        top: 14px;
        -webkit-text-fill-color: #333;
    }
    .recruit-more {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        &:hover {
            opacity: 1;
            .recruit-more__txt {
                @include textclip($mainGrada);
            }
        }
        &__txt {
            @include fz(17,17,700);
            font-family: $fontAlegreyaSansSC;
            margin-right: 10px;
            letter-spacing: 0.02em;
        }
    }
    .search-tags {
        margin-top: 76px;
        position: relative;
        .l-container {
            position: relative;
        }
        &__title {
            @include fz(20,20,700);
            font-family: $fontAlegreyaSansSC;
            margin-left: 3px;
            &::before {
                @include icon-ini;
                background-image: url(../images/ico-search-black.svg);
                height: 14px;
                left: -8px;
                position: relative;
                width: 14px;
            }
        }
        &__list {
            align-items: center;
            border-bottom: 1px solid #eaeaea;
            display: flex;
            flex-wrap: wrap;
            padding: 21px 30px 20px 14px;
            justify-content: flex-start;
            overflow: hidden;
            &::before {
                @include icon-ini;
                background-color: #eaeaea;
                height: 1px;
                display: inline-block;
                right: 25px;
                position: absolute;
                top: 10px;
                width: calc(100% - 11em);
            }
            &.is-hide {
                height: 84px;
            }
            &.is-show {
                //bottom: -2em;
            }
        }
        &__item {
            margin-bottom: 10px;
            margin-right: 10px;
        }
        &__link {
            @include fz(14,32,600);
            border-radius: 16px;
            background: $grayColor;
            display: inline-block;
            height: 32px;
            min-width: 90px;
            padding: 0 30px 0 30px;
            text-align: center;
              @media all and (-ms-high-contrast: none) {
                padding-top: 3px;
              }
            &:hover {
                background: $siteColor;
                color: #fff;
                opacity: 1;
            }
        }
        &__morebtn {
            background: $siteColor;
            bottom: -24px;
            border-radius: 50%;
            display: inline-block;
            height: 50px;
            position: absolute;
            right: 23px;
            width: 50px;
            &:hover {
                opacity: .8;
            }
            span {
                background-color: #fff;
                bottom: 0;
                display: inline-block;
                height: 1px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                transition: transform 200ms;
                width: 15px;
                &::before {
                    @include icon-ini;
                    @extend span;
                    height: 15px;
                    transition: transform 200ms;
                    width: 1px;
                }
            }
            &.is-show {
                span {
                    transform: rotate(180deg);
                    transition: transform 200ms;
                    &::before {
                        transform: rotate(-270deg);
                        transition: transform 200ms;
                    }
                }
            }
        }
    }
    &-tips {
        margin-top: 58px;
        &__header {
            margin-bottom: 80px;
            position: relative;
        }
        &__title {
            @include fz(28,28,600);
            position: relative;
            text-align: center;

            &::after {
                @include icon-ini;
                background: $mainGrada;
                height: 1px;
                margin: auto;
                left: 0;
                position: absolute;
                right: 2px;
                top: 56px;
                width: 30px;
            }
        }
        &__contents {
            flex-wrap: wrap;
            &--item {
                margin-right: 30px;
                padding-bottom: 50px;
                position: relative;
                &:hover {
                    .recruittips-tips__case--comname,
                    .recruittips-tips__case--name {
                        @include textclip($mainGrada);
                    }
                }
                &:nth-child(3n) {
                    margin-right: 0px;
                }
            }
            &--imgWrap {
                position: relative;
            }
        }

        &__interview {
            margin-top: 58px;
            &--category {
                @include fz(13,32,600)
                background: $siteColor;
                color: #fff;
                display: inline-block;
                height: 32px;
                min-width: 80px;
                padding: 1px 15px 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }
        }
        &__company {
            margin-top: 57px;
        }
        &__case {
            margin-top: 57px;
            &--comname {
                display: block;
                margin-bottom: -3px;
                margin-left: -2px;
                margin-top: -1px;
            }
            &--name {
                display: block;
                margin-left: -1px;
            }
            .recruit-contents__title {
                margin-bottom: 15px;
            }
        }
        .recruit-more {
            position: absolute;
            right: 0;
            bottom: -1px;
        }
        .new-pop {
            top: -28px;
            left: 6px;
        }
    }
    &-news {
      &__inner {
        display: flex;
        margin-bottom: 61px;
        //padding-bottom: 18px;
        box-shadow: -21.213px 21.213px 57px 0px rgba(0, 0, 0, 0.04);
      }
      &__header {
        padding: 68px 73px 24px 60px;
      }
      &__title {
        font-size: 37px;
        margin-bottom: 8px;
        position: relative;
        &::after {
            @include icon-ini;
            background: $mainGrada;
            height: 1px;
            left: 6px;
            position: absolute;
            top: 97px;
            width: 30px;
        }
      }
      &__subtitle {
        padding-left: 2px;
        margin-bottom: 76px;
      }
      &__contents {
          padding: 69px 0 55px 0;
          &--listItems {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
          &:not(:first-child) {
              padding-top: 16px;
          }
          &:not(:last-child) {
              border-bottom: 1px solid #eaeaea;
              padding-bottom: 15px;
          }
          &__link {
            @include fz(14,28,700);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 1px;
            &:hover {
                @include textclip($mainGrada);
            }
          }
        }
        .txt-years {
          color: #999;
          @include fz(18,32,700);
          font-family: $fontAlegreyaSansSC;
          margin-right: 28px;
        }
      }
      .new-pop {
        position: relative;
        margin: 0 22px 0 4px;
        &::before {
          height: 8px;
          top: 0;
        }
        span {
          @include fz(16,16,700);
        }
      }
      .recruit-more {
          margin-left: 6px;
      }
    }
    @include media(sm) {
        .l-page-header {
            background-image: url(../images/recruittips/mv_sp.jpg);
            height: calc(482px / 2 + 45px);
            top: 0;
            &__title {
                @include fzs(80,80,800);
                margin-top: 20px;
                &::after {
                    height: calc(23px * 0.7);
                    width: calc(23px * 0.7);
                }
            }
            &__subTitle {
                @include fzs(30, 30, 600);
                font-family: $fontSpFamily;
                top: 7px;
            }
        }

        .search-tags {
            margin-top: 50px;

            &__list {
                padding: 21px 0 20px 14px;
                &.is-hide {
                    height: 84px;
                }
            }
            &__link {
                @include fz(13,32,600);
                padding: 0 20px;
            }
            &__morebtn {
                right: 20px;
            }
        }

        &-tips {
            &__header {
                margin-bottom: 30px;
            }
            &__title {
                @include fzs(42,42,600);
                margin-bottom: 8px;


                &::after {
                    right: 9px;
                    top: 57px;
                }
            }
            &__contents {
                &--item {
                    flex-wrap: wrap;
                    margin-right: 0;
                    padding-bottom: 35px;
                }
                &--imgWrap {
                    margin-right: 0;
                    height: 280px;
                    width: 100%;
                }
                &--txt {
                    padding-left: 0;
                    padding-top: 10px;
                }
                &--title {
                    @include fzs(32,50,600)
                    margin-bottom: 5px;
                }
                &--excerpt {
                    @include fzs(28,48,400);
                }
            }
            .recruit-more {
                justify-content: center;
                position: relative;
                text-align: center;

            }
            &__case {
                .recruit-contents__title {
                    margin-bottom: 5px;
                }
                &--comname {
                    margin-bottom: 0;
                }
            }
        }
        &-news {
            padding-bottom: 0;
            &__inner {
                padding: 20px 20px 80px;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 0;
                position: relative;
            }
            &__header {
                padding: 0;
                text-align: center;
            }
            &__title {
                &::after {
                    left: 0;
                    margin: auto;
                    right: 0;
                    top: 75px;
                }
            }
            &__subtitle {
                margin-bottom: 30px;
            }
            &__contents {
                padding: 0;
                width:100%;
                &--listItems {
                    align-items: flex-start;
                    flex-direction: column;
                    position: relative;
                    &__link {
                        white-space: normal;
                        overflow: auto;
                        text-overflow: clip;
                    }
                }
            }
            .new-pop {
                position: absolute;
                margin: auto;
                left: 90px;
                top: 8px;
            }
            .recruit-more {
                bottom: 20px;
                position: absolute;
                margin: auto;
                right: 20px;
            }
        }
    }
}
