@charset "utf-8";

.l-footer {
    .search-area {
        background-image: url(../images/footer-bg.jpg);
        background-size: cover;
        height: 466px;
        overflow: hidden;
        position: relative;
        width: 100%;
        &::before {
            @include icon-ini;
            background: linear-gradient(90deg, #ff8b17 20%, #ff5d51 80%);
            //ここだけグラデ濃いめ!
            height: 467px;
            opacity: .95;
            position: absolute;
            width: 100%;
        }
        @include media(sm) {
            background-position: center right 24%;
            height: 432px;
        }
        &__inner {
            align-items: flex-start;
            display: flex;
            margin: 0 auto;
            padding: 0 0 0 0px;
            position: relative;
            justify-content: space-between;
            width: 1100px;
            z-index: 0;
            @include media(sm) {
                margin-top: 35px;
                padding:  0 $spSpace;
                width: 100%;
            }
        }
        &__img {
            position: relative;
            &::before {
                @include icon-ini;
                background-image: url(../images/footer-img01.png);//image-setが効かないやつの指定
                background-image: image-set(url(../images/footer-img01.png) 1x, url(../images/footer-img01@2x.png) 2x);
                height: 474px;
                left: -39px;
                position: absolute;
                top: 101px;
                width: 408px;
            }
            &::after {
                @include icon-ini;
                background-image: url(../images/img-emotion.png);
                height: 62px;
                left: 110px;
                position: absolute;
                top: 84px;
                width: 60px;
            }
            @include media(sm) {
              display: none;
            }
        }
        &__content {
            margin-top: 100px;
            @include media(sm) {
                margin: 0 auto;
            }
            &--title {
                @include fz(32,48,700);
                color: #fff;
                margin-left: -6px;
                margin-bottom: 27px;
                position: relative;
                &::before {
                    @include icon-ini;
                    background-image: url(../images/logo_txt.png);
                    position: absolute;
                    height: 166px;
                    left: -60px;
                    top: -52px;
                    width: 282px;
                    z-index: -1;
                }
                @include media(sm) {
                    @include fzs(40,64,600);
                    color: #fff;
                    background: none;
                    height: auto;
                    margin: 0 auto 19px;
                    padding-left: 13px;
                    text-align: center;
                    width: auto;
                    &::before {
                        height: calc(305px / 2);
                        left: -17px;
                        top: -39px;
                        width: calc(521px / 2)
                    }
                }
                @include media(ss) {
                  @include fzs(30,64,600);
                }

            }
            &--form {
              display: flex;
              input[type="text"] {
                border: 0;
                font-family: inherit;
                font-weight: 600;
                height: 60px;
                padding: 10px;
                width: 213px;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 15px;
                }
              }
            }
            &--select {
                display: flex;
                margin-bottom: 26px;
                justify-content: space-between;
                @include media(sm) {
                    flex-wrap: wrap;
                    position: relative;
                }
                &_inner {
                    position: relative;
                    &.jobSelect {
                        margin-right: 1px;
                        &::after {
                            top: 0;
                            background: #fff;
                            content: "";
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            width: 42px;
                            vertical-align: middle;
                            pointer-events: none;
                            @include media(sm) {
                              width: 32px;
                              height: 98%;
                            }
                        }
                        .jobSelect-wrap {

                        }
                    }
                    &::before {
                      @include icon-ini;
                      background: url(../images/ico-arrow.svg);
                      height: 9px;
                      position: absolute;
                      right: 20px;
                      top: 24px;
                      width: 14px;
                      pointer-events: none;
                      z-index: 10;
                    }

                    @include media(sm) {
                        width: 100%;
                        &::before {
                          height: 8px;
                          right: 17px;
                          top: 18px;
                          width: 14px;
                        }
                        &::after {
                            top: 14px;
                        }
                    }
                }
                select {
                    @include fz(15,15,600)
                    appearance: none;
                    border: 1px solid #fff;
                    border-radius: 0;
                    background: #fff;
                    cursor: pointer;
                    font-family: inherit;
                    height: 60px;
                    margin-right: 1px;
                    outline: none;
                    padding-bottom: 1px;
                    padding-left: 21px;
                    padding-right: 40px;
                    position: relative;
                    width: 214px;
                    @media all and (-ms-high-contrast: none) {
                      padding-top: 6px;
                    }
                    &::-ms-expand {
                        display: none;
                    }
                    @include media(sm) {
                        @include fzs(28,28,600);
                        height: 45px;
                        margin: 0 0 1px;
                        width: 100%;
                        padding: 1px 20px 0 19px;
                    }
                }
                option {}
            }
            &--search {
                background: $siteColor;
                height: 60px;
                margin-left: 16px;
                width: 60px;
                @include media(sm) {
                    border-radius: 50px;
                    bottom: -120px;
                    margin: auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                    height: calc(80px / 2);
                    width: calc(360px / 2);
                }
                span {
                    @include fz(12,12,600);
                    color: #fff;
                    display: block;
                    position: relative;
                    &::before {
                        @include icon-ini;
                        background-image: url(../images/ico-search.svg);
                        display: block;
                        height: 18px;
                        margin: 0 auto 9px;
                        left: 0;
                        position: relative;
                        right: 0;
                        top: 3px;
                        width: 18px;
                    }
                    @include media(sm) {
                        @include fzs(28,40,600);
                    }
                }
            }
            &--detail {
                @include fz(15,15,600);
                color: #fff;
                text-decoration: underline;
                &:hover {
                    opacity: .8;
                    transition: 200ms;
                }
                @include media(sm) {
                    @include fzs(28,28,600);
                    display: block;
                    text-align: center;
                }
            }
        }
        &__check {
            position: relative;
            margin-bottom: 16px;
            top: 1px;
            @include media(sm) {
                margin: 0 auto 19px;
                top: 0;
                display: flex;
                justify-content: center;
            }
            label {
                @include fz(15,15,600);
                color: #fff;
                cursor: pointer;
                margin-right: 19px;
                position: relative;
                @include media(sm) {
                    @include fzs(26,26,600);
                    margin-right: 0;
                    margin: 0 11px 0 8px;
                }
                .checkbox-parts {
                    position: relative;
                    &::before {
                      @media all and (-ms-high-contrast: none) {
                        top: -2px;
                      }
                    }
                    @include media(sm) {
                        padding-left: 18px;
                    }
                    &:before {
                        @include icon-ini;
                        background: #fff;
                        border-radius: 2px;
                        height: 15px;
                        position: relative;
                        margin-right: 12px;
                        left: 0;
                        top: -1px;
                        width: 15px;
                        @include media(sm) {
                            border-radius: 2px;
                            height: calc(22px / 2);
                            width: calc(22px / 2);
                            margin-right: 0;
                            position: absolute;
                            top: 2px;
                        }
                    }
                }
                input[type="checkbox"] {
                    display: none;
                }
                input[type="checkbox"]:checked + .checkbox-parts {
                    position: relative;
                    &::after {
                        @include icon-ini;
                        background: transparent;
                        border-bottom: 2px solid $siteColor;
                        border-right: 2px solid $siteColor;
                        height: 9px;
                        left: 5px;
                        position: absolute;
                        top: 2px;
                        transform: rotate(40deg);
                        width: 6px;
                        @media all and (-ms-high-contrast: none) {
                          top: 3px;
                        }
                        @include media(sm) {
                            height: 6px;
                            left: 3px;
                            top: 5px;
                            width: 4px;
                        }
                    }
                }
          }
        }
    }

    &-main {
        &__inner {
            padding-bottom: 115px;
            padding-top: 97px;
            margin: 0 auto;
            width: 1100px;
        }
        background: #212a37;
        .company {
            align-items: center;
            display: flex;
            padding-bottom: 75px;
            position: relative;
            justify-content: space-between;
            &::before {
                @include icon-ini;
                background-color: #384352;
                bottom: 0;
                height: 1px;
                position: absolute;
                width: 100%;
            }
            &__title {
                @include fz(24,24,600);
                color: #fff;
                width: calc(100% - 749px);
                @include media(sm) {
                    @include media(sm) {
                        @include fzs(34,34,600);
                    }
                }
            }
            &__txt {
                @include fz(15,30,600);
                color: #fff;
                @include media(sm) {
                    @include fzs(28,42,600);
                }
            }
            &__more {
                @include fz(18,50,700);
                margin-top: -6px;
                @include media(sm) {
                    @include fzs(34,80,700);
                    margin-top: -7px;
                    padding: 0;
                    width: calc(360px / 2);
                }
            }
        }

        //clientページ適用
        .client {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 4px;
            padding-bottom: 81px;
            position: relative;
            @include media(sm) {
                flex-wrap: wrap;
                padding-bottom: 52px;
                margin-top: 0;
            }
            &::before {
                @include icon-ini;
                background-color: #384352;
                bottom: 0;
                height: 1px;
                position: absolute;
                width: 100%;
            }
            &__title {
                @include fz(24,24,600);
                color: #fff;
                position: relative;
                left: -2px;
                width: calc(100% - 785px);
                @include media(sm) {
                    @include fzs(34,34,600);
                    margin: 0 auto 14px;
                    text-align: center;
                    width: auto;
                }
            }
            &__txt {
                @include fz(15,30,600);
                color: #fff;
                position: relative;
                top: 2px;
                @include media(sm) {
                    @include fzs(28,42,600);
                    margin-bottom: 30px;
                }
            }
            &__more {
                @include fz(15,50,600);
                border-radius: 50px;
                background: $mainGrada;
                color: #fff;
                font-family: $fontFamily;
                min-width: 200px;
                height: 50px;
                padding: 0 0 0 47px;
                position: relative;
                top: -1px;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 4px;
                }

                @include media(sm) {
                    @include fzs(28,80,600);
                    font-family: $fontSpFamily;
                    height: calc(80px / 2);
                    margin: 0 auto;
                    padding-left: 52px;
                }
                &:before{
                    @include icon-ini;
                    background-image: url(../images/client/ico-mail-client.svg);
                    position: relative;
                    height:12px;
                    right: 8px;
                    top: -1px;
                    width:14px;
                }
            }
        }

        .content {
            align-items: flex-start;
            display: flex;
            margin: 80px 0 0;
            justify-content: space-between;
            &__logoarea {
                margin-top: 2px;
                &--link {
                  @include media(sm) {
                      img {
                          height: calc(40px / 2);
                          width: calc(335px / 2);
                      }
                  }
                }
                &--lead {
                    @include fz(12,24,600);
                    @include letterSpacing(80);
                    color: #fff;
                    margin-bottom: 30px;
                    @include media(sm) {
                        @include fzs(24,40,600);
                        margin-bottom: 26px;
                        letter-spacing: 0.06em;
                        margin-left: -2px;
                    }
                }
                &--sns {
                    @include fz(14,14,900);
                    @include letterSpacing(90);
                    color: #5c78b7;
                    font-family: $fontAlegreyaSansSC;
                    text-decoration: underline;
                    &:hover {
                        opacity: .8;
                        transition: 200ms;
                    }
                }
            }
        }
    }
    &__logo {
        margin-bottom: 18px;
        @include media(sm) {
            padding-left: 1px;
        }
    }
    &__nav {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        width: 723px;
        &--list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 64px;
        }
        &--item {}
        &--link {
            @include fz(14,14,600);
            color: #fff;
            opacity: .5;
            position: relative;
            &::after {
                @include icon-ini;
                background: $mainGrada;
                left: 0;
                margin: auto;
                height: 2px;
                right: 0;
                position: absolute;
                top: -85px;
                transform: scale(0, 1);
                transform-origin: right top;
                transition: transform 200ms;
                width: 100%;
                @media all and (-ms-high-contrast: none) {
                  top: -83px;
                }
            }
            &:hover,
            &.is-active {
                opacity: 1;
                transition: 200ms;
                &::after {
                    @include icon-ini;
                    transform: scale(1, 1);
                    transform-origin: left top;
                }
            }

        }

        &--other {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            &_item {
                margin-bottom: 7px;
                margin-left: 18px;
                text-align: right;
                @include media(sm) {
                    margin-bottom: 3px;
                    margin-left: 0;
                    margin-right: 19px;
                    text-align: left;
                }
            }
            &_link {
                @include fz(12,12,400);
                color: #565d67;
                @include media(sm) {
                    @include fzs(24,24,300);
                }
            }
        }
    }

    &__bar {
        align-items: center;
        display: flex;
        margin-top: 80px;
        justify-content: space-between;
        @include media(sm) {
            margin-top: 42px;
        }
        &--tel {
            position: relative;
            @include media(sm) {
                margin-bottom: 26px;
            }
            &::before {
                @include icon-ini;
                background-image: url(../images/ico-tel-mark.svg);
                height: 17px;
                width: 26px;
                margin-left: -3px;
                margin-right: 4px;
                @include media(sm) {
                    background-image: url(../images/ico-tel-mark.svg);
                    height: calc(27px / 2);
                    margin-left: 1px;
                    margin-top: 3px;
                    width: calc(42px / 2);
                }
            }
            &_number {
                @include fz(18,18,500);
                color: #fff;
                font-family: $fontRoboto;
                vertical-align: middle;
                margin-top: 3px;
                display: inline-block;
                pointer-events: none;
                @include media(sm) {
                    @include fzs(32,32,500);
                    margin-top: 2px;
                    pointer-events: auto;
                }
            }
            &_txt {
                @include fz(12,32,600);
                color: #fff;
                margin-bottom: 5px;
                margin-left: 7px;
                vertical-align: sub;
                letter-spacing: 0.08em;
                @include media(sm) {
                    @include fzs(24,24,600);
                    margin-left: 3px;
                    margin-bottom: 0;
                    margin-top: -1px;
                    vertical-align: middle;
                    letter-spacing: 0.05em;
                }
            }
        }
        &--copyright {
            @include fz(14,14,600);
            color: #565d67;
            font-family: $fontAlegreyaSansSC;
        }
        &--sns {
            @include media(sm) {
                a {
                  display: inline-block;
                  &:last-child {
                      margin-left: -4px;
                  }
                    img {
                        height: calc(72px / 2);
                        width: calc(72px / 2);
                    }
                }
            }
        }
    }
    @include media(sm) {
      &-main {
        &__inner {
          width: 100%;
          padding: 49px 20px 50px;
        }
        .company {
          display: block;
          text-align: center;
          padding-bottom: 52px;
          &::before {
            left: 0;
          }
          &__title {
            width: 100%;
            margin-bottom: 14px;
            padding-left: 0px;
            letter-spacing: -0.02em;
            margin-top: 1px;
          }
          &__txt {
            text-align: left;
            margin-bottom: 32px;
          }
        }
        .content {
          display: block;
          margin-top: 62px;
          &__logoarea {
            margin-top: 0;
            margin-bottom: 24px;
          }
        }
      }
      .search-area__content {
        &--select {
          margin-bottom: 0;
          &_inner {
            &.jobSelect {
              margin-right: 0;
              .jobSelect-wrap::after {
                background: none;
              }
            }
          }
        }
        &--form {
          display: block;
          margin-bottom: 20px;
          input[type="text"] {
            @include fzs(26,26,600);
            border-radius: 0;
            height: 45px;
            width: 100%;
            padding: 6px 10px 0 20px;
            @include media(ss) {
            }
          }
          .free {
            position: relative;
          }
        }
        &--search {
          bottom: -95px;
          span {
            @include fzs(28,80,600);
            display: flex;
            align-items: center;
            padding: 0 10px 0 0;
            justify-content: center;
            &::before {
              height: calc(27px / 2);
              left: -4px;
              margin: 0;
              top: 0;
              width: calc(27px / 2);
            }
          }
        }
      }
      &__nav {
        width: 100%;
        &--other {
          justify-content: flex-start;
        }
      }
      &__bar {
        &--sns {
          a[target="_blank"]::after {
            background: none;
          }
        }
      }
      .client-btn{
        @include media(sm) {
          width:auto;
          margin:0 auto;
          line-height: 2.9;
        }
      }
    }
}

@include media(ss) {
    .l-footer {
        .search-area {
            &__check {
                align-items: center;
                justify-content: flex-start;
                label {
                    @include fz(12,12,600);
                    margin: 0 15px 0 0;
                    .checkbox-parts {
                        padding-left: 14px;
                        &::before {
                            top: 1px;
                        }
                    }
                    input[type="checkbox"] {
                        &:checked + .checkbox-parts {
                            &::after {
                                left: 4px;
                                top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}
