@charset "utf-8";

html {
    // height: 100%;
    position: relative;
    &.is-nav--active {
        overflow: hidden;
    }

}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: $bodyW;
    // ★★★js用
    &::before {
        content: 'lg';
        display: none;
        @include media(sm) {
            content: 'sm';
        }
    }
    @include media(sm) {
        min-width: 320px;
    }

    &:after {
        @include icon-ini;
        background: #fff;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        height: 100%;
        width: 100%;
        z-index: 9999;
    }
    .is-gotop & {
        &:after {
            animation: fadeIn 650ms  $easeOutQuart forwards;
        }
    }

}

.l-page {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 0;
    overflow: hidden;
    // padding-top: $headerH;
    position: relative;
    // IE11でmin-height:100%;が効かないため100vh
    // 全部100vhにするとiOSのSafariで意図した高さにならない
    @media all and (-ms-high-contrast: none) {
        min-height: auto;
    }
    @include media(sm) {
        // padding-top: $spHeaderH;
    }
}

.l-content {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 100%; //Safariでレイアウトが崩れたことがあったので
    @include media(sm) {
        padding-bottom: 67px;
    }
}

.l-main {
  @include media(lg) {
  //overflow-x: hidden;
  }
}

.l-container {
    margin: 0 auto;
    max-width: $mainW;
    position: relative;
    // content直下のcontainerはmainとsideを横並びにするためflexbox
    .l-content > & {
        display: flex;
        flex-wrap: wrap;
        .l-main {
            width: $mainW - $sideW - 47;
        }
        .l-side {
            margin-left: 47px;
            width: $sideW;
        }
    }
    @include media(sm) {
        padding: 0 $spSpace;
        .l-content > & {
            // flex-direction: column; //IE11で詳細ページのmainの高さがバグった
            .l-main {
                width: 100%;
            }
            .l-side {
                margin: 50px 0 0;
                width: 100%;
            }
        }
    }
}


.l-block {
    margin-top: 40px;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &--sm {
        margin-top: 20px;
        &:first-child {
            margin-top: 0;
        }
    }
    @include media(sm) {
        margin-top: 30px;
        &--sm {
            margin-top: 20px;
        }
    }
}

.recruit {
  &-contents {
    display: flex;
    justify-content: flex-start;
    &__item {
      flex-basis: 346px;
      padding-bottom: 25px;
      @include media(sm) {
        padding-bottom: 17px;
      }
      &::after {
        position: absolute;
      }
      &:hover {
        opacity: 1;
        .recruit-contents__imgWrap,
        .recruit-contents__excerpt {
            opacity: .8;
        }
        .recruit-contents__title,
        .recruit-contents__readMore--txt {
            @include textclip($mainGrada);
        }
        &::after {
          height: 1px;
          width: 100%;
        }
      }
    }
    &__imgWrap {
      height: 280px;
      margin-top: 9px;
      margin-bottom: 21px;
      width: 100%;
      img {
          font-family: 'object-fit: cover';
          height: 100%;
          object-fit: cover;
          width: 100%;
      }
    }
    &__title {
      @include fz(18, 32, 700);
      margin-bottom: 13px;
      min-height: 64px;
      @include media(sm) {
          @include fzs(28,42,600);
          margin-bottom: 0;
          min-height: inherit;
      }
    }
    &__excerpt {
      @include fz(14, 28, 500);
      min-height: 56px;
    }
    &__txt {
      @include fz(18, 32, 400);
      margin-bottom: 16px;
      padding-right: 24px;
    }
    &__readMore {
      @include fz(18,32,600);
      font-family: $fontAlegreyaSansSC;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 16px;
      @include media(sm) {
        @include fzs(28,32,700);
      }
      &--txt {
        margin-right: 11px;
        @include media(sm) {
            margin-right: 4px;
        }
      }
    }
    @include media(sm) {

      display: block;
      &__item {
        display: flex;
        padding-bottom: 20px;
      }
      &__imgWrap {
        margin-bottom: 0;
        margin-top: 0;
        margin-right: 13px;
        height: calc(242px / 2);
      }
      &__txt {
        padding-left: 7px;
        padding-top: 21px;
        padding-right: 0;
        margin-bottom: 0;
        position: relative;
      }
      &__readMore {
        padding-bottom: 8px;
      }
    }
  }
}

.l-lg {
    @include media(sm) {
        display: none !important;
    }
}

.l-sm {
    @include media(lg) {
        display: none !important;
    }
}

a {
    &:hover {
        opacity: .8;
    }
}
/* 下層ページ共通 */
//別窓アイコン
a[target="_blank"] {
    &:not(.l-nav__link) {
        &:not(.open-menu__link) {
            position: relative;
            &::after {
                @include icon-ini;
                background-image: url(../images/icon-tab.svg);
                height: 10px;
                position: relative;
                left: 5px;
                top: -3px;
                width: 10px;
            }
        }
    }
}
//newアイコン
.new-pop {
    position: absolute;
    &::before {
      @include icon-ini;
      background-image: url(../images/new-pop.png);
      width: 14px;
      left: -6px;
      top: -2px;
      position: relative;
      height: 11px;
    }
    &::after {
      @extend ::before;
      left: 7px;
    }
    span {
        @include fz(22,22,700);
        font-family: $fontAlegreyaSansSC;
    }
    @include media(sm) {
      position: absolute;
      top: -6px;
      left: 12px;
      line-height: 1;
      &::before {
        height: calc(22px / 2);
        width: calc(28px / 2);
        top: 0;
        left: -5px;
      }
      &::after {
        left: 5px;
      }
      span {
        @include fzs(38,38,700);
      }
    }
}
//ボタン
.btn-more {
    @include fz(18,50,600);
    background-color: $siteColor;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: $fontAlegreyaSansSC;
    height: 50px;
    padding: 0 41px 0;
    vertical-align: middle;
    @include media(sm) {
        @include fzs(34,78,700);
        height: calc(80px / 2);
        padding: 0 0 0 2px;
    }
}
//ボタンアイコンあり
.btn-icon {
    @extend .btn-more;
    padding: 0 41px 0 21px;
    &::before {
        @include icon-ini;
        background-image: url(../images/icon-pc.png);
        height: 15px;
        margin-right: 20px;
        margin-top: -1px;
        position: relative;
        width: 17px;

    }
}
//左上に上げるやつ
@include media(lg) {
.over {
    transition: 200ms;
    &:hover {
        transform: translate(-15px,-15px);
        transition: 200ms;
    }
  }
}

//横にシュッ
.bar-slide {
  @include media(lg) {
    position: relative;
    &::after {
        @include icon-ini;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform 200ms;
    }
    &:hover {
        transition: 200ms;
        &::after {
            background: $mainGrada;
            @include icon-ini;
            transform: scale(1, 1);
            transform-origin: left top;
            position: absolute;
        }
    }
  }
}
.sub-title__en {
    @include fz(44,44,800);
    font-family: $fontAlegreyaSansSC;
    @include media(sm) {
        @include fzs(70,70,800);
    }
}
.sub-title__jp {
    @include fz(15,15,800);
    @include media(sm) {
        @include fzs(28,28,600);
    }
}

//下層ページ内リンク
.page-links {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.1);
    height: auto;
    padding: 20px 27px 18px 28px;
    position: fixed;
    bottom: 60px;
    right: 40px;
    width: 133px;
    z-index: 50;
    &__list {}
    &__item {
        &:not(:last-child) {
            .page-links__link {
                border-bottom: 1px #999999 dotted;
                margin-bottom: 19px;
                padding-bottom: 18px;
            }
        }
        &:last-child {
            .page-links__link {
                &::before {
                    height: 100%;
                }
            }
        }
    }
    &__link {
        @include fz(13,22,600);
        display: inline-block;
        position: relative;
        &::before {
            @include icon-ini;
            background: $mainGrada;
            height: calc(100% - 21px);
            position: absolute;
            left: -28px;
            width: 3px;
            transform: scale(0);
            transform-origin: bottom;
            transition: transform 200ms;
        }
        &:hover, &.is-active {
            @include textclip($mainGrada);
            transition: transform 200ms;
            opacity: 1;
            @media all and (-ms-high-contrast: none) {
              color: #ff9933;
              background: none !important;
            }
            &::before {
                @include icon-ini;
                transform: scale(1);
                transform-origin: top;
                position: absolute;
            }
        }
        &__fixed {
            position: absolute !important;
            bottom: 40px;
            top: initial !important;
        }
    }
}

//SP用ページ内リンク
.sponly-box {
    display: none;
}
@include media(sm) {
    .link-list {
        margin-top: 10px;
    }
  .sponly-box {
      display: block;
      &__contents {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
      }
      &__btn {
          background-color: #fff;
          border-radius: 2.5px;
          box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.1);
          color: #333;
          margin-bottom: 20px;
          padding: 13px 0 25px 0;
          position: relative;
          text-align: center;
          flex-basis: 100%;
          &--text {
              @include fzs(26, 40, 700);
              bottom: 0;
              display: block;
              position: relative;
              &:after {
                  @include icon-ini;
                  background: url(../images/flow/arrow.png);
                  background-size: cover;
                  display: block;
                  height: 6px;
                  margin: 0px auto;
                  margin-top: 10px;
                  left: 0;
                  position: absolute;
                  top: 85%;
                  right: 0;
                  width: 10px;
              }
          }
      }
  }
}
//下層のドットの円
.dot-Circle {
    &::before {
        @include dot-human;
        height: 600px;
        position: absolute;
        right: -300px;
        top: -300px;
        width: 600px;
        z-index: -1;
        @include media(sm) {
            height: 300px;
            right: -150px;
            top: -150px;
            width: 300px;
        }
    }
    &::after {
      @include media(sm) {
        background: none;
      }
    }
    &.left {
      &::before {
        @include dot-human;
        height: 600px;
        position: absolute;
        top: -300px;
        width: 600px;
        z-index: -1;
        left: -300px;
        @include media(sm) {
          height: 300px;
          top: -150px;
          width: 300px;
          left: -150px;
        }
      }
    }
}

//ページナビゲーション
.pagenav {
    /*align-items: center;*/
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .page, li {
        @include fz(15,40,700);
        color: #999999;
        cursor: pointer;
        display: inline-block;
        font-family: $fontRoboto;
        position: relative;
        text-align: center;
        &:last-child {
            margin: 0;
        }
        a{
            margin: 0;
            padding:10px;
        }
    }
    .page{
      margin: 0 3px 0 0;
      padding:0 10px 0 10px;
    }
    .next,.last,.previous,.first{
      display:none;
    }
    .current {
        @extend .page;
        background-color: $siteColor;
        border-radius: 50px;
        color: #fff;
        height: 40px;
        width: 40px;
        margin: 0 8px 0 0;
        @include media(sm) {
          height: 38px;
          width: 38px;
          margin: 0 9px 0 0;
        }
    }
    li a {
        color: #999999;
    }
    .after {
        .delta-4 {
            .tail {
                margin-right: 0px;
            }
        }
    }
    .next {
        margin: 0px;
    }
}

//カテゴリ別指定
.cate {
    &-knowledge {}
    &-interview {
        &__jobstyle {
            @include fz(11,22,600);
            background-color: $siteColor;
            color: #fff;
            display: inline-block;
            min-width: 60px;
            padding: 0 5px;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
        }
    }
    &-case {
        &__company {
            @include fz(18,24,600);
            display: block;
            margin-bottom: 7px;
            margin-top: -2px;
        }
    }
}

// ieテキスト処理

.ie-text_color {
  @media all and (-ms-high-contrast: none) {
    color: #ff9933;
    background: none !important;
  }
}

.recruit-contents__item {
  &:hover {
    .ie-text_color--hv {
      @media all and (-ms-high-contrast: none) {
        color: #ff9933;
        background: none !important;
      }
      .recruittips-tips__case {
        &--comname {
          @media all and (-ms-high-contrast: none) {
            color: #ff9933;
            background: none !important;
          }
        }
        &--name {
          @media all and (-ms-high-contrast: none) {
            color: #ff9933;
            background: none !important;
          }
        }
      }

    }
  }
}

.recruit-more {
  &:hover {
    .ie-text_color--hv {
      @media all and (-ms-high-contrast: none) {
        color: #ff9933;
        background: none !important;
      }
    }
  }
}

.recruittips-news__contents--listItems {
    .ie-text_color--hv {
      &:hover {
      @media all and (-ms-high-contrast: none) {
        color: #ff9933;
        background: none !important;
      }
    }
  }
}

.single-recruittips__related--item {
  &:hover {
    .ie-text_color--hv {
      @media all and (-ms-high-contrast: none) {
        color: #ff9933;
        background: none !important;
      }
    }
  }
}

.l-side__item {
  &:hover {
    .ie-text_color--hv {
      @media all and (-ms-high-contrast: none) {
        color: #ff9933;
        background: none !important;
      }
    }
  }
}

.recruittips-news-news__contents--listItems__link.ie-text_color--hv {
  &:hover {
      @media all and (-ms-high-contrast: none) {
        color: #ff9933;
        background: none !important;
      }
  }
}

.sitemap-wrap {
  .link-list__link {
    .ie-text_color--hv {
      &:hover {
        @media all and (-ms-high-contrast: none) {
          color: #ff9933;
          background: none !important;
        }
      }
    }
  }
}


// 電話番号PCではNOクリッカブルに
// -------------------------------
a[href^="tel:"] {
    @include media(lg) {
        pointer-events: none;
    }
}


.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
