@charset "utf-8";
//お仕事検索共通
.job-list {
    margin-bottom: 80px;
    &:last-of-type {
        margin-bottom: 50px;
    }
    &__inner {}
    &__header {
        background: #FEF5EE;
        padding: 34px 51px 30px 38px;
        position: relative;
        &::before {
            @include icon-ini;
            background: $mainGrada;
            height: 4px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    &__category {
        @include fz(15,15,700);
        @include textclip($mainGrada);
    }
    &__title {
        @include fz(20,32,600);
        margin-bottom: 13px;
        margin-top: 14px;
    }
    &__tags {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        &--item {
            @include fz(13,13,600);
            background: #fff;
            margin-right: 1px;
            padding: 9px 10px 8px 10px;
            @media all and (-ms-high-contrast: none) {
            padding: 12px 10px 6px 10px;
          }
        }
    }
    &__content {
        background: #fff;
        padding: 31px 30px 50px 39px;
        &--tags {
            align-items: center;
            display: flex;
            margin-bottom: 23px;
            flex-wrap: wrap;
            &_item {
                @include fz(13,28,600);
                background: $grayColor;
                margin-bottom: 1px;
                margin-right: 1px;
                padding: 2px 15px 0;
                height: 28px;
                text-align: center;
                min-width: 122px;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 4px;
                }
            }
        }
        &--list {
            align-items: flex-start;
            border-bottom: 1px solid $grayColor;
            display: flex;
            justify-content: flex-start;
            padding-bottom: 11px;
            &:not(:first-of-type) {
                margin-top: 13px;
            }
            &:last-of-type {
                margin-bottom: 33px;
                padding-bottom: 13px;
            }
            &_head {
                @include fz(14,28,600);
                flex-basis: 12.6%;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 6px;
                }
            }
            &_body {
                @include fz(14,28,500);
                flex-basis: calc(100% - 12.6%);
                @media all and (-ms-high-contrast: none) {
                  padding-top: 6px;
                }
            }
        }
        &--txt {
            @include fz(12,24,600);
            color: #999999;
            padding-right: 10px;
            text-align: center;
        }
    }
    &__contactarea {
        margin: 23px auto 0;
        text-align: center;
        .btn-detail {
            @include fz(18,18,600);
            font-family: $fontFamily;
            height: 60px;
            margin-right: 16px;
            padding: 20px 0 0 8px;
            width: 280px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 25px;
            }
        }
        .btn-contact {
            @include fz(18,18,600);
            background: $mainGrada;
            font-family: $fontFamily;
            height: 60px;
            margin-right: 12px;
            padding: 20px 0 0 1px;
            width: 280px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 25px;
            }
        }
    }
    .job-new {
        @include fz(20,20,700);
        @include textclip($mainGrada);
        border-right: 2px solid $gradeBottom;
        border-left: 2px solid $gradeTop;
        display: inline-block;
        font-family: $fontAlegreyaSansSC;
        line-height: 1;
        margin-right: 14px;
        padding: 1px 0px 2px 4px;
        position: relative;
        text-align: center;
        width: 59px;
        @media all and (-ms-high-contrast: none) {
          padding: 4px 0 1px 4px;
        }
        &::before {
            @include icon-ini;
            background: $mainGrada;
            left: 0;
            height: 2px;
            position: absolute;
            top: 0;
            width: 100%;
        }
        &::after {
            @extend ::before;
            bottom: 0;
            top: auto;
        }
    }

}
.Recomlist {
    &__pop {
        background: $mainGrada;
        border-bottom-left-radius: 3px;
        height: 50px;
        position: absolute;
        right: 0px;
        text-align: center;
        top: 4px;
        width: 136px;
        span {
          @include fz(17, 34, 700);
          color: #fff;
          display: inline-block;
          left: 2px;
          position: relative;
          text-align: center;
          top: 6px;
          @media all and (-ms-high-contrast: none) {
            top: 8px;
          }
        }
    }
    .job-list {
        &__inner {
            border-left: 4px solid $gradeTop;
            border-right: 4px solid $gradeBottom;
            position: relative;
            &::after {
                @include icon-ini;
                background: $mainGrada;
                bottom: 0;
                height: 4px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }
        &__header {
            padding: 34px 30px 29px 34px;
        }
        &__tags {
            &--item {
                padding: 8px 10px 8px 10px;
            }
        }
        &__content {
            padding: 31px 30px 49px 35px;
            &--tags {
                margin-bottom: 22px;
            }
            &--list {
                padding-bottom: 12px;
                &:nth-of-type(3) {
                    //動的なら消してもいい気がする...
                    padding-bottom: 10px;
                }
                &:nth-of-type(4) {
                    //動的なら消してもいい気がする...
                    padding-bottom: 10px;
                }
                &:last-of-type {
                    margin-bottom: 33px;
                    padding-bottom: 11px;
                }
            }
            &--txt {
                padding-right: 6px;
            }
        }
        &__contactarea {
            .btn-detail {
                margin-right: 15px;
            }
            .btn-contact {
                margin-right: 8px;
            }
        }
    }
}
.job-result {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    &__numberlist {
        &--result {
            @include fz(15,15,500);
            .push {
                @include fz(24,24,600);
                font-family: $fontRoboto;
                left: -4px;
                margin-right: 3px;
                position: relative;
            }
        }
    }
    .pagenav {
        .current {
            padding:0;
            padding-top: 1px;
            margin: 0 3px 0 0;
        }
    }
    &__order {
        &--list {
            display: flex;
        }
        &--item {
            &:first-child {
                .job-result__order--link {
                    border-radius: 5px 0 0 5px;
                }
            }
            &:last-child {
                .job-result__order--link {
                    border-radius: 0 5px 5px 0;
                }
            }

        }
        &--link {
            @include fz(14,14,600);
            background: #fff;
            cursor: pointer;
            display: inline-block;
            padding: 11px 0 12px 1px;
            height: 35px;
            text-align: center;
            width: 85px;
            @media all and (-ms-high-contrast: none) {
              padding-top: 14px;
            }
            &.current {
                background: $siteColor;
                color: #fff;
            }
        }
    }
}
//お仕事検索
.jobsearch {
    background: $grayColor;

    label,
    select {
        cursor: pointer;
    }

    .l-page-header {
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
            //height: calc(482px / 4);
            height: 112px;
        }
        &__wrap {
            left: 2px;
            top: -15px;
            @include media(sm) {
                top: 0;
            }
        }
        &__subTitle {
            left: -2px;
            position: relative;
            top: -3px;
        }
        .l-scroll-down {
              top: 48.8% !important;
        }
    }

    .l-content {
        margin-bottom: 83px;
        margin-top: 100px;
    }


    .current-search {
        margin-bottom: 30px;
        &__inner {
            background: #fff;
            border: 1px solid #eaeaea;
            padding: 37px 51px 30px 38px;
            position: relative;
        }
        &__title {
            @include fz(22,22,600);
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 28px;
            margin-bottom: 19px;
        }
        &__content {
            &--list {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding-left: 1px;
            }
            $margin: 8px;//headとbodyの値
            &--head {
                @include fz(14,32,600);
                flex-basis: 12.75%;
                margin-bottom: $margin;
                &:first-of-type {
                    margin-bottom: 7px;
                }

            }
            &--body {
                @include fz(14,32,500);
                flex-basis: 80%;
                margin-bottom: 8px;
                &:first-of-type {
                    margin-bottom: 7px;
                }
                &:last-child{
                    flex-basis: 87%;
                }

            }
        }
        &__btn {
            left: -1px;
            text-align: center;
            position: relative;
            top: -20px;
            .btn-more {
                @include fz(15,40,600);
                font-family: $fontFamily;
                height: 40px;
                padding: 1px 0 0 21px;
                position: relative;
                width: 180px;
                cursor: pointer;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 4px;
                }
                &::before {
                    @include icon-ini;
                    background-color: #fff;
                    bottom: 0;
                    height: 1px;
                    left: 27px;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    width: 10px;
                    transition: transform 200ms;
                }
                &::after {
                    @extend ::before;
                    transform: rotate(90deg);
                }
                &.is-open {
                    &::before {
                        transform: rotate(180deg);
                        transition: transform 200ms;
                    }
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        .saerch-error {
            @include fz(18,18,600);
            color: #f13232;
            margin-right: 14px;
            margin-bottom: 35px;
            margin-top: 25px;
            text-align: center;
            position: relative;
            &::before {
                @include icon-ini;
                background-image: url(../images/jobsearch/ico-exclamation.svg);
                height: 31px;
                position: relative;
                right: 13px;
                top: -3px;
                width: 31px;
            }
        }
    }

    .job-search {
        margin-bottom: 61px;
        &__header {}
        &__title {
            @include fz(20,20,600);
            background: #37414f;
            border-radius: 5px 5px 0 0;
            display: inline-block;
            height: 39px;
            padding: 12px 0 0px 16px;
            width: 155px;
            font-family: $fontAlegreyaSansSC;
            color: #fff;
            position: relative;
            &::before {
                @include icon-ini;
                background-image: url(../images/ico-search.svg);
                height: 14px;
                margin-right: 11px;
                position: relative;
                width: 14px;
            }
        }
        &__content {
            background: #fff;
            border: 8px solid $siteColor;
            padding: 26px 31px 40px;
            &--list {
                align-items: center;
                border-bottom: 1px solid #eaeaea;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 16px;
                &:last-of-type {
                    align-items: flex-start;
                }
                &:not(:first-of-type) {
                    margin-top: 21px;
                    padding-bottom: 19px;
                }
                &:nth-of-type(2) {
                    margin-top: 19px;
                }
                &:nth-of-type(3) {
                    margin-top: 18px;
                    padding-bottom: 16px;
                }
                &:nth-of-type(4) {
                    margin-top: 17px;
                    padding-bottom: 16px;
                }
                &:nth-of-type(5) {
                    margin-top: 18px;
                    padding-bottom: 16px;
                }
                &:nth-of-type(6) {
                    padding-bottom: 9px;
                }
                &_head {
                    @include fz(14,14,600);
                    flex-basis: 12.6%;
                }
                &_body {
                    @include fz(14,14,600);
                    flex-basis: calc(100% - 12.7% - 11px);
                    &:first-child{
                      @include fz(14,14,600);
                    }
                    select {
                        font-size: 12px;
                        width:166px;
                        text-indent: 3px;
                    }
                    input[type="text"] {
                      @include fz(14,32,600);
                      margin: 0;
                      padding: 0 22px 1px 22px;
                      border: 0;
                      background-color: $grayColor;
                      outline: 0;
                      width: 95%;
                      height: 40px;
                    }
                }
                .jobstyle {
                    //雇用形態
                    label {
                        margin-right: 30px;
                        position: relative;
                        top: 2px;
                        &:nth-of-type(2) {
                            margin-right: 25px;
                        }
                        &::before {
                            @include icon-ini;
                            background-color: #fff;
                            border: 1px solid #eaeaea;
                            border-radius: 3px;
                            height: 14px;
                            position: relative;
                            margin-right: 13px;
                            top: -2px;
                            left: 2px;
                            width: 14px;
                        }
                        input[type="checkbox"] {
                            display: none;
                            &:checked + .checkbox-parts {
                                &::before {
                                    @include icon-ini;
                                    background: #3b99fc;
                                    border-radius: 3px;
                                    height: 14px;
                                    left: -25px;
                                    position: absolute;
                                    top: 0px;
                                    width: 14px;
                                }
                                &::after {
                                    @include icon-ini;
                                    background: transparent;
                                    border-bottom: 2px solid #fff;
                                    border-right: 2px solid #fff;
                                    height: 9px;
                                    left: 4px;
                                    position: absolute;
                                    left: -21px;
                                    top: 1px;
                                    transform: rotate(40deg);
                                    width: 6px;
                                }
                            }
                        }
                        .checkbox-parts {
                            position: relative;
                        }
                    }
                }
                .area {
                    //エリア検索
                    &__warp {
                        position: relative;
                        width: 167px;
                        &::after {
                            @include icon-ini;
                            background: linear-gradient(#60adfd 20%, #097fff 80%);
                            border-radius: 0 3px 3px 0;
                            bottom: 0;
                            height: 20px;
                            margin: auto;
                            position: absolute;
                            right: -3px;
                            top: -4px;
                            width: 17px;
                            pointer-events: none;
                        }
                    }
                    label {
                        position: relative;
                        top: -2px;
                        left: 3px;
                        &::before {
                            @include icon-ini;
                            background: transparent;
                            border-bottom: 2px solid #fff;
                            border-right: 2px solid #fff;
                            height: 6px;
                            margin: auto;
                            right: 3%;
                            top: 3px;
                            position: absolute;
                            pointer-events: none;
                            transform: rotate(-135deg);
                            width: 6px;
                            z-index: 1;
                        }
                        &::after {
                            @extend ::before;
                            transform: rotate(45deg);
                            top: inherit;
                            bottom: -9px;
                        }
                    }
                    select {
                        @include fz(12,12,400);
                        appearance: none;
                        background: #fff;
                        border: 1px solid #dedede;
                        border-radius: 0 3px 3px 0;
                        height: 20px;
                        padding: 1px 0 0 7px;
                        width: 167px;
                    }
                }
                .jobtype {
                  //職種
                  &__warp {
                      position: relative;
                      width: 167px;
                      &::after {
                          @include icon-ini;
                          background: linear-gradient(#60adfd 20%, #097fff 80%);
                          border-radius: 0 3px 3px 0;
                          bottom: 0;
                          height: 20px;
                          margin: auto;
                          position: absolute;
                          right: -3px;
                          top: -4px;
                          width: 17px;
                          pointer-events: none;
                      }
                  }
                  label {
                      position: relative;
                      top: -2px;
                      left: 3px;
                      &::before {
                          @include icon-ini;
                          background: transparent;
                          border-bottom: 2px solid #fff;
                          border-right: 2px solid #fff;
                          height: 6px;
                          margin: auto;
                          right: 3%;
                          top: 3px;
                          position: absolute;
                          pointer-events: none;
                          transform: rotate(-135deg);
                          width: 6px;
                          z-index: 1;
                      }
                      &::after {
                          @extend ::before;
                          transform: rotate(45deg);
                          top: inherit;
                          bottom: -9px;
                      }
                  }
                  select {
                      @include fz(12,12,400);
                      appearance: none;
                      background: #fff;
                      border: 1px solid #dedede;
                      border-radius: 0 3px 3px 0;
                      height: 20px;
                      padding: 1px 0 0 7px;
                      width: 167px;
                  }
                }
                .salary {
                    //給料
                    align-items: center;
                    display: flex;
                    left: -1px;
                    position: relative;
                    top: -1px;
                    &__warp {
                        margin: 0 15px 0 13px;
                        position: relative;
                        width: 167px;
                        &:nth-of-type(2) {
                            margin: 0 0 0 10px;
                        }
                        &::after {
                            @include icon-ini;
                            background: linear-gradient(#60adfd 20%, #097fff 80%);
                            border-radius: 0 3px 3px 0;
                            bottom: 0;
                            height: 20px;
                            margin: auto;
                            position: absolute;
                            right: -3px;
                            top: -3px;
                            width: 17px;
                            pointer-events: none;
                        }
                    }
                    label {
                        position: relative;
                        top: -1px;
                        left: 3px;
                        &::before {
                            @include icon-ini;
                            background: transparent;
                            border-bottom: 2px solid #fff;
                            border-right: 2px solid #fff;
                            height: 6px;
                            margin: auto;
                            right: 3%;
                            top: 3px;
                            position: absolute;
                            pointer-events: none;
                            transform: rotate(-135deg);
                            width: 6px;
                            z-index: 1;
                        }
                        &::after {
                            @extend ::before;
                            transform: rotate(45deg);
                            top: inherit;
                            bottom: -9px;
                        }
                    }
                    select {
                        @include fz(12,12,400);
                        appearance: none;
                        background: #fff;
                        border: 1px solid #dedede;
                        border-radius: 0 3px 3px 0;
                        height: 20px;
                        padding: 1px 0 0 8px;
                        width: 167px;
                    }
                }
                .stick {
                    //こだわり条件
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    position: relative;
                    top: -2px;
                    label {
                        flex-basis: calc(100% / 4 - 8.5px);
                        margin-bottom: 19px;
                        position: relative;
                        top: 3px;
                        &::before {
                            @include icon-ini;
                            background-color: #fff;
                            border: 1px solid #eaeaea;
                            border-radius: 3px;
                            height: 14px;
                            position: relative;
                            margin-right: 13px;
                            top: -2px;
                            left: 2px;
                            width: 14px;
                        }
                        input[type="checkbox"] {
                            display: none;
                            &:checked + .checkbox-parts {
                                &::before {
                                    @include icon-ini;
                                    background: #3b99fc;
                                    border-radius: 3px;
                                    height: 14px;
                                    left: -25px;
                                    position: absolute;
                                    top: -1px;
                                    width: 14px;
                                }
                                &::after {
                                    @include icon-ini;
                                    background: transparent;
                                    border-bottom: 2px solid #fff;
                                    border-right: 2px solid #fff;
                                    height: 9px;
                                    left: 4px;
                                    position: absolute;
                                    left: -21px;
                                    top: 0;
                                    transform: rotate(40deg);
                                    width: 6px;
                                }
                            }
                        }
                        .checkbox-parts {
                            position: relative;
                        }
                    }
                }
            }
        }
        &__btn {
            margin: 29px auto 0;
            padding-right: 3px;
            text-align: center;
            .btn-more {
                @include fz(15,40,600);
                font-family: $fontFamily;
                height: 40px;
                position: relative;
                padding: 0 3px 0 0px;
                text-align: center;
                width: 280px;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 5px;
                }
                &::before {
                    @include icon-ini;
                    background-image: url(../images/ico-search.svg);
                    height: 16px;
                    position: relative;
                    right: 5px;
                    top: -1px;
                    width: 16px;
                }
            }
        }
    }
}

//お仕事詳細
.jobdetail {
    margin-bottom: 120px !important;
    &__point {
        background: #f1f9fb;
        display: flex;
        margin-bottom: 33px;
        padding: 13px 30px 12px 30px;
        min-height: 137px;
        &--title {
            @include fz(26,26,700);
            @include textclip($mainGrada);
            font-family: $fontAlegreyaSansSC;
            margin-right: 36px;
            margin-top: 4px;
            position: relative;
            &::after {
                @include icon-ini;
                background-image: url(../images/jobsearch/ico-point.svg);
                height: 65px;
                left: 13px;
                margin: auto;
                position: absolute;
                right: 0;
                top: 30px;
                width: 44px;
            }
        }
        &--list {}
        &--item {
            @include fz(14,28,600);

        }
    }
    &__back {
        margin: 0 auto;
        text-align: center;
        @media all and (-ms-high-contrast: none) {
          padding-top: 4px;
        }
        &--btn {
            @include fz(15,48,600);
            background: #dedede;
            border-radius: 3px;
            display: inline-block;
            height: 48px;
            margin-left: 5px;
            position: relative;
            text-align: center;
            width: 280px;
            &::before {
                @include icon-ini;
                background-image: url(../images/jobsearch/ico-arrow-back.svg);
                height: 12px;
                position: relative;
                right: 11px;
                top: -1px;
                width: 13px;
            }
        }
    }
    .job-list {
        margin-bottom: 40px;
        &__header {
            padding: 34px 30px 31px 39px;
        }
        &__title {
            margin-bottom: 14px;
        }
        &__content {
            padding: 41px 40px 50px 39px;
            &--tags {
                margin-bottom: 30px;
            }
            &--txt {
                padding-right: 1px;
            }
        }
        &__contactarea {
            margin: 24px auto 0;
            .btn-contact {
                margin-right: 0;
            }
        }
    }
}

@include media(sm) {
    .job-list {
        &__header {
            padding: 30px 20px;
        }
        &__title {
            @include fzs(32,51,600);
        }
        &__tags {
            &--item {
                @include fz(12,12,600);
                margin-bottom: 2px;
                margin-right: 2px;
            }
        }
        &__content {
            padding: 30px 20px;
            &--tags {
                &_item {
                    @include fz(12,28,600);
                    margin-bottom: 2px;
                    margin-right: 2px;
                }
            }
            &--list {
                flex-direction: column;
                flex-wrap: wrap;
                &_head {
                    @include fzs(24,24,600);
                    flex-basis: auto;
                    margin-bottom: 5px;
                }
                &_body {
                    @include fzs(24,48,500);
                    flex-basis: auto;
                }
            }
        }
        &__contactarea {
            .btn-detail {
                @include fz(15,15,600);
                font-family: $fontSpFamily;
                height: 45px;
                margin-bottom: 10px;
                margin-right: 0;
                padding: 15px 0 0 8px;
                width: 220px;
            }
            .btn-contact {
                @include fz(15,15,600);
                font-family: $fontSpFamily;
                height: 45px;
                margin-right: 0;
                padding: 16px 0 0 5px;
                width: 220px;
            }
        }
        .job-new {
            @include fzs(32,32,700);
            padding: 2px 0px 0 2px;
            height: 20px;
            width: 53px;
            margin-right: 10px;
        }
        &__category {
            @include fzs(24,24,600);
        }
    }
    .Recomlist {
        //おすすめ求人
        margin-bottom: 20px;
        &__pop {
            height: 40px;
            right: 0px;
            top: 4px;
            width: 130px;
            span {
                @include fz(16, 16, 700);
                top: 9px;
            }
        }
        .job-list {
            &__header {
                padding: 30px 20px;
            }
            &__content {
                padding: 30px 20px;
            }
            &__contactarea {
                .btn-detail {
                    margin-right: 0;
                }
                .btn-contact {
                    margin-right: 0;
                }
            }
        }
    }
    .job-result {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        &__numberlist {
        }
        .pagenav {
            margin-top: 50px;
            top: 0;
        }
        &__order {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
            &--link {
                @include fzs(24,24,600);
                padding: 11px 0px 9px;
                width: 65px;
            }
        }

        &.top {
            .pagenav {
                display: none;
            }
        }
        &.bottom {
            .job-result {
                &__numberlist--result {
                    display: none;
                }
                &__order--list {
                    display: none;
                }
            }
        }
    }
    .jobsearch {
        .l-content {
            margin-bottom: 30px;
            margin-top: 30px;
            padding-bottom: 0;
        }

        .current-search {
            margin-bottom: 30px;
            &__inner {
                padding: 30px 20px;
            }
            &__title {
                @include fzs(32,32,600);
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
            &__content {
                &--list {}
                &--head {
                    @include fzs(24,48,600);
                    flex-basis: 100%;
                    margin-bottom: 0;
                }
                &--body {
                    @include fzs(24,48,500);
                    flex-basis: 100%;
                    margin-bottom: 10px;
                    &:last-child {
                        flex-basis: 100%;
                    }
                }
            }
            &__btn {
                .btn-more {
                    font-family: $fontSpFamily;
                }
            }
            .saerch-error {
                @include fz(16,16,600);
                &::before {
                    background-size: 80%;
                    right: 3px;
                }
            }
        }

        .job-search {
            margin-bottom: 20px;
            &__content {
                border: 4px solid $siteColor;
                padding: 30px 20px;
                &--list {
                    &_head {
                        @include fzs(24,24,600);
                        flex-basis: 100%;
                        margin-bottom: 15px;
                    }
                    &_body {
                        @include fzs(24,24,600);
                        flex-basis: 100%;
                        input[type="text"] {
                            padding: 0 10px 1px 10px;
                            width: 100%;
                        }
                        select {
                            @include fzs(24,24,500);
                        }
                    }
                    .jobstyle {
                        //雇用形態
                        display: flex;
                        flex-wrap: wrap;
                        label {
                            flex-basis: 100%;
                            margin-bottom: 8px;
                            margin-right: 0;
                        }
                    }
                    .area {
                        //エリア検索
                        &__warp {
                            width: 100%;
                            &::after {
                                height: 30px;
                                width: calc(100% / 8);
                            }
                        }
                        label {
                            &::before {
                                transform: rotate(45deg);
                                height: 8px;
                                right: 5%;
                                top: 3px;
                                width: 8px;
                            }
                            &::after {
                                display: none;
                            }
                            select {
                                height: 30px;
                                width: 100%;
                            }
                        }
                    }
                    .jobtype {
                        //職種
                        &__warp {
                            width: 100%;
                            &::after {
                                height: 30px;
                                width: calc(100% / 8);
                            }
                        }
                        label {
                            &::before {
                                transform: rotate(45deg);
                                height: 8px;
                                right: 5%;
                                top: 3px;
                                width: 8px;
                            }
                            &::after {
                                display: none;
                            }
                            select {
                                height: 30px;
                                width: 100%;
                            }
                        }
                    }
                    .salary {
                        //給料
                        display: flex;
                        flex-wrap: wrap;
                        line-height: 2em;
                        &__warp {
                          margin: 5px 0;
                            width: 100%;
                            &::after {
                                height: 30px;
                                width: calc(100% / 8);
                            }
                            &:nth-of-type(2) {
                                margin-left: 0;
                            }
                        }
                        label {
                            &::before {
                                transform: rotate(45deg);
                                height: 8px;
                                right: 5%;
                                top: 3px;
                                width: 8px;
                            }
                            &::after {
                                display: none;
                            }
                            select {
                                height: 30px;
                                width: 100%;
                            }
                        }
                    }
                    .stick {
                        label {
                            flex-basis: 100%;
                        }
                    }
                }
            }
            &__btn {
                .btn-more {
                    width: 210px;
                }
            }
        }
    }
    //お仕事詳細
    .jobdetail {
        &__point {
            padding: 20px 20px;
            flex-direction: column;
            margin-bottom: 20px;
            &--title {
                margin-right: 0;
                margin-top: 0;
                &::after {
                    display: none;
                }
            }
            &--item {
                @include fz(12,24,600);
            }
        }
        .job-list {
            &__header {
                padding: 30px 20px;
            }
            &__content {
                padding: 30px 20px;
                &--tags {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
@include media(ss) {
    .jobsearch {
        .current-search {
            .saerch-error {
                @include fz(12,12,600);

            }

        }
    }

        .job-list {
            &__tags {
                &--item {
                    @include fz(11,24,600);
                    min-width: initial;
                    padding: 2px 11px 0;
                }
            }
            &__content {
                &--tags {
                    &_item {
                        @include fz(11,24,600);
                        min-width: initial;
                        padding: 2px 13px 0;
                    }
                }
            }
        }
    }
.jobsearch {
	.top-recruitment {
		margin-top: 40px;
		height: 648px;
		&::before {
			height: 511px;
		}
		@include media(sm) {
			padding-top: 1px;
			height: 507px;
			&:before {
				height: 377px;
			}
		}
	}
	.viewMore-btn {
		margin-bottom: 40px;
	}
}

// バナー（Sticky）
.bnr-recommend {
    &-wrap {
        position: absolute;
        right: 30px;
        top: -14px;
    }
    &-inner {
        height: 100%;
        width: 100%;
        position: relative;
    }
    &-btn {
        display: inline-block;
        top: 200px;
        transition: opacity 400ms ease;
    }
    &-close {
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        left: -17px;
        top: -18px;
        height: 35px;
        transform: rotate(45deg);
        width: 35px;
        transition: 400ms ease;
        z-index: 1;
        &:hover {
            opacity: .6;
        }
        .line {
            position: relative;
            display: inline-block;
            height: 35px;
            width: 35px;

            &:before,
            &:after {
                @include icon-ini;
                background: $mainGrada;
                border-radius: 1px;
                position: absolute;
                height: 17px;
                width: 2px;
                top: calc(50% - 9px);
            }
            &:before {
                left: calc(50% - 1px);
            }
            &:after {
                transform: rotate(90deg);
                left: calc(50% - 1px);
            }
        }
    }
    &-link {
        display: inline-block;
    }

    @include media(sm) {
        &-wrap {
            right: 20px;
            top: 80px;
        }
        &-inner {
        }
        &-btn {
            top: 105px;
        }
        &-close {
            left: -15px;
            top: -14px;
            height: 30px;
            width: 30px;
            .line {
                height: 30px;
                width: 30px;

                &:before,
                &:after {
                    height: 15px;
                    width: 2px;
                    top: calc(50% - 7px);
                }
            }
        }
        &-link {
            box-shadow: 0 3.78px 5.04px 1.26px rgba(#000, .1);
            height: 65px;
            width: 65px;
        }
    }
}
