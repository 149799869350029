@charset "utf-8";

.client {
  .l-header.is-hedder_chenge_own .l-nav__item.joinFree .l-nav__link {
    @media all and (-ms-high-contrast: none) {
          padding-top: 20px;
        }
  }
    .l-page-header {
        background-image: url(../images/client/mv.jpg);
        background-image: image-set(url(../images/client/mv.jpg) 1x , url(../images/client/mv@2x.jpg) 2x);
        height: 627px;
        z-index: 1;
        &__wrap {
            top: -49px;
            left: -2px;
        }
        &__title {
            @include fz(42,54,600);
            text-align: center;
            position: relative;
            left: -3px;
        }
        &__subTitle {
            @include fz(21,21,600);
            background: none;
            font-family: $fontFamily;
            margin-bottom: 27px;
            padding-bottom: 28px;
            position: relative;
            left: 7px;
            -webkit-text-fill-color: #333;
            &::before {
                @include icon-ini;
                background-image: url(../images/client/ico-building.svg);
                height: 22px;
                right: 16px;
                top: -3px;
                padding-right: 10px;
                position: relative;
                width: 18px;
            }
            &::after {
                @include icon-ini;
                background: $mainGrada;
                bottom: 0;
                height: 1px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                width: 30px;
            }
        }
    }

    //サービス紹介ページ指定
    .anderPage {
        background-image: url(../images/client/img-client-main.jpg);
        background-image: image-set(url(../images/client/img-client-main.jpg)1x,url(../images/client/img-client-main@2x.jpg)2x);
        height:500px;
        .l-page-header__wrap {
            top: -47px;
        }
        .l-page-header__title {
            left: 0;
        }
    }

    .bottomSpace{
        &:after{
         top: 66px!important;
        }
    }

    .l-block{
      position:relative;
      margin-top:0;
    }

    &-content{
        &__top{
            padding:65px 0;
            background:linear-gradient(#f5f5f5, #fff);
            position:relative;
            z-index: 0;
            &--inner{
                display:flex;
                padding-left:22px;
                &:after{
                  position:absolute;
                  content:"";
                  background-image: url(../images/client/img-bg.svg);
                  top: -123px;
                  left: -143px;
                  width:628px;
                  height:589px;
                  z-index: -1;
                }
                .image{
                    width: 468px;
                    img {
                        height: auto;
                    }
                    .human,.jobs,.consulting,.entrust,.part,.introduction{
                      vertical-align: middle;
                    }
                    .circle{
                      @include fz(18, 26, 700);
                      background:#fff;
                      width:148px;
                      height:148px;
                      border-radius:50%;
                      text-align: center;
                      border: 1px solid #555;
                    }
                }
                .textBlock{
                    margin-top:29px;
                    margin-left:58px;
                    .headline{
                        @include fz(36, 54, 700);
                        margin-bottom:5px;
                    }
                  .subTitle{
                      @include fz(18, 40, 700);
                      @include textclip($mainGrada);
                      font-family: 'Alegreya Sans SC', sans-serif;
                      text-indent: 3px;
                  }
                  .text{
                      @include fz(15, 34, 400);
                      margin-top: 4px;
                      margin-left: 3px;
                      margin-bottom: 27px;
                  }
                  .viewMore-btn{
                    font-family: $fontFamily;
                    a {
                      margin:0;
                    }
                  }
              }
          }
      }
        .dot-Circle {
            &:before {
              left:800px;
            }
        }
    }

    .top-reasonWhy {
        background-image: url(../images/client/img-strong-bg.jpg);
        background-image: img-set(url(../images/client/img-strong-bg.jpg) 1x,url(../images/client/img-strong-bg@2x.jpg) 2x);
        background-repeat: no-repeat;
        background-size: cover;
        padding-top:71px;
        padding-bottom:90px;
        &__header {
            &:before {
                background:none;
            }
            &:after {
                display:none;
            }
        }
      &__contents{
          width:1100px;
          margin: 38px auto 0;
          &--item{
              height: 272px;
              &:nth-child(3){
                  .top-reasonWhy__contents--title {
                      margin-left: 5px;
                  }
              }
              &:nth-child(4){
                  .top-reasonWhy__contents--title{
                    margin-left: 3px;
                    margin-top: 5px;
                  }
                  .top-reasonWhy__contents--txt{
                    margin-left:-3px;
                  }
              }
              &:nth-child(5){
                  .top-reasonWhy__contents--title{
                    margin-left:21px;
                    margin-top: 5px;
                  }
                  .top-reasonWhy__contents--txt{
                    margin-left:6px;
                  }
              }
              &:nth-child(6){
                  .top-reasonWhy__contents--title{
                    margin-left: 4px;
                    margin-top: 5px;
                  }
                  .top-reasonWhy__contents--txt{
                    margin-left:5px;
                  }
              }
              &:nth-child(n+4){
                    height:294px;
              }
          }
          &--txtWrap {
              margin-top: 4px;
          }
          &--title {
              position: relative;
              margin-bottom:45px;
              &:before{
                  @include icon-ini;
                  position:absolute;
                  width:30px;
                  height:1px;
                  background:$mainGrada;
                  top: 87px;
                  left: 0;
                  right: 0;
                  margin: auto;
              }
          }
      }
      .borderB{
          position:relative;
          margin-bottom:45px;
          &:before{
              position:absolute;
              content:"";
              width:30px;
              height:1px;
              background:$mainGrada;
              top: 85px;
              left: 0;
              right: 0;
              margin: auto;
          }
      }

      .viewMore-btn{
        width:190px;
      }
    }

    &-recruit{
        &__header{
            text-align: center;
            &::after {
              @include icon-ini;
              background-image: url(../images/dot.svg);
              background-repeat: repeat;
              background-size: 9px 9px;
              background-position: 0 0;
              border-radius: 50%;
              height: 325px;
              right: -175px;
              position: absolute;
              top: -43px;
              width: 325px;
              z-index: -1;
            }
        }
        &__title {
            margin-bottom: 14px;
        }
        &__inner{
          padding-top:67px;
        }
        &__contents {
            margin-top:32px;
            margin-bottom:26px;
            &--item {
                position: relative;
                .new-pop {
                    position: absolute;
                    left: 6px;
                    top: -29px;
                    @include media(sm) {
                        top: -20px;
                    }
                }
            }
            &--txt{
                margin-bottom: 17px;
            }
            &--title{
              @include fz(18, 30, 700);
                  margin-bottom: 15px;
            }
            &--readMore {
                border-bottom: 1px solid #f5f5f5;
            }
        }
        .spaceBottom{
            margin-bottom:20px;
        }
    }


  &-tradingCompanies {
      margin-bottom: 118px;
      margin-top: 90px;
      &__header {
          margin-bottom: 19px;
      }
      &__title {
          @include fz(16,16,600);
      }
      &__contents {
          ul {
              align-items: center;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
          }
          li {
              border-left: 1px solid #eaeaea;
              border-top: 1px solid #eaeaea;
              width: 20%;
              height: 99px;
              padding: 40px 0;
              position: relative;
              text-align: center;
              @include media(lg) {
                  //調整
                  &:nth-child(5) {
                      img {
                          left: -13px;
                          top: -10px;
                      }
                  }
                  &:nth-child(6) {
                      img {
                          left: -6px;
                          top: -8px;
                      }
                  }
                  &:nth-child(7) {
                      img {
                          top: -8px;
                      }
                  }
                  &:nth-child(8) {
                      img {
                          left: 14px;
                          top: -10px;
                      }
                  }
                  &:nth-child(9) {
                      img {
                          left: -7px;
                          top: 3px;
                      }
                  }
                  &:nth-child(10) {
                      img {
                          left: 7px;
                          top: -5px;
                      }
                  }
                  &:nth-child(11) {
                      img {
                          left: 0;
                          top: 4px;
                      }
                  }
                  &:nth-child(12) {
                      img {
                          left: 0;
                          top: 0px;
                      }
                  }
                  &:nth-child(14) {
                      img {
                          left: -3px;
                          top: 7px;
                      }
                  }
                  &:nth-child(15) {
                      img {
                          left: 5px;
                          top: 4px;
                      }
                  }
                  &:nth-child(16) {
                      img {
                          left: 7px;
                          top: -5px;
                      }
                  }
                  &:nth-child(17) {
                      img {
                          left: 1px;
                          top: -4px;
                      }
                  }
                  &:nth-child(18) {
                      img {
                          top: -4px;
                      }
                  }
                  &:nth-child(19) {
                      img {
                          left: -6px;
                      }
                  }
                  &:nth-child(20) {
                      img {
                          left: 0;
                          top: -7px;
                      }
                  }
                  &:nth-child(21) {
                      img {
                          left: -6px;
                          top: 0px;
                      }
                  }
                  &:nth-child(22) {
                      img {
                          left: -6px;
                          top: -4px;
                      }
                  }
                  &:nth-child(23) {
                      img {
                          left: -4px;
                          top: -4px;
                      }
                  }
                  &:nth-child(24) {
                      img {
                          left: -4px;
                          top: -3px;
                      }
                  }
                  &:nth-child(25) {
                      img {
                          left: 2px;
                          top: -1px;
                      }
                  }
              }
              &:nth-child(5n) {
                  border-right: 1px solid #eaeaea;
              }
              &:nth-child(n+21) {
                  border-bottom: 1px solid #eaeaea;
              }
              img {
                  bottom: 0;
                  left: 0;
                  margin: auto;
                  position: absolute;
                  right: 0;
                  top: 0;
              }
          }
      }
  }

//PC用共通パーツ指定
.textBlock{
    width:539px;
    .headding{
        @include fz(28,32,700);
        color: #333;
        margin-bottom: 26px;
        &:after {
            @include icon-ini;
            background: $mainGrada;
            display:block;
            height: 1px;
            margin-top: 27px;
            width: 30px;
        }
    }
    .set{
      @include fz(28,40,700);
      margin-bottom:40px;
      &:before{
        top:100px;
      }
    }
    .under{
      margin-bottom:50px;
    }
    .subtitle{
      @include fz(20,34,700);
      margin-bottom:12px;
    }
    .text{
      @include fz(15,30,400);
    }
}
//↑共通パーツ指定ここまで！


  .spaceLeft{
    margin-top: 2px;
  }

    &-service{
        &::after {
            @include icon-ini;
            background: linear-gradient(to bottom, rgba(245, 245, 245, 1), #fff 80%);
            height: 623px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -2;
        }
        &__content{
            &--top{
                margin-top:98px;
                margin-bottom: 119px;
            }
            &--inner{
                width:1100px;
                margin:0 auto;
                display:flex;
                flex-direction: row-reverse;
                background:#fff;
                box-shadow: -21.213px 21.213px 57px 0px rgba(0, 0, 0, 0.04);
                margin-bottom:30px;
                position: relative;
                justify-content: space-between;
                padding: 38px 60px 34px 40px;
                &:first-child {
                    .textBlock {
                        .headding {
                            margin-bottom: 26px;
                        }
                        .subtitle {
                            margin-bottom: 11px;
                        }
                    }
                    .imageBlock {
                        position: relative;
                        left: -1px;
                        top: 4px;
                    }
                }
                &:nth-child(2){
                    padding-bottom:40px;
                    padding-top: 37px;
                    .textBlock{
                      .subtitle{
                          margin-bottom:14px;
                      }
                    }
                    .imageBlock {
                        position: relative;
                        left: 5px;
                        top: -2px;
                    }
                }
                &:nth-child(3){
                    padding-bottom:35px;
                    padding-top: 34px;
                    .textBlock{
                        .subtitle{
                            margin-bottom:15px;
                        }
                    }
                    .imageBlock {
                        position: relative;
                        left: -1px;
                        top: 7px;
                    }
                }
                &:nth-child(4){
                  padding-top: 34px;
                  padding-bottom:52px;
                  .textBlock{
                    width:520px;
                    margin-right: 50px;
                    .subtitle{
                        margin-bottom: 12px;
                    }
                  }
                  .imageBlock {
                      position: relative;
                      left: 0;
                      top: 4px;
                  }
                }
                &:nth-child(5){
                  padding-top: 40px;
                  padding-bottom: 36px;
                  .textBlock{
                    width: 532px;
                    margin-right: 40px;
                    .subtitle{
                      margin-bottom:13px;
                    }
                  }
                  .imageBlock {
                      position: relative;
                      left: 0;
                      top: 4px;
                  }
                }
                &:nth-child(6){
                  padding-top:38px;
                  padding-bottom:42px;
                  .textBlock{
                    width:538px;
                    .subtitle{
                      margin-bottom:13px;
                    }
                  }
                  .imageBlock{
                      position: relative;
                      left: 0;
                      top: 4px;
                  }
                }
            }
            .bottom{
              padding-bottom:40px;
              .subtitle{
                margin-bottom:14px;
              }
            }
            .baseTop{
              padding-bottom:46px;
            }
            .space{
              padding-top: 40px;
              padding-bottom: 51px;
            }
            .next{
              padding-bottom:50px;
            }
            .more{
              padding-bottom:40px;
            }
            .end{
              padding-bottom:51px;
            }
        }
    }

    &-strength {
      &::after {
          @include icon-ini;
          background: linear-gradient(to bottom, rgba(245, 245, 245, 1), #fff 80%);
          height: 623px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: -2;
      }
        &__content{
            &--top{
              margin-top:98px;
              margin-bottom:120px;
            }
            #suggestion,
            #work,
            #performance,
            #location,
            #compliance,
            #system {
                margin-top: -100px; /* リンク位置上昇分 */
                padding-top: 100px; /* 上昇分を戻す分 */
            }

            #suggestion {
                .client-strength__content--inner{
                    padding-top: 35px;
                    padding-bottom:50px;
                    .imageBlock{
                        margin-top:7px;
                    }
                }
            }
            #work {
                .client-strength__content--inner{
                    padding-top: 40px;
                    padding-bottom: 51px;
                    .imageBlock{
                        margin-top: 3px;
                        margin-right: 18px;
                    }
                }
            }
            #performance {
                .client-strength__content--inner{
                    padding-top:36px;
                    padding-bottom:50px;
                    .textBlock{
                        .headding{
                        }
                        .subtitle{
                        }
                    }
                    .imageBlock{
                        margin-top: 4px;
                          img {
                              margin-top:0px;
                          }
                    }
                }
            }
            #location {
                .client-strength__content--inner{
                    padding-top: 37px;
                    padding-bottom:55px;
                    .textBlock{
                        .headding{
                        }
                        .subtitle{
                        }
                    }
                    .imageBlock{
                        margin-top:4px;
                    }
                }
            }
            #compliance {
                .client-strength__content--inner{
                    padding-top:33px;
                    padding-bottom:42px;
                    .textBlock{
                        .headding{
                        }
                        .subtitle{
                        }
                    }
                    .imageBlock{
                        margin-top:1px;
                    }
              }
            }
            #system {
                .client-strength__content--inner{
                    padding-bottom:51px;
                    .textBlock{
                        .headding{
                        }
                        .subtitle{
                        }
                    }
                    .imageBlock{
                        img{
                          margin-top:2px;
                        }
                    }
                }
            }
            &--inner {
                width:1100px;
                margin:0 auto;
                display:flex;
                flex-direction: row-reverse;
                background:#fff;
                box-shadow: -21.213px 21.213px 57px 0px rgba(0, 0, 0, 0.04);
                margin-bottom:30px;
                padding:38px 40px 34px 39px;
                .textBlock{
                    width:539px;
                    margin-right:32px;
                    .headding{
                        @include fz(28,32,700);
                        margin-bottom:54px;
                        position:relative;
                        &:after{
                            content:"";
                            background:$mainGrada;
                            display: block;
                            height: 1px;
                            margin-top: 26px;
                            margin-left: 0px;
                            position:absolute;
                            width: 30px;
                        }
                    }
                    .subtitle{
                        @include fz(20,34,700);
                        margin-bottom:11px;
                    }
                    .text{
                        @include fz(15,30,400);
                    }
              }
              .imageBlock {
                  margin-right: 20px;
              }
          }
          .bottom{
              padding-bottom:40px;
              .subtitle{
                  margin-bottom:14px;
              }
          }
        }
    }


    //ここからSPサイズ
    @include media(sm) {
        .l-page-header {
            background-image: url('../images/client/mv_sp.jpg');
            height: calc(482px / 2);
            &__wrap {
                top: 0 !important;
                padding: 0 20px;
            }
            &__title {
                @include fzs(48,60,600);
            }
            &__subTitle {
                @include fzs(28,28,600);
                margin-bottom: 15px;
                padding-bottom: 15px;
                &::before {
                  background-image: url(../images/client/ico-building@2x.png);
                  height: calc(22px * 0.7);
                  right: 10px;
                  top: -1px;
                  width: calc(18px * 0.7);
                }
            }
        }

        //サービス紹介ページ指定
        .anderPage {
            background-position: center right;
            background-size: cover;
            height:calc(482px / 2);
        }

        .l-content{
            margin-bottom: 30px;
            padding-bottom: 0;
        }

        &-content{
            &__top{
                &--inner{
                    width:100%;
                    display:block;
                    margin:0 auto;
                    padding:0px;
                    &:after{
                        background: none;
                        background-repeat: no-repeat;
                        background-size: 40%;
                        top: -82px;
                        left: 0;
                    }
                    .image{
                        height:auto;
                        margin-bottom:30px;
                        width:100%;
                        img{
                            display: block;
                            margin: 0 auto;
                            width: 100%;
                        }
                    }
                    .textBlock{
                        margin:0;
                        .headline{
                            @include fzs(42, 54, 700);
                        }
                      .text{
                          @include fzs(28, 48, 400);
                          margin: 0px;
                          margin-bottom: 20px;
                          top: -20px !important;
                      }
                      .viewMore-btn{
                          a{
                              margin:0 auto;
                          }
                      }
                  }
              }
          }
          .dot-Circle{
              &:before{
                  left: 140px;
              }
          }
      }

      .top-reasonWhy {
          &__contents{
              width:1100px;
              margin: 38px auto 0;
              &--link{
                  padding: 15px 10px 15px;
              }
              &--item{
                  height:calc(525px / 2);
                  &:not(:nth-child(2)) {
                      .top-reasonWhy__contents--title {
                          margin-bottom: 53px !important;
                      }
                  }
                  &:nth-child(n+4){
                      height:calc(525px / 2);
                  }
              }
              &--title {
                  margin: 0 0 30px !important;
                  &:before{
                      top:130%;
                  }
              }
          }
      }

      &-recruit{
          margin-top: 40px !important;
          &__inner{
              padding-top: 0px;
          }
          &__contents {
              &--item {
                  margin: 0 auto;
                  display:block;
                  width: 100%;
              }
              &--imgWrap {
                  height: 280px;
              }
          }
          .spaceBottom{
              margin: 0px;
          }
      }

      &-tradingCompanies {
          &__title {
              @include fz(16,16,600);
          }
          &__contents {
              li {
                  flex-basis: 50%;
                  height: 80px;
                  padding: 20px;
                  &:nth-child(odd) {
                      border-right: 1px solid #eaeaea !important;
                  }
                  &:nth-child(even) {
                      border-right: 1px solid #eaeaea !important;
                      border-left: none;
                  }
                  &:nth-child(n+25) {
                      border-bottom: none !important;
                  }
                  &:nth-child(5n) {
                      border-right: none;
                  }
                  &:nth-child(n+21) {
                      border-bottom: none;
                  }
                  &:nth-child(n+23) {
                      border-bottom: 1px solid #eaeaea;
                  }
                  &:last-child {
                      border-top: none;
                      border-bottom: 1px solid #eaeaea !important;
                  }
                  img {
                      object-fit: scale-down;
                      width: 75%;
                  }
              }
          }
      }

      //SP用共通パーツ指定
      .textBlock{
          width:100%;
          .headding{
              @include fzs(42, 22, 700);
              display: block;
              position: absolute;
              top: 30px;
              &:after {
                  top: 21px;
              }
          }
          .subtitle{
              @include fzs(32, 44, 700);
              margin-bottom: 0px !important;
              position: relative;
              top: 60px;
          }
          .text{
              @include fzs(28, 48, 400);
              margin-top: 20px;
              position: relative;
              top: 60px;
          }
      }
      .imageBlock{
          margin: 0;
          margin-bottom:30px;
          left: 0 !important;
          top: 70px !important;
          width:100%;
          &-img {
              width: 100%;
          }
      }

      &-service,
      &-strength {
        &__content{
            &--top{
                margin-bottom:50px;
                margin-top: 0px;
                padding-top: 30px;
            }
            &--inner{
                display: block;
                padding: 20px 20px 80px 20px!important;
                width: 100%;
            }
        }
      }
      //↑共通パーツ指定ここまで！

      &-service {
          &__content{
              &--top{
                  margin-bottom:50px;
                  margin-top: 0px;
                  padding-top: 30px;
              }
              &--inner{
                  &:nth-child(3){
                      .textBlock{
                          .subtitle{
                                top: 65px;
                          }
                      }
                  }
                  &:nth-child(4){
                      .textBlock{
                          width:100%;
                      }
                  }
                  &:nth-child(5){
                      .textBlock{
                          width:100%;
                      }
                  }
                  &:nth-child(6){
                      .textBlock {
                          width:100%;
                      }
                  }
              }
          }
      }

      &-strength {
          &__content{
              &--top {
                  margin-top:20px;
                  margin-bottom:50px;
              }
              #suggestion,
              #work,
              #performance,
              #location,
              #compliance,
              #system {
                margin-top: -80px; /* リンク位置上昇分 */
                padding-top: 80px; /* 上昇分を戻す分 */
              }
              #suggestion,
              #work {
                  .client-strength__content--inner {
                      padding-bottom: 120px !important;
                  }
                  .headding {
                      top: 20px !important;
                      &:after {
                          top: 47px;
                      }
                  }
                  .imageBlock {
                      top: 85px !important;
                  }
                  .textBlock {
                      .text {
                          margin-top: 0px !important;
                          top: 100px;
                      }
                  }
              }
              #performance,
              #location,
              #compliance,
              #system {
                .textBlock {
                    .text {
                        margin-top: 28px !important;
                    }
                }
              }
              &--inner {
                  display: block;
                  padding: 20px 20px 80px 20px!important;
                  position: relative;
                  width:100%;
                  .textBlock{
                      width:100%;
                      .headding{
                          @include fzs(42, 54, 700);
                          margin-bottom:20px !important;
                          &:after{
                              margin-top: 21px;
                          }
                      }
                      .subtitle{
                          @include fz(18,34,700);
                      }
                      .text{
                          @include fzs(28, 48, 400);
                          display: inline-flex;
                          margin-top: 20px;
                      }
                  }
                  .imageBlock {
                      margin: 0 !important;
                      margin-bottom:30px;
                      position: relative;
                  }
              }
          }
      }
    }

    //iPhoneSE、5用指定
    @include media(ss) {
        &-strength {
            &__content{
                &--top {
                    margin-top:20px;
                    margin-bottom:50px;
                }

                #suggestion,
                #work,
                #performance,
                #location,
                #compliance,
                #system {
                  margin-top: -80px; /* リンク位置上昇分 */
                  padding-top: 80px; /* 上昇分を戻す分 */
                }

                #suggestion {
                    .client-strength__content--inner {
                        padding-bottom: 150px !important;
                    }
                    .headding {
                        top: 20px !important;
                        width: 86%;
                        &:after {
                            top: 47px;
                        }
                    }
                    .imageBlock {
                        top: 118px !important;
                    }
                    .textBlock {
                        .text {
                            top: 134px;
                        }
                    }
                }

                #work {
                    .textBlock {
                        .text {
                            margin-top: 10px !important;
                        }
                    }
                }

                #performance,
                #location {
                  .imageBlock {
                      top: 100px !important;
                  }
                  .textBlock {
                      .text {
                          margin-top: 55px !important;
                      }
                  }
                }

                #work,
                #compliance {
                  .headding {
                      top: 20px !important;
                      &:after {
                          top: 22px;
                      }
                  }
                  .imageBlock {
                      top: 92px !important;
                  }
                }

                #compliance {
                    .textBlock {
                        .text {
                            margin-top: 50px !important;
                        }
                    }
                }

                #system {
                  .headding {
                      &:after {
                          top: -5px;
                      }
                  }
                  .imageBlock {
                      margin-top: 10px !important;
                  }
                }

                &--inner {
                    display: block;
                    padding: 20px 20px 80px 20px!important !important;
                    position: relative;
                    width:100%;
                    .textBlock{
                        width:100%;
                        .headding{
                            @include fzs(42, 54, 700);
                            margin-bottom:20px !important;
                            width: 86%;
                            &:after{
                                margin-top: 50px;
                            }
                        }
                        .subtitle{
                            @include fz(18,34,700);
                        }
                        .text{
                            @include fzs(28, 48, 400);
                            display: inline-flex;
                            margin-top: 20px;
                        }
                    }
                    .imageBlock {
                        margin: 0 !important;
                        margin-bottom:30px;
                        position: relative;
                    }
                }
            }
        }
    }
}
