@charset "utf-8";
.inquiry {
    .l-page-header{
        background-image: url(../images/mv.jpg);
        background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        height: 300px;
        position:relative;
        @include media(sm) {
            background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2);
        }
        &__wrap {
            top: -16px;
            @include media(sm) {
                top: 0;
            }
        }
        &__title {
            @media screen and (max-width: 320px) {
                font-size: 20px;
            }
        }
        &__subTitle {
            position: relative;
            top: -3px;
            @include media(sm) {
                top: 0;
            }
        }
        .l-scroll-down {
            top: 48.8%;
        }
    }
      .form-block {
          .form-overview {
              margin-top: 30px;
          }
      }
      .question {
        .title{
          @include media(sm) {
            width:100%!important;
          }
        }
        .textarea {
              padding-left: 30px;
              @include media(sm) {
                padding-left:0;
              }
        }
      }
      .telphone {
          &__txtlong {
              margin: 0 0 0 10px !important;
              @include media(sm) {
                  margin: 0 !important;
              }
          }
      }

      //SPサイズ
      @include media(sm) {
        .l-content {
            padding-bottom: 0;
        }
        .form-block {
            .form-overview {
                &__inner {
                    .line {
                        padding: 20px 0;
                    }
                }
            }
        }
      }
}
