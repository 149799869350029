@charset "utf-8";

.accounting {
    .l-page-header{
        background-image: url(../images/mv.jpg);
		background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
        position:relative;
        z-index: 1;
        height: 300px;
        @include media(sm) {
			background-image: url(../images/mv_sp.jpg);
            height: calc(482px / 2);
        }
        .l-scroll {
            top: -61px;
        }
    }

    .l-page-header__title {
        position: relative;
        margin-top: -16px;
		@include media(sm) {
			margin-top: 0;
		}
    }

    .l-page-header__subTitle {
    }

    .l-block{
      position:relative;
      margin-top:0;
    }

    &-content{
        width: 1107px;
        margin: 42px auto 120px;
        padding-top: 34px;
        position:relative;
        z-index: 0;
        @include media(sm) {
          width:100%;
          margin:20px auto;
        }
        &__inner{
            &--topText{
                .headline{
                    @include fz(28, 75, 700);
                    color: #333;
                    margin-bottom: 33px;
                    &:after {
                        @include icon-ini;
                        background: $mainGrada;
                        display:block;
                        height: 1px;
                        margin-top: 19px;
                        width: 30px;
                    }
                }
                .text{
                  @include fz(15,28,400);

                }

                @include media(sm) {
                  .headline{
                      @include fzs(42, 22, 700);
                      margin-bottom: 0px;
                      &:after {
                          margin-top: 19px;
                          margin-left: 2px;
                      }
                  }
                    .text {
                        @include fzs(28, 48, 400);
                        margin-left: 0px;
                        margin-top: 14px;
                    }
                }
            }
            &--bottomText{
                background:#fff;
                display: block;
                margin-top: 32px;
                padding: 35px 40px 0px 40px;
                box-shadow: -21.213px 21.213px 57px 0px rgba(0, 0, 0, 0.04);
                height: 328px;
                @include media(sm) {
                    margin-top: 30px;
                    padding: 20px;
                    height: auto;
                }
                .title{
                    @include fz(24,32,700);
                    display: block;
                    margin-bottom:17px;
                    width: 100%;
                    @include media(sm) {
                        @include fz(20,32,700);
                    }
                }

                //text、imgの囲い
                .allbox {
                    display: flex;
                    @include media(sm) {
                        flex-direction: column-reverse;
                    }
                }
                //textブロック
                .textbox{
                    display: block;
                    .text{
                      @include fz(15,28,400);
                    }
                    @include media(sm) {
                        position: relative;
                        .text{
                          @include fzs(28, 48, 400);
                        }
                    }
                }
                .buttomarea{
                    margin-top: 30px;
                    display: flex;
                    @include media(sm) {
                      display: block;
                    }
                    .btn-more{
                        @include fz(15,50,700);
                        font-family:$fontFamily ;
                        padding: 0px 54px 1px 52px;
                        margin-top: 1px;
                        &:before {
                            @include icon-ini;
                            background-image: url(../images/ico-search.svg);
                            height: 14px;
                            left: -8px;
                            top:-2px;
                            position: relative;
                            width: 14px;
                        }
                        @include media(sm) {
                            @include fzs(28, 32, 700);
                            font-family: $fontSpFamily;
                            height:50px;
                            margin:0 auto;
                            padding-top: 17px;
                            text-align: center;
                            width:100%;
                            &:before {
                                top: -2px;
                            }
                        }
                    }
                    .staff{
                      @include fz(15,24,700);
                        margin-top:1px;
                        padding-top: 16px;
                        margin-left: 16px;
                        padding: 13px 32px 0 28px;
                        height: 50px;
                        border-radius: 50px;
                        background: $mainGrada;
                        color: #fff;
                        display: inline-block;
                        &:after {
                            display: none;
                        }
                        @include media(sm) {
                            @include fzs(28, 32, 700);
                            margin-left:0;
                            margin-top: 10px;
                            padding-left:45px;
                            padding-top: 17px;
                            text-align: center;
                            width:100%;
                        }
                        &::before {
                            @include icon-ini;
                            background-image: url(../images/icon-memo.svg);
                            height: 20px;
                            margin-right: 6px;
                            margin-top: -1px;
                            width: 18px;
                            @include media(sm) {
                                background-image: url(../images/icon-memo.svg);
                                height: calc(35px / 2);
                                position: relative;
                                left: 1px;
                                width: calc(32px / 2);
                            }
                        }
                        &:hover {
                            color: #fff;
                            opacity: .8;
                            background-clip: inherit;
                            -webkit-text-fill-color: inherit;
                            transition: transform 200ms;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                //imgブロック
                .imageblock{
                    margin-left: 89px;
                    position: relative;
                    top: -55px;
                    @include media(sm) {
                        background: #fff;
                        margin: 0 auto 10px;
                        top: 0;
                    }
                }
            }
        }
    }
}
