@charset "utf-8";

* {
    box-sizing: border-box;
}
*::before,
*::after {
    box-sizing: border-box;
    backface-visibility: hidden;//幸せの呪文
}

html {
    font-size: $fontSize;
    -webkit-font-smoothing: antialiased; //transitionによる文字のにじみ回避
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
body {
    background-color: $bodyColor;
    color: $textColor;
    font-family: $fontFamily;
    line-height: $lineHeight;
    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    word-wrap: break-word;

    @include media(sm) {
        font-family: $fontSpFamily;
    }
}

// text
strong, em {
    font-weight: 700;
}
pre {
    white-space: pre-wrap;
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

// link
a {
    color: $linkColor;
    outline: none;
    text-decoration: none;
    transition: background-color 100ms, border-color 100ms, color 100ms, opacity 100ms;
}

// image
img, svg, video {
    max-width: 100%;
    vertical-align: top;
}

// table
table {
    max-width: 100%;
    width: 100%;
    th, td {
        word-break: break-all;
    }
}

// form
input,
button,
select,
textarea {
    color: inherit;
    //font-family: inherit;デフォルトのselect表示させるため
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;
    padding: 0;
    vertical-align: middle;
    &::-moz-focus-inner {
        border: 0;
    }
}
input,
button,
textarea {
    appearance: none;
}
input[type="checkbox"] {
    appearance: checkbox;
}
input[type="radio"] {
    appearance: radio;
}
textarea {
    resize: none;
}
input[type="button"],
input[type="submit"],
button {
    background: none;
    border: 0;
    cursor: pointer;
}
button {
    overflow: visible; //IE11
}

// テキスト選択時のスタイル
// 同じ内容でも分けて指定しないと効かない
::selection {
    background: #06c;
    color: #fff;
}
::-moz-selection {
    background: #06c;
    color: #fff;
}

// Macだと入力中の文字に::selectionが効いてしまい、見えなくなるため
input::selection,
textarea::selection {
    background: #ddd;
    color: $textColor;
}
input::-moz-selection,
textarea::-moz-selection {
    background: #ddd;
    color: $textColor;
}

// IE11のクリアボタンを非表示
input::-ms-clear {
    display: none;
}
//スクロールダウン
.l-scroll{
    position: absolute;
    width: 61px;
    right: 0;
    border-left: solid 1px #fff;
    top: 0;
    //height: calc( 100% - 77px);
    height: 100%;
    opacity: 0;
    transform: translate(100px, 0);
    transition: transform 400ms $easeOutQuart 0ms;
    //transition: opacity 800ms, transform 800ms $easeOutQuart 1000ms;
    &-down { // テキストの配置
        $scroll: &;
        @include fz(15,15,700);
        //@include letterSpacing(220);
        font-family: $fontAlegreyaSansSC;
        color: #fff;
        position: absolute;
        right: 23px;
        transform: translateY(-50%);
        white-space: nowrap;
        writing-mode: vertical-rl;
        z-index: 100;
        top: 48.8%;
        &_view { //ドット移動範囲
            display: block;
            overflow: hidden;
            pointer-events: none;
            position: absolute;
            top: 103px;
            left: 99%;
            width: 17px;
            height: 25px;
            margin-left: -16px;
            border: 1px solid #fff;
            border-radius: 50px;
            box-sizing: border-box;
            &::before {
                background: #fff;
                content: '';
                display: block;
                position: absolute;
            }
            &::before {
                width: 1px;
                border-radius: 50%;
                left: 7px;
                height: 5px;
                top: 2px;
            }

        }
        &_txt {
          padding: 10px;
        }
    }
    &-down { // テキストの配置
        pointer-events: none;
        &::before {
            top: 20px;
        }
        &_view { //ドット移動範囲
            bottom: -47px;
            &::before {
                animation: scrollDown 1300ms $easeInOutCubic 1000ms infinite both;
            }
        }
    }
    @include media(sm) {
      display: none;
    }
}

.ico-grad-arrow {
  background: $mainGrada;
  background-size: 14px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  i {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 4px solid #fff;
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 6px;
  }
  @include media(sm) {
    height: calc(24px / 2);
    width: calc(24px/ 2);
    i {
      top: 4px;
      left: 5px;
    }
  }
}
