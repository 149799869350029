@charset "utf-8";
.client-order {
  .l-page-header{
      background-image: url(../images/mv.jpg);
      background-image: image-set(url(../images/mv.jpg)1x,url(../images/mv@2x.jpg)2x);
      position: relative;
      height: 300px;
      @include media(sm) {
          background-image: url(../images/mv_sp.jpg);
          height: calc(482px / 2);
      }
      &__wrap {
          top: -16px;
          @include media(sm) {
              top: 0;
          }
      }
      &__title {
          @media screen and (max-width: 320px) {
              font-size: 20px;
          }
      }
      &__subTitle {
          position: relative;
          top: -3px;
          @include media(sm) {
              top: 0;
          }
      }
      .l-scroll-down {
          top: 48.8%;
      }
  }
    .form-block {
        .form-overview {
            margin-top: 30px;
            &__inner {
                .line {
                    .textarea {
                    }
                }
            }
        }
    }
    .question {
      .textarea {
            padding-left: 30px;
            @include media(sm) {
              padding-left:0;
            }
      }
    }
    .telphone {
        &__txtlong {
            margin: 0 0 0 10px !important;
            @include media(sm) {
                margin: 0 !important;
            }
        }
    }
}

@include media(sm) {
    .client-order {
        .form-block {
            .form-overview {
                &__inner {
                    .line {
                        padding: 20px 0;
                    }
                }
            }
        }
    }
}
