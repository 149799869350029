@charset "utf-8";

.c-input,
.c-select,
.c-textarea {
    background: #fff;
    border: 1px solid #ddd;
    transition: background-color 100ms, border-color 100ms;
    width: 100%;
    .is-error & {
        background: #fcc;
        border-color: #c00;
    }
}
select {
    cursor: pointer;
}
// label
// ----------------------------------------------------------
label {
    vertical-align: middle;
    cursor: pointer;
}

// input
// ----------------------------------------------------------
.c-input {
    height: 35px;
    padding: 2px 10px;
    @include media(sm) {
        height: 40px;
    }
}
// Chromeでオートフィルした時にinputが黄色くなるのを回避
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 100px #fff inset !important;
}

// textarea
// ----------------------------------------------------------
.c-textarea {
    border: 0;
    background: #f5f5f5;
    border-radius: 3px;
    height: 175px;
    padding: 8px 10px;
    @include media(sm) {
        height: 125px;
    }
}

// placeholder
// ----------------------------------------------------------
:placeholder-shown {
    color: #ccc;
}
::-webkit-input-placeholder {
    color: #ccc;
}
:-moz-placeholder {
    color: #ccc; opacity: 1;
}
::-moz-placeholder {
    color: #ccc; opacity: 1;
}
:-ms-input-placeholder {
    color: #ccc;
}
.c-select.is-empty {
    color: #ccc;
}

// other
// ----------------------------------------------------------
.c-required {
    color: #c00;
}
.c-error {
    color: #c00;
}

.form-block {
    //p {下層のサブタイトルに影響が出る
    //    @include fz(15, 28, 400);
    //    @include media(sm) {
    //        @include fzs(28, 48, 400);
    //    }
    //}
    .textarea-inner {
      padding-top: 2px !important;
    }
    .form {
      &-overview {
        width: 800px;
        margin: 100px auto 80px;
        position: relative;
        z-index: 0;

        p {
          @include fz(15, 28, 400);
              @include media(sm) {
                  @include fzs(28, 48, 400);
              }
        }

        &__inner {
          margin-bottom: 80px;
          @include media(sm) {
              margin-bottom: 30px;
          }
          &--title {
            @include fz(20, 30, 700);
            background: #fff;
            border: 2px solid #eaeaea;
            padding: 12px 10px 10px 10px;
            text-align: center;
            min-height: 60px;
            margin-bottom: 45px;
          }
           .regist{
             padding-bottom:20px;
             .link{
               color:#36a7c7;
             }
           }
          #privacy_policy {
            margin-top: 45px;
            @include media(sm) {
                margin-top: 0;
            }
          }
          &--contents {
            display: flex;
            align-items: center;
            &___wrap {
              display: flex;
              align-items: center;
            }

          }
          .contactBOX {
            table-layout: fixed;
            margin-top: 20px;
          }
          .line {
            align-items: center;
            border-bottom: 1px solid #eaeaea;
            display: flex;
            justify-content: flex-start;
            padding: 0 0 20px;
            position: relative;
            &:after{
              content:"";
              position: absolute;
              background: $mainGrada;
              width: 180px;
              height: 1px;
              bottom: -1px;
              left: 0;
              @include media(sm) {
                background: none;
              }
            }
            &:not(:first-of-type) {
                padding: 30px 0 20px;
                @include media(sm) {
                    padding: 20px 0 !important;
                }
            }
            &:nth-of-type(3) {
                padding: 33px 0 31px;
            }
            &:nth-of-type(4) {
                padding: 32px 0 30px;
                //.text {
                  //  position: relative;
                  //  top: -2px;
                //}
            }
            &:nth-of-type(5) {
                align-items: flex-start;
                padding: 39px 0 29px;
            }
            &:nth-of-type(6) {
                align-items: flex-start;
                padding: 38px 0 30px;
                .imgInput__txt {
                    margin-bottom: 18px;
                }
            }

            &.station {
                span {
                    display: inline-block;
                    margin: 0 10px;
                }
                .select-wrap_item {
                    margin-right: 10px;
                }
            }
            &.birth {
              .select-wrap {
                &_item {
                 &--block {
                   display: flex;
                 }
                }
              }
            }
            .u-col--1 {
              width: 60px;
              text-align: center;
              @include media(sm) {
                text-align: left;
              }
            }
            &.telphone {
              .form-overview__inner--contents {
                span {
                    margin: 0 10px;
                }
              }
                .text_bg {
                  @media all and (-ms-high-contrast: none) {
                    padding: 0 12px;
                    }
                }
            }
            &.question {
              .textarea {
                @media all and (-ms-high-contrast: none) {
                    padding-left: 30px;
                  }
              }
            }
            &.fax {
                span {
                    margin: 0 10px;
                }
            }
            &.graduate {
                span {
                    margin: 0 10px;
                }
            }

            &.requirement {
                align-items: flex-start;
                margin-top: 4px;
                .title {
                    margin-top: 8px;
                }
            }
            &.work {
                align-items: flex-start !important;
                padding: 30px 0 2px ;
                .title {
                    margin-top: 8px;
                }
                .Duties {
                    margin-left: -1px;
                    margin-top: 2px;
                    @include media(sm) {
                        margin-left: 0;
                        margin-top: 0;
                    }
                }
                .contents-wrap_title {
                    @include media(sm) {
                        margin-bottom: 5px;
                    }
                    //margin-bottom: 5px;
                }

            }

            &.industry {
              .text_bg {
                margin-left: 10px;
              }
              @include media(sm) {
                  .text_bg {
                      margin-top: 5px;
                  }
              }
            }

            &.occupations {
              .text_bg {
                margin-left: 10px;
              }
            }

            &.relocation {
              .text_bg {
                margin-top: 10px;
                padding-top: 7px;
              }
            }

            &.salary {
              .text_bg {
                margin-right: 10px;
              }
            }
            &.job-hunt {
              .text_bg {
                margin: 0 10px 0 10px;
              }
              .cptn {
                margin-top: 15px;
              }
            }
            &.hope-years {
              .text_bg {
                margin-top: 10px;
                padding-top: 1px;
              }
            }
            &.number {
                .form-overview__inner--contents {
                    span {
                        margin: 0 10px;
                    }
                }
            }

            th {
              font-weight: bold;
              width: 180px;
              position: relative;
              text-align: left;
              padding: 0 0 0 18px;
              @media all and (-ms-high-contrast: none) {
                display: flex;
                align-items: center;
              }

              .hissu {
                color: #c10000;
              }
            }
            .text {
              @include fz(14, 28, 400);
              width: 590px;
              padding-left: 30px;
              .contents-wrap {
                margin-bottom: 18px;
                display: flex;
                align-items: center;
                &_title {
                  width: 80px;
                  display: inline-block;
                  width: 82px;
                  margin-right: 18px;
                  text-align: right;
                  white-space: nowrap;
                }
                &.period {
                    margin-bottom: 13px;
                    .contents-wrap_title {
                      margin-right: 18px;
                      //margin-top: 2px;
                      margin-left: -2px;
                      @include media(sm) {
                          margin-left: 0;
                      }
                    }
                }
                &.employment {
                    position: relative;
                    left: -3px;
                    margin-bottom: 14px;
                    @include media(sm) {
                        left: 0;
                    }
                    .select-wrap_item {
                        position: relative;
                        top: -3px;
                        @include media(sm) {
                            top: 0;
                        }
                    }
                }
                &.company-salary {
                    margin-bottom: 28px;
                }
              }

              &.name {
                padding-left: 12px;
                input {
                  width: 245px;
                }
              }
              &.license {
                input {
                  width: 245px;
                  &:nth-child(odd) {
                    margin-right: 6px;
                    @include media(sm) {
                        margin-right: 0;
                    }
                  }
                }
              }
              &.career {
                .company {
                  &-name {
                    width: 245px;
                  }
                  &-area {
                    width: 180px;
                    &_title {
                      width: 64px;
                      display: inline-block;
                      text-align: center;
                    }
                  }
                  &-haken {
                    width: 160px;
                  }
                }
              .fee {
                width: 160px;
                margin-right: 14px;
              }
              .fee-title {
                display: inline-block;
                width: 106px;
                text-align: center;
              }
              }

              &.os {
                input.text_bg {
                  width: 140px;
                  margin-bottom: 0;
                  margin-top: 0;
                  @include media(sm) {
                      margin-top: 10px;
                      margin-bottom: 10px;
                  }
                  &:nth-child(odd) {
                    margin-left: 7px;
                    margin-right: 7px;
                  }
                }
              }
              .other-area {
                margin-right: 3px;
              }
              label {
                .other-area {
                  margin: 0 10px;
                }

              }
            }
            .confirm{
              @include fz(14, 28, 400);
            }
            .textarea {
                @include fz(14,28,400);
                flex-basis: calc(100% - 180px - 30px);
                width: 100%;
                .form-overview__inner--contents___wrap {
                    width: 100%;
                }
                @include media(sm) {
                  font-weight: 400;
                }
            }
          }
          .requirement {
            .text {
              .text_bg {
                margin-bottom: 10px;
              }
            }
          }
          .personal-info {
			  margin: 40px 0 28px;
			  height: 145px;
			  border: 1px solid #eaeaea;
			  background: #fff;
              border-radius: 3px;
              iframe {
              	  height: 100%;
                  width: 100%;
              html {
                  background: #fff;
              }
            }
          }
          .work-location {
            .select-wrap {
              &_item {
                margin-left: 10px;
                margin-top: -1px;
              }
            }
          }
          .apply-company {
            .select-wrap {
              margin-top: 12px;
              .time-applicants {
                margin-right: 10px;
              }
            }
          }
          &--text {
              @include fz(16,28,600);
              margin-bottom: 30px;
              text-align: center;
          }
        }
        .text_bg {
          border: 0;
          background: #f5f5f5;
          border-radius: 3px;
          padding: 0 20px;
          height: 30px;

        }
        .pr {
            &__textarea {
                height: 300px;
                padding-top: 10px;
                @include media(sm) {
                    height: 150px;
                }
            }
        }
        .textarea-confirm {
            border-bottom: 1px solid #eaeaea;
            padding: 0 0 30px;
            @include media(sm) {
                padding: 30px 0 30px;
            }
        }

        #station1_time_minute {
          &.text_bg {
            width: 70px;
          }
        }
        #station2_time_minute {
          &.text_bg {
            width: 70px;
          }
        }
      }

    }
    & .mw_wp_form.mw_wp_form_confirm.mw_wp_form_preview {
      .select-wrap_item::after {
        content: none;
      }
      .privacy-box {
          display: none;
      }
      .input-submit__confirm {
          display: block;
      }
    }
    & .mw_wp_form.mw_wp_form_input {
        .input-submit__confirm {
          display: none;
        }
    }

  input[type="radio"] {
      appearance: none;
      cursor: pointer;
      position: relative;
      top: 0;
      width: 16px;
      height: 16px;
      border: 1px solid #dadada;
      border-radius: 50%;
      margin-right: 8px;
      + label {
        margin-right: 10px;
      }
      @media all and (-ms-high-contrast: none) {
          top: -3px;
        }
      &:checked {
          background-color: #fff;
          position: relative;
          top: 0;
          border: 5px solid #3b99fc;
          @media all and (-ms-high-contrast: none) {
              top: -3px;
            }
      }
  }



  input[type="checkbox"] {
      appearance: none;
      position: relative;
      width: 14px;
      height: 14px;
      border: 1px solid #dadada;
      border-radius: 3px;
      margin-right: 3px;
      @media all and (-ms-high-contrast: none) {
          top: -2px;
        }
      &:checked {
          background-color: #3b99fc;
          height: 14px;
          width: 14px;
          border: none;
          border-radius: 3px;
          position: relative;
          &::before {
              @include icon-ini;
              background: transparent;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              bottom: 0;
              height: 9px;
              left: 4px;
              position: absolute;
              right: 0;
              transform: rotate(40deg);
              top: 2px;
              width: 6px;
          }
          @media all and (-ms-high-contrast: none) {
              top: -2px;
            }
      }
      + label {
        margin-right: 10px;
      }
  }

  .select-wrap {
        display: flex;
    &_item {
      position: relative;
      overflow: hidden;
      &--block {
        display: flex;
        &__content {
          display: flex;
        }
      }
      &::after {
        content: "";
        position: absolute;
        background: url(../images/icon-selectbox.png) no-repeat;
        width: 17px;
        height: 20px;
        top: 5px;
        right: 0px;
        pointer-events: none;
        @media all and (-ms-high-contrast: none) {
          top: 2px;
        }
      }
      &.left-text {
        margin-left: 10px;
      }
    }
    .u-my--xxs {
      padding-right: 14px;
      padding-left: 6px;
      padding-top: 1px;
    }

    &_inner {
      display: flex;
      margin-left: 20px;
      .overtime {
        display: flex;
        label {
          left: 4px;
          top: 4px;
        }
      }
    }
  }

  select {
    -webkit-appearance: none;
    border: 1px solid #dedede;
    border-radius: 0;
    position: relative;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.4;
    padding-top: 2px;
    background: none;
    padding-right: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 11px;
    width: 140%;
    &.please-select {
      width: 120%;
    }
    &.selectType2 {
      width: 140%;
    }
  }

  input[type="file"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .imgInput {
    position: relative;
    margin-bottom: 14px;
    display: flex;
    line-height: 1;
    &-wrap {

    }
    &__txt {
        @include fz(13,24,600);
    }
    @include media(sm) {
        p {
            padding-top: 2px;
        }
    }
  }
  .file_btn {
    @include fz(12,12,600);
    border: 1px solid #ddd;
    padding: 8px 8px 2px 5px;
    letter-spacing: -0.06em;
    cursor: pointer;
    border-radius: 5px;
    color: #666;
    position: relative;
    margin-right: 10px;
    @include media(sm) {
        padding: 7px 10px 6px;
    }
  }
  .no-select {
      @include fz(12,12,600);
      padding-top: 3px;
  }
  .caution {
    color: #e01616;
  }
  .input-submit {
      &_button {
          margin: 0 auto;
          margin-bottom: 50px;
          position: relative;
          width: 360px;
          height: 60px;
          @include media(sm) {
              height: calc(120px / 2);
              width: calc(560px / 2);
          }
          button {
              border-radius: 50px;
              display: block;
              @include fz(16,16,600);
              font-family: $fontFamily;
              padding-top: 4px;
              background: $mainGrada;
              padding: 0 10px;
              position:relative;
              margin: 0 auto 30px;
              color: #fff;
              height: 100%;
              width: 100%;
              @media all and (-ms-high-contrast: none) {
                  padding-top: 10px;
              }
              @include media(sm) {
                  @include fzs(28,28,600);
                  font-family: $fontSpFamily;
              }
              &:hover {
                  color: #fff;
                  opacity: .8;
                  background-clip: inherit;
                  -webkit-text-fill-color: inherit;
                  transition: transform 200ms;
                  &::after {
                      display: none;
                  }
              }
              &::before {
                  @include icon-ini;
                  background-image: url(../images/icon-memo.svg);
                  height: 20px;
                  margin-right: 5px;
                  position: relative;
                  top: -1px;
                  width: 18px;
                  @media all and (-ms-high-contrast: none) {
                      margin-top: 5px;
                  }
                  @include media(sm) {
                      background-image: url(../images/icon-memo.svg);
                      height: calc(42px / 2);
                      position: relative;
                      width: calc(38px / 2);
                  }
              }
          }
      }
      &__confirm {
          margin: 30px auto 0;
          text-align: center;
          padding-left: 15px;
          height: 48px;
          width: 280px;
          @include media(sm) {
              margin: 15px auto 0;
              padding-left: 0px;
          }
          @include media(ss) {
              padding-left: 0;
          }
          button {
              @include fz(15,48,600);
              background: #dedede;
              border-radius: 3px;
              display: inline-block;
              height: 100%;
              position: relative;
              text-align: center;
              width: 100%;
              &::before {
                  @include icon-ini;
                  background-image: url(../images/jobsearch/ico-arrow-back.svg);
                  height: 12px;
                  position: relative;
                  right: 11px;
                  top: -1px;
                  width: 13px;
              }
              &:hover {
                  opacity: .8;
              }
          }
      }
  }

  .select-wrap_item {
      select {
          min-width: 70px;
      }
  }
  .check-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
    padding: 0 5px 0 0;
    &_box {
      & + label {
        padding-left: 0 !important;

      }
    }
    &_text {
      text-align: center;
      label {
        @include fz(15, 24, 700);

      }
    }

    @include media(sm) {
        margin-bottom: 25px;
        .check-box {
            margin-right: 8px;
            height: 14px;
            width: 14px;
        }
    }
  }

  .next-flow_text {
    background: #f5f5f5;
        padding: 24px 0 23px 8px;
        @include media(sm) {
            padding: 20px;
        }
    p {
      @include fz(14, 28, 700);
      text-align: center;
    }
  }

  .skill-check_txt {
    @include fz(14, 28, 700);
    text-align: center;
margin: 20px 0 40px;
  }

  .full-area {
    width: 100%;
  }

}

@include media(sm) {
  .form-block {

      select {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          font-size: 11px !important;
          line-height: 1 !important;
          height: 20px;
      }

    .form {
      &-overview {
        width: 100%;
        margin-bottom:40px;
        margin-top: 30px;
        &__inner {
          &--title {
            @include fzs(30, 45, 700);
            margin: 0;
            padding: 10px 12px 10px;
            min-height: 40px;
            + p {
                @include fzs(22, 22);
                margin-top: 10px;
                //text-align: right;
            }
          }
          &--text{
            @include fzs(25, 60, 400);
            margin: 15px 0;
            padding: 0 20px;
          }
          &--contents {
            flex-direction: column;
            align-items: flex-start;
            flex-basis: 100%;
            &___wrap {
              width: 100%;
              margin-bottom: 10px;
              .u-col--1 {
                width: 10% !important;
              }
              .u-col--4 {
                width: 90%;
              }
            }
          }
          .line {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            @include media(sm) {
                .cptn {
                    @include fzs(24, 24, 400);
                    align-items: center;
                    display: flex;
                    margin-top: 4px;
                    &:before {
                        @include icon-ini;
                        @include fzs(21, 21, 400);
                        content: "※";
                        margin-top: 1px;
                        margin-right: 4px;
                    }
                }
            }
            select {
              padding-left: 6px;
              width: 100%;
            }
            &.work-location {
              .select-wrap_item {
                margin-left: 0;
                select {
                  width: 100%;
                }
              }
            }
            &.salary {
              input {
                width: 120px;
              }
            }
            &.hope-years {
              .select-wrap {
                flex-direction: row;
              }
            }

            &.number {
                .form-overview__inner--contents {
                    flex-direction: row;
                }
                .form-overview__inner--contents___wrap {
                    width: auto;
                    span {
                        margin: 0 10px;
                    }
                }
            }
            &.station {
              .form-overview__inner--contents {
                  flex-wrap: wrap;
                  flex-direction: inherit;
              }
              .form-overview__inner--contents___wrap {
                  width: auto;
                  span {
                      margin: 0 10px;
                  }
              }
              .select-wrap_item {
                  margin-top: 10px;
              }
            }
            &.telphone {
              .form-overview__inner--contents {
                  flex-direction: inherit;
                  flex-wrap: wrap;
              }
              .form-overview__inner--contents___wrap {
                  width: calc(100% / 3);
                  span {
                      margin: 0 10px;
                  }
              }
            }
            &.fax {
              .form-overview__inner--contents {
                  flex-direction: row;
              }
              .form-overview__inner--contents___wrap {
                  width: calc(100% / 3);
                  span {
                      margin: 0 10px;
                  }
              }
            }
            &.move {
                .text {
                    span {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
            .title {
              flex-basis: 100%;
              padding: 10px 0px 10px;
              margin-right: 0;
              width: 100%;
              &:after{
                content: none;
              }
            }
            .text {
              @include fzs(24, 38, 400);
              width: 100%;
              padding: 0 0px;
              display: flex;
              flex-wrap: wrap;
              &.name {
                padding-left: 0;
                input {
                  width: 100%;
                  margin-bottom: 0;
                }
              }
              &.license {
                 input {
                    width: 100%;
                 }
              }
              &.excel {
                .text_bg {
                  width: 120px;
                }
              }
              &.career {
                .company-name {
                  width: 100%;
                }
                .company-area {
                  width: 100%;
                  &_title {
                    width: auto;
                  }
                }
                .job-desc {
                  width: 100%;
                }
                .company-haken {
                  width: 100%;
                }
                .fee {
                  width: 100%;
                }
                .fee-title{
                  text-align: left;
                  margin-top:5px;
                }
                .select-wrap {
                  flex-direction: column;
                  align-items: flex-start;
                }
              }
              &.os {
                input {
                  &.text_bg {
                    width: 100%;
                    &:nth-child(odd) {
                      margin-left: 0;
                    }
                  }
                }
              }
              .other-area{
                margin:0;
              }
              .contents-wrap_title {
                display: inline;
                width: auto;
              }
              .contents-wrap {
                  align-items: flex-start;
                  flex-direction: column;
                  margin-bottom: 0;
                  width: 100%;
              }
            }


          &.birth {
            .text {
              flex-direction: row;
              &.select-wrap {
                .select-wrap_item {
                  width: 100%;
                  &--block {

                    select {
                      width: 100%;
                    }
                  }
                }
                .u-my--xxs {
                  padding-right: 5px;
                }
              }

            }
          }
        }
        .apply-company {
          .select-wrap_item {
            flex-basis: 100%;
            &--block {
                  flex-basis: 50%;
                  .u-my--xxs {
                    white-space: nowrap;
                  }
              &__content {
                flex-wrap: wrap;
              }
            }
          }
        }
			.personal-info{
                background-color: #f9f9f9;
    			margin: 20px auto;
                padding: 10px;
				width: 100%;
				-webkit-overflow-scrolling: touch;
    			overflow: auto;;
				iframe {
					display: block;
		  			height: 100%;
					width: 100%;
				}
  			}
      	}
      .innerBox{
        width:100%;
        padding:0;
        margin:10px 0;
      }
      .text_bg{
        margin-bottom: 0;
        padding: 2px 20px 0;
      }
    }
  }
  .select-wrap {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    &_inner {
      margin-left: 0;
    }
    &_item{
      &::after {
        top: 1px;
      }
    }
  }
  .time{
    .select-wrap {
    &_inner {
      margin-left: 0;
      margin-right: 10%;
      width: 100%;
      .left-text{
        width: 100%;
      }
      .overtime{
        width: 32%;
        label{
          width:auto;
          padding: 0;
          top:7px;
        }
      }
      .labelTit{
        width:23%;
        label{
          width:inherit;
        }
      }
      label{
        width: 38%;
      }
      &:last-child{
        margin-right:0;
      }
    }
    &_item{
      width: 75%;
      margin-left:2%;
    }
  }
  }
  .skill-check_txt{
    margin-bottom:20px;
  }

}
.next-flow_text{
  margin-top:20px;
}
}

@include media(sm) {
    input[type="text"] {
        @include fz(12,12,600);
    }
}

//wpのフォーム表示調整

.form-block {
    .line {
        .mwform-checkbox-field.horizontal-item {
            margin-right: 10px;
        }
    }
    .form-overview {
        &__inner {
            .line {
                .text {
                    overflow: hidden;
                }
            }
            //確認画面
            .Completion {
                &__title {
                    @include fz(25,35,600);
                    margin-bottom: 50px;
                    text-align: center;
                    @include media(sm) {
                        @include fz(18,24,600);
                        margin-bottom: 30px;
                    }
                }
                &__text {
                    @include fz(15,26,600);
                    text-align: center;
                    @include media(sm) {
                        @include fz(14,24,600);
                    }
                }
            }
        }
        .text_bg {
            @include media(sm) {
                margin-left: 0px !important;
                margin-top: 5px;
                width: 100%;
            }
        }
    }
    .mwform-checkbox-field-text {
      @include fz(15, 28, 700);
          @include media(sm) {
              @include fzs(23, 60, 700);
          }
    }
}



//iphoneSEここから
@include media(ss) {
    .form-block {
        select {

        }
        .input-submit_button {
            display: block !important;
            margin: 0px auto 40px;
            width: calc(560px / 2);
        }

        .line {
            &.birth {
                select {
                    @include fzs(20, 30, 700);
                    margin-top: 2px;
                    min-width: 50px;
                }
            }
        }
        .select-wrap_item {

        }
        .form-overview {
            .text_bg {
                margin-left: 0px !important;
            }
            .line {
                &.job-hunt {
                    .text_bg {
                      margin-left: 5px !important;
                    }
                }
            }
        }
    }
}


.P_message {
    @include media(sm) {
        font-size: 13px !important;
        line-height: 1.4 !important;
        text-align: left !important;
        br {
            display: none;
        }
    }
}

//form配下の確認画面
.confirm {
    .form-overview__inner .line {
        &:last-child {
            margin-bottom: 80px;
        }
    }
    .form-overview__inner .line th {
        padding: 0;
    }
}
