@charset "utf-8";

.l-page-header {
    background: #eee;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    position: relative;
    margin-top: 100px;
    &__wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    &__title {
        @include fz(42, 75, 700);
    }
    &__subTitle {
        @include fz(18, 32, 700);
        @include textclip($mainGrada);
        font-family: 'Alegreya Sans SC', sans-serif;
        @include media(sm) {
            @include fzs(28,32,700);
        }
    }
    @include media(sm) {
        background-position: center;
        height: calc(482px / 2);
        margin-top: 80px;
        &__title {
            @include fzs(48,80,700);
        }
    }
    .l-scroll {
        opacity: 1;
        border: none;
        right: 121px;
        top: 2px;
        &-down {
            top: 69.5%;
            &_view {
                border-color: #ff6b5f;
                &::before {
                    background: $mainGrada;
                }
            }
        }
    }
}

//recruittipsページナビ
.l-page-nav {
    background: $siteColor;
    height: 60px;
    position: absolute;
    top: -20px;
    width: 100%;
    &__list {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: auto;
        width: 840px;
    }
    &__item {
        position: relative;
        text-align: center;
        //width: calc(100% / 5 - 11px * 2);
        margin: 0 18px;
        &:not(:last-child) {
            &::after {
                @include icon-ini;
                background-color: #546072;
                bottom: 0;
                height: 13px;
                margin: auto;
                position: absolute;
                right: -18px;
                top: 0;
                width: 1px;
            }
        }
    }
    &__link {
        @include fz(15,60,600);
        color: #fff;
        padding: 0 11px;
    }
}
@include media(sm) {
    .l-page-nav {
        left: 0;
        overflow-x: scroll;
        right: 0;
        top: 0;
        height: 45px;
        &__list {
            flex-wrap: nowrap;
        }
        &__item {
            &:not(:last-child) {
            }
        }
        &__link {
            @include fz(14,45,600);
        }
    }
}
