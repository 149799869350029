@charset "utf-8";

html {
    &:not(.is-bye) {
        .no-referrer {
            .l-loading {
                background: #fff;
                &.velocity-animating {
                    background: $mainGrada;
                }
            }
        }
    }
}

.l-loading ,.l-loading2 {
    $loading: &;
    background: $mainGrada;
    //background: #fff;
    background-size: 100%;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    display: none;
    z-index: 1000;

    .no-referrer & {
        display: block;
        opacity: 1;
    }

    .is-hello & {
        opacity: 1;
        z-index: 1002;
        display: block;
        transform: translate(-100%, 0);
        animation: hello 600ms $easeInOutQuart 0ms;
        //transition: none;
        //byeの準備
        //transform: translate(0%, 0);
    }
    .is-bye & {
        transform: translate(0%, 0);
        animation: bye 500ms $easeInOutQuart both;
        transition: none;
    }
    .is-bye2 & {
        transform: translate(0%, 0);
        animation: bye 750ms $easeInOutQuart both;
        transition: none;
    }
    .is-byebye & {
        animation: fadeIn 750ms  $easeInOutQuart both;
        background: #fff;
        transform: translate(0%, 0);
        transition: none;
    }
    .top & {
        &::before {
            background: $mainGrada;
            content: '';
            display: block;
            height: 0;
            left: 100%;
            position: absolute;
            right: 0;
            top: 100%;
        }
        .is-hello & {
            transition-delay: 1450ms;
            &::before {
                top: 0%;
                transition: top 650ms $easeInOutQuart 100ms;
            }
        }
    }

    &-logo {
        //background: url(../images/common/logo-mark.svg) no-repeat center center;
        //background-size: 70px auto;
        bottom: 0;
        left: 0;
        margin-left: -8px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
        .is-hello:not(.is-bye) & {
            opacity: 0;
            transition: opacity 400ms $easeInOutQuart 100ms, visibility 0ms 750ms;
            visibility: hidden;
        }
        .is-bye & {
            transition: opacity 400ms $easeInOutQuart 200ms;
        }
        .top & {
            .is-hello:not(.is-bye) & {
                transition: opacity 400ms $easeInOutQuart 1450ms, visibility 0ms 2100ms;
            }
        }
    }
}
.nosc body {
    overflow: hidden;
    height: 100%;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;

    .no-referrer & {
        display: none;
    }

}

/**/
.op{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: block;
    &__inner{
        width: 42.5%;

        .no-referrer & {
            display: none;
        }

    }
    &__title {
        display: block;
        position: absolute;
        //top: 50%;
        //left: 50%;
        //transform: translate(-50%,-50%);
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 3.2rem;
        &-wrap {
            @include fz(28, 40, 700);
            @include letterSpacing(200);
            text-align: center;
            width: 100%;
        }
        &-in {
            @include letterSpacing(200);
            color: #000;
        }
    }
}
@include media(sm) {
    .op{
        &__inner{
            width: 100vw;
        }
        &__title {
            transform: translate(0%,0%);
            text-align: center;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 3.2rem;
        }
        &__title {
            &-wrap {
                @include fz(16, 31, 700);
            }
        }

    }
}
.c-border-in-out {
    display: inline-block;
    z-index: 100;
    position: relative;
    color: transparent;
    white-space: nowrap;
}
.c-border-in-out__content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}
.c-border-in-out.is-animate .c-border-in-out__content-in1, .c-border-in-out.is-shown .c-border-in-out__content-in1 {
    animation-name: contentWrapMove;
    animation-duration: .8s;
    animation-fill-mode: forwards;
  }
.c-anime-dl-0 {
    animation-delay: 0s;
  }
.c-anime-dl-1 {
    animation-delay: 0.75s;
    will-change: transform;
  }
.c-anime-dl-2 {
    animation-delay: 0.9s;
  }
.c-anime-dl-3 {
    animation-delay: 1.05s;
  }
.c-anime-dl-4 {
    animation-delay: 1.2s;
  }
.c-anime-dl-5 {
    animation-delay: 1.35s;
  }
.c-anime-dl-6 {
    animation-delay: 1.5s;
  }
.c-anime-dl-9 {
    animation-delay: 1.95s;
  }
.c-border-in-out__content-in1 {
    transform: translateX(-100%);
    opacity: 0;
  }

.c-border-in-out__content-in1 {
    width: 100%;
    display: block;
    overflow: hidden;
}
.c-border-in-out.is-animate .c-border-in-out__content-in2, .c-border-in-out.is-shown .c-border-in-out__content-in2 {
    animation-name: contentInnerStay;
    animation-duration: .8s;
    animation-fill-mode: forwards;
}
.c-border-in-out__content-in2 {
    transform: translateX(100%);
}
.c-border-in-out__content-in2 {
    width: 100%;
    display: block;
    white-space: nowrap;
}
.c-border-in-out__content:after {
    content: '';
    height: 10%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
    background-color: #fff;
    transform: translateY(-50%);
}


.c-border-in-out {
    display: inline-block;
    z-index: 100;
    position: relative;
    color: transparent;
    white-space: nowrap;
}
.c-border-in-out__content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}
.c-border-in-out.is-animate .c-border-in-out__content-in2 {
    animation-name: contentInnerStay;
    animation-duration: .8s;
    animation-fill-mode: forwards;
}
.c-border-in-out__content-in2 {
    transform: translateX(100%);
}
.c-border-in-out__content-in2 {
    width: 100%;
    display: block;
    white-space: nowrap;
}
.c-border-in-out.is-animate .c-border-in-out__content:after {
    animation-name: borderInOut;
    animation-duration: .8s;
    animation-fill-mode: forwards;
}
.c-anime-dl-af1::after {
    animation-delay: 0.1s;
}
.c-anime-dl-af9::after {
    animation-delay: 1.55s;
}
.c-border-in-out__content::after {
    opacity: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}
.c-border-in-out__content:after {
    content: '';
    //height: 50%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    //top: 10%;
    top: 0%;
    right: 0;
    bottom: 0;
    opacity: .5;
    background-color: #fff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*各種アニメーション　*/
@keyframes borderInOut{
    0%{
      opacity:0;
      transform:scaleX(0);
      transform-origin:left
    }5%{
      opacity:1;
      transform:scaleX(0);
      transform-origin:left
    }49%{
      opacity:1;
      transform:scaleX(1);
      transform-origin:left
    }50%{
      opacity:1;
      transform:scaleX(1);
      transform-origin:right
    }100%{
      opacity:1;
      transform:scaleX(0);
      transform-origin:right
    }
}

@keyframes contentWrapMove{
    0%{
      opacity:0;
      transform:translateX(-100%)
    }5%{
      opacity:1;
      transform:translateX(-100%)
    }100%{
      opacity:1;
      transform:translateX(0)
    }
}


 @keyframes contentInnerStay{
    0%{
      transform:translateX(100%)
    }5%{
      transform:translateX(100%)
    }100%{
      transform:translateX(0)
    }
}
