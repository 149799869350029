.l-breadcrumb {
    position: absolute;
    bottom: 0;
    width: 683px;
    height: 50px;
    background: #37414f;
    padding-left: 132px;
    padding-right: 20px;
    @include media(sm) {
        display: none;
    }
    ul {
        display: flex;
        align-items: center;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        li {
            position: relative;
            &:not(:last-of-type) {
                &::after {
                  @include icon-ini;
                  border-top: 1px solid #636b76;
                  border-right: 1px solid #636b76;
                  bottom: 0;
                  display: inline-block;
                  height: 6px;
                  right: 0;
                  margin-left: 10px;
                  margin-right: 18px;
                  position: relative;
                  top: 0;
                  transform: rotate(45deg);
                  width: 6px;
                  @media all and (-ms-high-contrast: none) {
                    top: -2px;
                  }
                }
            }
            &:first-child {
              a {
                &::after {
                  background: $mainGrada;
                  @include icon-ini;
                  transform: scale(1, 1);
                  transform-origin: left top;
                  position: absolute;
                }
              }
            }
            &:nth-of-type(2) {
                &::after {
                    margin-right: 17px;
                    margin-left: 14px;
                }
            }
            a {
                @include fz(13, 50, 400);
                color: #fff;
                display: inline-block;
                @media all and (-ms-high-contrast: none) {
                padding-top: 6px;
                }
                &::after {
                    bottom: 0;
                    height: 2px;
                    margin: auto;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    @media all and (-ms-high-contrast: none) {
                    bottom: 3px;
                    }
                }
                &:hover {
                    font-weight: 600;
                    opacity: 1;
                }
            }
        }
    }
}
