@charset "utf-8";

.l-side {

    &__inner {
        &:not(:first-child) {
            margin-top: 41px;
        }
    }
    &__header {
        margin-bottom: 34px;
    }
    &__title {
        @include fz(36,36,800);
        @include textclip($mainGrada);
        font-family: $fontAlegreyaSansSC;
        margin-bottom: 7px;
    }
    &__subTitle {
        @include fz(14,14,600);
    }
    &__list {}
    &__item {
        display: flex;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 26px;
        position: relative;
        &:not(:first-child) {
            .l-side__item--txtarea {
                margin-top: 2px;
            }
            .l-side__item--txtarea_category {
                margin-bottom: 12px;
            }
        }
        &:not(:last-child) {
            margin-bottom: 24px;
        }
        &:hover {
            opacity: 1;
            .l-side__item--imgarea {
                opacity: .8;
            }
            .l-side__item--txtarea_txt,
            .cate-case__company {
                @include textclip($mainGrada);
            }
        }
        &--imgarea {
            flex-basis: 130px;
            position: relative;
            height: 105px;
            img {
                font-family: 'object-fit: cover';
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        &--txtarea {
            flex-basis: calc(100% - 130px - 20px);
            margin-left: 20px;
            &_category {
                @include fz(13,13,600);
                color: #999999;
                margin-bottom: 11px;
            }
            &_txt {
                @include fz(14,24,600);
            }
        }
    }
    .ranking {
        .l-side {
            &__item {
                overflow: hidden;
                &::before {
                    @include icon-ini;
                    @include fz(150,150,700);
                    bottom: -60px;
                    color: $grayColor;
                    font-family: $fontAlegreyaSansSC;
                    position: absolute;
                    right: 0;
                    z-index: -1;
                }
                &:first-child {
                    &::before {
                        content: "1";
                        bottom: -30px;
                        right: 10px;
                    }
                }
                &:nth-child(2) {
                    &::before {
                        content: "2";
                        bottom: -37px;
                        right: -2px;
                    }
                }
                &:nth-child(3) {
                    &::before {
                        content: "3";
                        bottom: -35px;
                        right: 5px;
                    }
                }
                &:nth-child(4) {
                    &::before {
                        content: "4";
                        bottom: -28px;
                        right: -6px;
                    }
                }
                &:nth-child(5) {
                    &::before {
                        content: "5";
                        bottom: -34px;
                        right: 5px;
                    }
                }
                &:nth-child(6) {
                    &::before {
                        content: "6";
                    }
                }
                &:nth-child(7) {
                    &::before {
                        content: "7";
                    }
                }
                &:nth-child(8) {
                    &::before {
                        content: "8";
                    }
                }
                &:nth-child(9) {
                    &::before {
                        content: "9";
                    }
                }
                &:nth-child(10) {
                    &::before {
                        content: "10";
                    }
                }
            }
        }
    }

    .keyword {
        margin-top: 58px;
        &__inner {
            background: $grayColor;
            padding: 39px 15px 40px 31px;
        }
        &__title {
            @include fz(18,18,600);
            color: $siteColor;
            position: relative;
            &::before {
                @include icon-ini;
                background-image: url(../images/recruittips/category/ico-search.png);
                position: relative;
                height: 16px;
                margin-right: 9px;
                width: 16px;
                top: -2px;
            }
        }
        &__content {
            margin-top: 30px;
            overflow-y: auto;
            max-height: 326px;
            &::-webkit-scrollbar {
                width: 7px;
                &-track {
                    background: $grayColor;
                }
                &-thumb {
                    background: #eaeaea;
                    border-radius: 3px;
                }
            }
            @include media(sm) {
              overflow-y: scroll;
            }
            &--list {
                display: flex;
                flex-wrap: wrap;
            }
            &--item {
                margin: 0 10px 10px 0;
            }
            &--link {
                @include fz(13,13,600);
                background-color: #fff;
                border-radius: 16px;
                display: inline-block;
                height: 32px;
                min-width: 60px;
                text-align: center;
                padding: 9px 15px 10px 16px;
                @media all and (-ms-high-contrast: none) {
                  padding-top: 13px;
                }
                &:hover {
                    background-color: $siteColor;
                    color: #fff;
                    opacity: 1;
                }
            }
        }
    }

    .bar-slide {
        &::after {
            bottom: 0;
            height: 1px;
            margin: auto;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
    //カテゴリ別指定
    .cate {
        &-knowledge {}
        &-interview {
            &__jobstyle {
                @include fz(11,22,600);
                background-color: $siteColor;
                color: #fff;
                display: inline-block;
                min-width: 60px;
                padding: 0 5px;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
            }
        }
        &-case {
            &__company {
                @include fz(18,24,600);
                display: block;
                margin-bottom: 7px;
                margin-top: -2px;
            }
        }
    }

    //newsページのサイド
    &-news {
        &__list {
            background: #fff;
            border: 1px solid #eaeaea;
        }
        &__head {
            display: none;
        }
        &__item {
            &:not(:last-child) {
                border-bottom: 1px solid #eaeaea;
            }
        }
        &__link {
            @include fz(20,20,500);
            align-items: center;
            display: flex;
            font-family: $fontAlegreyaSansSC;
            height: 61px;
            justify-content: space-between;
            padding: 0 29px 2px;
            &:hover {
            .l-side-news__link---year {
                opacity: 1;
                @include textclip($mainGrada);
                @include ieTextColor();
              }
            }
        }
        &__back {
            background: $grayColor;
            margin-top: 20px;
            text-align: center;
            &--link {
                @include fz(15,48,600);
                display: inline-block;
                height: 48px;
                padding: 0 9px 0 0;
                position: relative;
                width: 100%;
                &::before {
                    @include icon-ini;
                    background: url(../images/recruittips/ico-back.png);
                    height: 12px;
                    margin-right: 12px;
                    position: relative;
                    right: -1px;
                    top: -1px;
                    width: 13px;
                }
            }
        }
    }


    @include media(sm) {
        &__header {
            margin-bottom: 25px;
        }
        &__title {
            @include fz(40,40,800);
        }
        &__item {
            &--txtarea {
                &_txt {
                    @include fzs(24,42,600);
                }
            }
        }
        .cate {
            &-case {
                &__company {
                    @include fzs(30,40,600);
                    margin-bottom: 4px;
                }
            }
        }
    }
}
.js-overlay {
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
