@charset "utf-8";

.top {
    @include media(lg) {
        .l-nav__item {
            &:nth-of-type(4) {
                padding-right: 26px;
            }
        }
        .l-nav__link {
            padding: 41px 15px 0 13px;
            font-size: 15px;
        }
        .small {
            .l-nav__link {
                padding: 41px 9px 0;
                font-size: 14px;
                &[target="_blank"] {
                    position: relative;
                    &:before {
                        top: 43px;
                        @media all and (-ms-high-contrast: none) {
                            top: 40px;
                        }
                    }
                }
            }
        }
    }
}

.l-header {
    align-items: center;
    background: #fff;
    display: flex;
    height: $headerH;
    justify-content: space-between;
    left: 0;
    margin: 0 auto;
    //max-width: 1366px;
    min-width: 1290px;
    padding: 0 96px 0 61px;
    position: fixed;
    right: 0;
    top: 0;
    opacity: 1;
    transform: translate(0, -100px);
    transition: transform 400ms $easeOutQuart 0ms;
    //transition: opacity 800ms, transform 800ms $easeOutQuart 1000ms;
    z-index: 100;

    a[target="_blank"]::after {

    }
    @include media(max-width, 1305px) {
        padding: 0 80px 0 0;
    }
    @include media(sm) {
      background: none;
      min-width: 100%;
      padding: 13px $spSpace 0;
      height: auto;

      //白いヘッダー
      &.is-hedder_chenge_own {
        height: 80px;
        background: #fff;
        & .l-header__logo {
            margin-top: -30px;
            &::after {
                left: 0px;
                top: 55px;
            }
        }

      }
    }
    &.is-hello_hedder{
        opacity: 1;
        transform: translate(0, 0);
        transition: transform 000ms $easeOutQuart 0ms;
    }
    &.is-hedder_chenge_own{
        opacity: 1;
        transform: translate(0, 0);
        transition: transform 400ms $easeOutQuart 0ms;
        .l-nav__item.joinFree .l-nav__link {
          @media all and (-ms-high-contrast: none) {
          padding-top: 15px;
          }
        }
    }
    &.is-hedder_chenge_two{
        opacity: 1;
        transform: translate(0, 0%);
        transition: transform 0ms $easeOutQuart 0ms;
    }
    &.is-hedder_show{
        opacity: 1;
        transform: translate(0, 0%);
        transition: transform 0ms $easeOutQuart 0ms;
    }
    &.hddr-trp {
        background: transparent;
        position: absolute;
        padding: 15px 90px 0 61px;
        @include media(sm) {
            padding: 12px $spSpace 0;
            //display: none;
            //position: fixed;
        }
        .l-nav {
            height: inherit;
            &__list {
                margin-top: 1px;
            }
            &__item {
                &.joinFree {
                  margin-left: calc(50vw - 589px);
                  @include media(max-width, 1250px) {
                      margin-left: calc(171px - 160px);
                  }
                  @include media(sm) {
                      margin: 0;
                      height: calc(80px / 2);
                  }
                  a {
                    margin-top: 25px;
                    @include media(sm) {
                        margin-top: 7px;
                    }
                    @media all and (-ms-high-contrast: none) {
                      padding-top: 16px;
                    }
                  }
                }
                &:nth-child(3) {
                    &:after {
                        margin-left: 191px;
                    }
                }
                // @include media(lg) {
                //     &:nth-child(4) {
                //         padding-right: 20px;
                //     }
                // }
                &:nth-child(5) {
                  padding-left: 14px;
                  margin-top: -1px;
                    a {
                        &::before {
                            margin-right: 12px;
                            top: -1px;
                        }
                    }
                }
            }
            &__btn {
                &.is-active {}
            }
        }
    }
    &__logo {
        position: relative;
        top: 0;
        @include media(sm) {
            top: 0px;
        }
        &.hddr-trp {
          margin-top: -2px;
          @include media(sm) {
              margin-top: 0px;
          }
        }
        &--link {
            align-items: center;
            display: flex;
            height: $headerH;
            position: relative;
            @include media(sm) {
                height: auto;
            }
            &:hover {
                opacity: .8;
                transition: transform 200ms;
            }
        }
        &--img {
            height: 24px;
            width: 201px;
            @include media(sm) {
                height: calc(33px / 2);
                width: calc(273px / 2);
            }
        }
        &::after {
            @include icon-ini;
            background-image: url(../images/img-lead.svg);
            background-size: cover;
            position: absolute;
            height: 13px;
            left: 201px;
            margin-left: 18px;
            top: 43%;
            width: 169px;
            @include media(sm) {
                background-image: url(../images/img-lead_sp.png);
                height: calc(19px / 2);
                width: calc(241px / 2);
                left: -5px;
                top: 32px;
                margin: inherit;
            }
        }
        &.hddr-trp::after {
            left: -2px;
            margin-left: 0;
            top: 78%;
            @include media(sm) {
              height: calc(19px / 2);
              width: calc(241px / 2);
              left: 0;
              top: 24px;
              margin: inherit;
            }
        }
    }
}

.l-nav {
    display: flex;
    transform: all 300s;
    height: 100%;
    position: relative;
    &__list {
        align-items: center;
        display: flex;
        position: relative;
        top: 3px;
        @include media(sm) {
            top: 0;
        }
    }
    &__item {
        @include fz(15,15,600);
        height: 100%;
        position: relative;
        &:nth-child(5) {
            a {
                &::after {
                    left: -15px;
                }
            }
        }
        &:nth-child(4) {
            @include media(lg) {
                padding-right: 15px;
                position: relative;
                .l-nav__link {
                    padding-right: 17px;
                }
                &:after {
                    @include icon-ini;
                    background: #afb3b9;
                    height: 14px;
                    position: absolute;
                    top: 41px;
                    right: 0;
                    width: 1px;
                }
            }
        }
        &:nth-child(5) {
          padding-left: 11px;
            a {
                &::before {
                    @include icon-ini;
                    background-image: url(../images/icon-building.svg);
                    height: 16px;
                    margin-right: 14px;
                    position: relative;
                    top: -2px;
                    width: 13px;
                }
            }
        }
        &.joinFree {
            @include media(sm) {
                @include fzs(26,80,600);
              }
            a {
                border-radius: 50px;
                background: $mainGrada;
                color: #fff;
                display: inline-block;
                margin-left: 11px;
                margin-top: -3px;
                @include media(lg) {
                    padding: 14px 23px 13px 23px !important;
                }
                margin-top: 24px;
                height: 46px;
                @include media(sm) {
                    height: calc(80px / 2);
                    padding: 0 0 0 17px;
                    width: calc(280px / 2);
                    margin-top: 7px;
                }
                &::before {
                    @include icon-ini;
                    background-image: url(../images/icon-memo.svg);
                    height: 20px;
                    margin-right: 6px;
                    margin-top: -1px;
                    width: 18px;
                    @include media(sm) {
                        height: calc(32px / 2);
                        position: relative;
                        left: 1px;
                        width: calc(29px / 2);
                    }
                }
                &::after {
                  .l-nav__link {
                    @media all and (-ms-high-contrast: none) {
                      background: none;
                    }
                  }
                }
                &:hover {
                    color: #fff;
                    opacity: .8;
                    background-clip: inherit;
                    -webkit-text-fill-color: inherit;
                    -webkit-tap-highlight-color:rgba(255,255,255,0);
                    transition: transform 200ms;
                    &::after {
                        display: none;
                    }
                }
            }
        }
        @include media(sm) {
            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                display: none;
            }
        }
    }
    &__link {
        color: $textColor;
        padding: 41px 9px 0;
        position: relative;
        align-items: center;
        display: block;
        height: 97px;
        @media all and (-ms-high-contrast: none) {
          padding-top: 44px;
        }

        @include media(lg) {
            font-size: 14px;
        }
        &::after {
            @include icon-ini;
            background: $mainGrada;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 4px;
            right: 0;
            position: absolute;
            transform: scale(0, 1);
            transform-origin: right top;
            transition: transform 200ms;
            width: calc(100% - 30px);

        }
        @include media(lg) {
            &[target="_blank"] {
                position: relative;
                &:before {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    background-image: url(../images/icon-tab.svg);
                    height: 10px;
                    position: relative;
                    width: 10px;
                    position: absolute;
                    right: 0;
                    top: 44px;
                    @media all and (-ms-high-contrast: none) {
                        top: 41px;
                    }
                }
            }
        }

        &:hover,
        &.is-active {
            @include textclip($mainGrada);
            @include ieTextColor();
            color: #ff9933;
            transition: transform 200ms;
            &::after {
                @include icon-ini;
                transform: scale(1, 1);
                transform-origin: left top;
            }
        }
        &:nth-of-type(3) {
            &:hover {
                &::after {
                    width: calc(100% - 30px - 1em)
                }
            }
        }
    }
    &__btn {
        cursor: pointer;
        height: 18px;
        position: absolute;
        top: 38px;
        right: -55px;
        width: 20px;
        z-index: 999;
        @media all and (-ms-high-contrast: none) {
          top: 40px;
        }
        &.hddr-trp {
            right: -69px;
        }
        &#js-nav__btn2{
            opacity: 1;
            top: 38px;
            @media all and (-ms-high-contrast: none) {
              top: 40px;
            }
            &.is-active {
              @media all and (-ms-high-contrast: none) {
                position: fixed;
                right: 60px;
              }
            }
        }
        @include media(sm) {
            right: auto;
            top: 85vh;//iphoneで確認したらメニューバーと被ったから
            opacity: 0;
            transform: none;
            &.is-hello_hedder {
                opacity: 1;
            }
            &#js-nav__btn2{
                opacity: 0;
                top: 100vh;
            }
        }
        @include media(max-width, 1290px) {
            margin-left: 25px;
        }
        span {
            background: #000;
            display: block;
            height: 2px;
            position: relative;
            transition: transform 200ms;
            width: 20px;
            &::before {
                @extend span;
                @include icon-ini;
                position: absolute;
                top: -8px;
                left: 0;
            }
            &::after {
                @extend span;
                @include icon-ini;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
        @include media(sm) {
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0px 2px 4px 0px rgba(37, 37, 37, 0.1);
            position: fixed;
            height: calc(110px / 2);
            top: 62.5vh;
            left: -9px;
            right: inherit;
            width: calc(110px / 2);
            span {
                margin: auto;
                top: 1px;
                width: calc(36px / 2);
                &::before {
                   top: -6px;
                }
                &::after {
                   top: 6px;
                }
            }
        }
    }
}
//企業のご担当者様ページ
.client-nav {
  .l-nav {
      &__item {
		  &:nth-child(3) {
            a {
                &::after {
                    left: -5px;
                }
            }
            &:after {
                @include icon-ini;
                background: #afb3b9;
                margin-top: -115px;
                height: 14px;
                margin-left: 110px;
                width: 1px;
            }
          }
		  &:nth-child(4) {
			  padding-left: 11px;
			  &::after {
				  display: none;
			  }
              a {
				           &::before {
					  @include icon-ini;
					  background-image: url(../images/ico-human.svg);
					  height: 17px;
					  margin-right: 14px;
                      position: relative;
                      top: -2px;
                      width: 14px;
                  }
				  &::after {
					  left: 2px;
				  }
              }
		  }
		  &:nth-child(4) {
          //padding-left: 3px;
			  @include media(sm) {
                  //display: block;
                  display: none;
			  }
		  }
      &.joinFree {
          a {
              padding: 15px 23px 13px 23px;
              @include media(sm) {
                  padding: 0 0 0 17px;
              }
              &::before {
                  background-image: url(../images/client/ico-mail-client.svg);
                  height: 12px;
                  left: -2px;
                  top: 0;
                  width: 14px;
              }
          }
      }
      }
  }
}
#js-nav__btn3 {
    @include media(sm) {
        opacity: 1;
        top: inherit;
        bottom: 60px;
    }
}
.open-menu {
    //シュッと表示するように変更

    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 111;
    overflow: hidden;
    display: none;
    perspective: 200px;
    &__bg {
        height: 100vh;
        width: 100%;
        z-index: 112;
        background: $mainGrada;
        transform: scaleX(0);
        transform-origin: right;
        transition-duration: .6s;
        transition-timing-function: cubic-bezier(.19,1,.22,1);
        transition-property: transform;
        &.open {
            transform: scaleX(1);
        }
    }
    &.open {
        transform: scaleX(1);
    }
    &__menu {
        width: 100%;
        position: absolute;
        z-index: 113;
        @include media(lg) {
        display: flex;
        align-items: center;
        top: 0;
        bottom: 0;
      }
        &::before {
            @include icon-ini;
            background-image: url(../images/img-findnew.svg);
            position: absolute;
            height: 689px;
            top: 90px;
            width: 827px;
            left: 23px;
        }
        @include media(sm) {
            &::before {
                height: calc(826px / 2);
                left: -42px;
                width: calc(992px / 2);
                top: 68px;
            }
        }
    }
    &__inner {
        margin: 0 auto;
        position: relative;
        width: 767px;
        @include media(sm) {
            margin: 118px auto 0;
            padding: 0 $spSpace;
            width: 100%;
        }
    }
    &__nav {
        margin:0 0 35px;
        position: relative;
        &::after {
            @include icon-ini;
            position: relative;
            background: #fff;
            bottom: 1px;
            height: 1px;
            opacity: .2;
            width: 100%;
        }
        @include media(sm) {
            margin: 0 0 21px;
            &::after {
                bottom: -5px;
            }
        }
    }
    &__list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 0 0 46px;
        @include media(sm) {
            margin: 0;
        }
    }
    &__item {
        position: relative;
        flex-basis: 33%;
        vertical-align: middle;
        margin-bottom: 41px;
        //margin-right: 92px;
        &::after {
            @include icon-ini;
            background: #eeeeee;
            bottom: 0;
            position: absolute;
            right: 35px;
            margin: auto;
            height: 20px;
            opacity: .4;
            top: 0;
            width: 1px;
        }
        &:nth-of-type(2){}
        &:nth-of-type(3n) {
            &::after {
                display: none;
            }
            @include media(sm) {
                &::after {
                    display: inline-block;
                }
            }
        }
        &:nth-of-type(1) {
            flex-basis: 30%;
            @include media(lg) {
                &::after {
                    right: 45px;
                }
            }
        }
        @include media(sm) {
            flex-basis: 45% ;
            margin: 0 0 15px !important;
            padding: 0;
            &::after {
                left: auto;
                right: 10px;
                height: calc(32px / 2);
                top: 7px;
            }
            &:nth-of-type(odd) {
                padding-left: 6px;
                flex-basis: 55%;
            }
            &:nth-of-type(even) {
                &::after {
                    display: none;
                }
            }
            &:nth-of-type(3) {
                a {
                    &::before {
                        top: -4px;
                    }
                    &::after {
                        top: 20px;
                    }
                }
            }
        }
        @include media(ss) {
            @include fzs(12,12,600);
            &:nth-of-type(3) {
                a {
                    &::before {
                        top: -3px;
                    }
                    &::after {
                        top: 14px;
                    }

                }
            }
        }
        &:hover {
            opacity: .8;
        }
    }
    &__link {
        @include fz(18,20,600);
        //IEのみ
        @media all and (-ms-high-contrast:none) {
            line-height: 1.4777777;
        }
        color: #fff;
        display: inline-block;
        position: relative;
        @include media(sm) {
            @include fzs(32,32,600);
        }
        @include media(ss) {
            @include fzs(24,24,600);
        }
        &::before {
            @include icon-ini;
            background-color: #fff;
            bottom: 0;
            border-radius: 50px;
            margin-right: 13px;
            position: relative;
            top: -2px;
            height: 20px;
            width: 20px;
            @include media(sm) {
                height: calc(32px / 2);
                margin-right: 0px;
                top: 1px;
                width: calc(32px / 2);
            }
            @include media(ss) {
                top:2px;
                height: calc(30px / 2);
                width: calc(30px / 2);
            }
        }
        &::after {
            @include icon-ini;
            background-image: url(../images/ico-arrow.svg);
            height: 6px;
            position: absolute;
            transform: rotate(-90deg);
            top: 6px;
            left: 6px;
            width: 11px;
            @include media(sm) {
                height: 5px;
                width: 7px;
                top: 9px;
                left: 5px;
            }
            @include media(ss) {
                left: 4.5px;
                top: 6.5px;
            }
        }
        &-in {
            color: #fff;
            @include media(sm) {
            line-height: 1.4;
            }
        }
    }

    &__other {
        align-items: center;
        display: flex;
        justify-content: space-between;
        @include media(sm) {
            flex-wrap: wrap;
        }
    }
    &__companylsit {
        width: calc(100% / 2 - 40px);
        margin-left: 46px;
        margin-top: -1px;
        @include media(sm) {
            margin: 0 0 31px 9px;
            width: 100%;
        }
        &--list {
            display: flex;
            margin-bottom: 5px;
        }
        &--item {
            margin-right: 1em;
            &:nth-child(2) {
                margin-right: 18px;
                @include media(sm) {
                    margin-right: 14px;
                }
            }
            @include media(ss) {
                margin-right: 0.5em;
                &:nth-child(2) {
                    margin-right: 7px;
                }
            }
        }
        &--link {
            @include fz(14,14,600);
            color: #fff;
            @include fzs(28,28,300);
            @include media(sm) {
                @include fzs(28,28,300);
            }
            &:hover {
                opacity: .8;
            }
        }
    }
    &__tel {
        align-items: center;
        display: flex;
        margin-top: 13px;
        &::before {
            @include icon-ini;
            background-image: url(../images/ico-tel-mark-white.svg);
            height: 17px;
            position: relative;
            width: 26px;
            margin-right: 5px;
        }
        @include media(sm) {
            &::before {
                height: calc(27px / 2);
                width: calc(42px / 2);
                left: 1px;
                margin: 0 7px 0 0px;
            }
        }
        &--number {
            @include fz(18,32,600);
            color: #fff;
            font-family: $fontRoboto;
            margin-right: 7px;
            vertical-align: middle;
            letter-spacing: -0.01em;
            display: inline-block;
            position: relative;
			pointer-events: none;
            &:hover {
              opacity: 1;
            }
            @include media(sm) {
                @include fzs(32,32,600);
                margin-right: 3px;
				pointer-events: auto;
            }
        }

        &--txt {
            @include fz(12,32,600);
            color: #fff;
            letter-spacing: 0.08em;
            position: relative;
            @include media(sm) {
                @include fzs(24,24,600);
            }
        }
    }

    &__contact {
        display: flex;
        width: calc(100% / 2 - 10px);
        justify-content: flex-end;
        margin-right: 5px;
        margin-top: -10px;
        @include media(sm) {
            justify-content: space-between;
            margin: 8px 0 0 0;
            padding: 0;
            width: 100%;
        }
        &--combtn {
            a {
                @include fz(15,50,600);
                border: 1px solid #fff;
                border-radius: 50px;
                color: #fff;
                display: inline-block;
                height: 52px;
                text-align: center;
                vertical-align: middle;
                padding-left: 24px;
                position: relative;
                width: 190px;
                &::before {
                    @include icon-ini;
                    background-image: url(../images/icon-building-white.svg);
                    bottom: 2px;
                    height: 16px;
                    position: absolute;
                    left: 24px;
                    margin: auto;
                    top: 0;
                    width: 13px;
                }
                @media all and (-ms-high-contrast: none) {
                  padding-top: 3px;
                }
            }
            @include media(sm) {
                width: calc(50% - 8px);
                a {
                    @include fzs(26,79,600);
                    border-radius: 20px;
                    height: calc(80px / 2);
                    padding-left: 18px;
                    width: 100%;
                    &::before {
                        height: calc(31px / 2);
                        left: 17px;
                        top: 2px;
                        width: calc(25px / 2);
                    }
                }
            }
            @include media(ss) {
                a {
                    @include fzs(24,75,600);
                    width: calc(270px / 2);
                    &::before {
                        left: 10px;
                    }
                }
            }
        }
        &--cvbtn {
            margin-left: 13px;
            a {
                @include fz(15,52,600);
                background-color: #fff;
                border-radius: 50px;
                display: inline-block;
                text-align: center;
                height: 52px;
                width: 160px;
                padding-left: 24px;
                position: relative;
                vertical-align: middle;
                &::before {
                    @include icon-ini;
                    background-image: url(../images/ico-memo-glada.png);
                    background-image: image-set(url(../images/ico-memo-glada.png)1x,url(../images/ico-memo-glada@2x.png)2x);
                    height: 20px;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 22px;
                    width: 18px;
                    position: absolute;
                }
                @media all and (-ms-high-contrast: none) {
                  padding-top: 3px;
                }
            }
            @include media(sm) {
                margin: 0;
                width: calc(50% - 8px);
                a {
                    @include fzs(26,79,600);
                    border-radius: 20px;
                    height: calc(80px / 2);
                    padding-left: 22px;
                    padding-top: 1px;
                    width: 100%;
                    &::before {
                        background-image: url(../images/ico-memo-glada_sp.png);
                        background-image: image-set(url(../images/ico-memo-glada_sp.png)1x,url(../images/ico-memo-glada_sp@2x.png)2x);
                        height: calc(36px / 2);
                        left: 28px;
                        top: 0;
                        width: calc(32px / 2);
                    }
                }
            }
            @include media(ss) {
                a {
                    @include fzs(24,75,600);
                    width: calc(270px / 2);
                    &::before {
                        left: 23px;
                    }
                }
            }
        }
    }
}
.l-nav__btn.is-active {
    margin-top: 7px;
    @media all and (-ms-high-contrast: none) {
        right: -138px;
        }
    @include media(sm) {
        margin-top: 0;
    }
    span {
        background: transparent;
        &::before {
            background: #fff;
            transform: translateY(20px) rotate(-315deg);
            top: -27px
        }
        &::after {
            background: #fff;
            transform: translateY(-20px) rotate(315deg);
            top: 13px;
        }
    }
    @include media(sm) {
        top: 62.5vh;
        right: 0;
        span {
            background: transparent;
            height: 1px;
            width: calc(40px / 2);
            &::before {
                background: #333;
                transform: translateY(20px) rotate(-315deg);
                top: -20px
            }
            &::after {
                background: #333;
                transform: translateY(-20px) rotate(315deg);
                top: 20px;
            }
        }
    }
}
.open-menu.is-active {
    display: block;
}
.open-menu__bk.is-active {
    background-color: #31363d;
    height: 100%;
    position: fixed;
    opacity: .95;
    width: 100%;
    z-index: 99;
    transition: transform 500ms;
}
body.is-active {
    overflow: hidden;
}
