@charset "utf-8";

.c-btn {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    position: relative;
    text-align: center;
    transition: background-color 100ms, border-color 100ms, color 100ms, opacity 100ms;
    vertical-align: middle;
    &:disabled {
        cursor: default;
    }
    &.is-loading {
        color: rgba(255, 255, 255, 0);
        &::after {
            animation: buttonLoading 800ms linear infinite;
            border: 3px solid #fff;
            border-radius: 100%;
            border-top-color: transparent;
            bottom: 0;
            content: '';
            display: block;
            height: 20px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
        }
    }

    &-default {
        background: #333;
        border-radius: 3px;
        color: #fff;
        padding: 10px 20px;
        &:not(:disabled):hover {
            background: #2b2b2b;
        }
        &:disabled {
            background: #999;
        }
    }
}

.viewMore-btn {
  a {
    //padding: 10px;
    width: 200px;
    margin: auto;
    display: block;
    text-align: center;
    //letter-spacing: 0.02em;
    @include media(sm) {
        width: calc(360px / 2);
    }
  }
}

@keyframes buttonLoading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
