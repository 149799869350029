@charset "utf-8";

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes masking {
    0% {
        transform: translate(0, 101%);
    }
    42%, 58% {
        transform: translate(0, 0%);
    }
    100% {
        transform: translate(0, -101%);
    }
}

@keyframes comet {
    0% {
        transform: translate(0%, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

@keyframes heroText {
    0% {
        opacity: 0;
        transform: translate(0, 15px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
//Loading用
@keyframes hello {
    0% {
        transform: translate(-100%, 0);
    }
    100% {
        transform: translate(0%, 0);
    }
}

@keyframes bye {
    0% {
        transform: translate(0%, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

//グラデの動き
@keyframes MoveGrad {
    0% {
        background-position:0% 50%
    }
    50% {
        background-position:100% 50%
    }
    100% {
        background-position:0% 50%
    }
}
//スクロールダウン
@keyframes scrollDown {
    0% {
        top: -100%;
    }
    100% {
        top: 30%;
    }
}
//TOPスライダーのアクティブアニメーション
@keyframes swiper {
    0% {
      transform: translate(0, 50px);
    }
    100% {
      transform: translate(0, 0);
    }
}
