@charset "utf-8";

.error {
    .l-page-header {
        display: none;
    }
    .hddr-trp {
        display: none;
    }
    .l-main {
        position: relative;
        &:after {
            @include icon-ini;
            background: url(../images/404/sorry.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto;
            display: block;
            padding-bottom: 270px;
            position: relative;
            bottom: 80px;
            z-index: -1;
        }
    }
    .l-content {
        display: block;
        //margin-bottom: 120px;
        margin-top: 215px;
    }
    .error-wrap {
    }
    .error-title {
        @include fz(42, 62, 700);
        text-align: center;
        position: relative;
        &:after {
    @include icon-ini;
    display: block;
    background: url(../images/404/not.svg);
    background-size: 100%;
    margin: 0px auto;
    margin-top: 11px;
    height: 22px;
    width: 15px;
}
    }
    .error-text {
        @include fz(15, 30, 700);
        text-align: center;
        margin-top: 20px;
      }
    .error-top {
        display: block;
        margin: 0px auto;
        margin-top: 50px;
        text-align: center;
        width: 210px;
    }
    .l-footer {
        margin-top: -151px;
    }


    //SP
    @include media(sm) {
        .l-page-header {
            display: none;
        }
        .l-main {
            margin-bottom: -50px;
            width: 100%;
            &:after {
                @include icon-ini;
                background: url(../images/404/sorry.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                padding-bottom: 270px;
                position: relative;
                bottom: 110px;
                z-index: -1;
            }
        }
        .l-content {
            margin-bottom: 0px;
            margin-top: 130px;
            padding-bottom: 0px;
        }
        .error-wrap {
        }
        .error-top {
            @include fzs(34, 32, 700);
            margin-top: 30px;
            &.btn-more {
                padding-top: 12px;
            }

        }
        .error-title {
            @include fzs(42, 62, 700);
            width: 100%;
        }
        .error-text {
            @include fzs(28, 48, 700);
        }
    }
}
